<script>
import NodeMap from "../js/node/index";
import BaseLayer from "../js/BaseLayer";
import { findEquipmentModel } from "@/api/map/index";
import { getRunningTaskList } from "@/api/project/index";
import planeIcon from "@/assets/img/map/XC_64x64_.png";
import planeIcon_seleted from "@/assets/img/map/XC_seleted.png";
export default {
  name: "UAVmixin",
  data() {
    return {
      // 点击地图选中的UAV
      selectModelId: "",
      // 无人机图标
      planeIcon,
      // 无人机选中图标
      planeIcon_seleted,
    };
  },
  computed: {
    currentUavData() {
      var arr = this.selectModelId.split("-");
      var id = arr[arr.length - 1];
      if (this.uavData && this.uavData[id]) {
        return this.uavData[id];
      }
      return {};
    },
  },
  methods: {
    initRunningUAV(map3d) {
      if (!map3d) {
        return
      }
      this.$options.sockets = {};
      this.$options.uavLayer = new BaseLayer();
      map3d.layerManager.add(this.$options.uavLayer);
    },

    clearUAVLayer() {
      this.stopLoopRequest()
      for (var key in this.$options.sockets) {
        this.$options.sockets[key].ws.close();
      }
      this.$options.sockets = {};
      this.$options.uavLayer.clear();
      
    },

    loadUav() {
      this.updateRealTimeUav()
      this.$options.RealTimeUavTimer = setInterval(this.updateRealTimeUav, 1000 * 3)
    },

    updateRealTimeUav() {
      let flyProjectId = undefined
      const firstPath = this.$route.matched[0].path
      switch (firstPath) {
        case '/mission-center':
          flyProjectId = this.$route.query.projectId
          break;
        case '/forest-grass':
          flyProjectId = this.$route.query.id
          if (!flyProjectId) {
            return
          }
          break;
        case '/oil-gas':
          flyProjectId = this.$route.query.id
          if (!flyProjectId) {
            return
          }
          break;
        case '/emergency':
          break;
        default:
          break;
      }
      getRunningTaskList({flyProjectId}).then(res=>{
        if (res.data.code === 200 && this.$options.RealTimeUavTimer) {
          const firstPath = this.$route.matched[0].path
          let taskList
          switch (firstPath) {
            case '/emergency':
              // 进入三级菜单
              if (this.$route.matched.length === 3) {
                taskList = res.data.data.filter(item=>{
                  if (this.projectDetail && this.projectDetail.snList && Array.isArray(this.projectDetail.snList)) {
                    return this.projectDetail.snList.indexOf(item.sn) > -1
                  }
                  return false
                })
              } else {
                taskList = res.data.data
              }
              break;
            default:
              taskList = res.data.data
              break;
          }
          this.diffTask(taskList)
        }
      })
    },

    diffTask(taskList) {
      let tastSet = {}
      // 新增进行添加
      for (var i = 0, l = taskList.length; i < l; i++) {
        const task = taskList[i]
        if (!this.$options.sockets[task.id]) {
          this.webSocketInt(task)
        }
        tastSet[task.id] = task
      }
      // 失去连接的无人机进行删除
      let socketIdList = Object.keys(this.$options.sockets)
      for (var i = 0, l = socketIdList.length; i < l; i++) {
        const socketId = socketIdList[i]
        if (!tastSet[socketId]) {
          this.removeTaskById(socketId)
        }
      }
    },

    // 删除无人机
    removeTaskById(id) {
      this.$options.sockets[id].ws.close()
      delete this.$options.sockets[id]
      // 实体node的Id
      const nodeId = this.getAirVehicleIdBytaskId(id)
      this.$options.uavLayer.removeNodeById(nodeId)
    },

    clickUav(entityId) {
      const id = entityId.replaceAll("uav-", "");
      if (this.$options.sockets[id]) {
        this.siderContentShow = true;
      }
      this.selectModelId = entityId;
    },

    changUAVstate(val, old) {
      // 判断当前展示的页面为项目首页
      switch (this.modifyType) {
        case 0:
          if (old === val) {
            return;
          }
          if (old) {
            // 还原已经高亮图标
            for (var i = 0, l = this.projectList.length; i < l; i++) {
              var item = this.projectList[i];
              if (item.id === old) {
                var tasks = item.projectTaskList;
                if (tasks && Array.isArray(tasks)) {
                  for (var j = 0; j < tasks.length; j++) {
                    const task = tasks[j];
                    const uavId = this.getAirVehicleIdBytaskId(task.id);
                    let uav = this.$options.uavLayer.getById(uavId);
                    if (uav) {
                      uav.billboard.image = this.getUAVIconByTaskId(task.id);
                    }
                  }
                }
                break;
              }
            }
          }
          if (val) {
            // 高亮选中图标
            for (var i = 0, l = this.projectList.length; i < l; i++) {
              var item = this.projectList[i];
              if (item.id === val) {
                var tasks = item.projectTaskList;
                if (tasks && Array.isArray(tasks)) {
                  for (var j = 0; j < tasks.length; j++) {
                    const task = tasks[j];
                    const uavId = this.getAirVehicleIdBytaskId(task.id);
                    let uav = this.$options.uavLayer.getById(uavId);
                    if (uav) {
                      uav.billboard.image = this.getUAVSelectIconByTaskId(
                        task.id
                      );
                    }
                  }
                }
                break;
              }
            }
          }
          break;
        default:
          break;
      }
    },

    UAVBeforeDestroy() {
      //
      this.stopLoopRequest()
      if (!this.$options.sockets) {
        return;
      }
      // 清理管理飞行任务
      for (var key in this.$options.sockets) {
        let item = this.$options.sockets[key];
        if (item.ws) {
          item.ws.close();
        }
      }
      this.$options.sockets = {};
    },

    webSocketInt(task) {
      let planeIcon = this.planeIcon;
      let planeIcon_seleted = this.planeIcon_seleted;
      if (!this.$options.sockets) {
        this.$options.sockets = {};
      }
      if (this.$options.sockets[task.id]) {
        this.$options.sockets[task.id].ws.close();
      }
      this.$options.sockets = {};
      findEquipmentModel({
        sn: task.sn,
      })
        .then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data ? res.data.data : {};
            planeIcon = data.modelIcon ? data.modelIcon : planeIcon;
            planeIcon_seleted = data.modelSelectIcon? data.modelSelectIcon: planeIcon_seleted;
          }
        })
        .finally(() => {
          let guid = Cesium.createGuid().slice(0, 6).replace("-", "A");
          // 新方案
          let ws = new WebSocket(
            `${window.global.VUE_APP_WS_ROUTE_NEW}/${task.sn}-${guid}`
          );
          if (!ws) return;
          ws.onmessage = (evt) => {
            if (this.modifyType === 6 && this.$options.sockets[task.id]) {
              return;
            }
            const data = JSON.parse(evt.data);
            this.infos = data;
            this.updateLiveFlyRoute({
              ...data,
              icon: planeIcon,
              icon_active: planeIcon_seleted,
            },task);
            let airPlaneSn = task.sn
            let airPlaneName = task.airPlaneName;
            let projectName = task.projectName;
            let taskName = task.taskName;
            let longitude = data.lon;
            let latitude = data.lat;
            let fieldHeight = data.fieldHeight;
            let gs = data.gs;
            let pwrLeft = data.pwrLeft;
            this.$set(this.uavData, task.id, {
              airPlaneSn,
              airPlaneName,
              projectName,
              taskName,
              longitude,
              latitude,
              fieldHeight,
              gs,
              pwrLeft,
            });
          };
          this.$options.sockets[task.id] = {
            ws,
            planeIcon,
            planeIcon_seleted,
            videoUrl: task.videoUrl,
            groundStationType: task.groundStationType,
          };
        });
    },

    getUAVIconByTaskId(id) {
      return this.$options.sockets[id].planeIcon;
    },

    getUAVSelectIconByTaskId(id) {
      return this.$options.sockets[id].planeIcon_seleted;
    },

    // 更新无人机
    updateLiveFlyRoute(data, task) {
      const longitude = data.lon
      const latitude = data.lat
      const uavYaw = data.yaw
      if (!longitude || !latitude) {
        return;
      }
      const tempLon = Number(longitude);
      const tempLat = Number(latitude);
      const uavId = this.getAirVehicleIdBytaskId(task.id);
      const position = Cesium.Cartesian3.fromDegrees(tempLon, tempLat);

      let uav = this.$options.uavLayer.getNodeById(uavId);
      this.lonArr = [tempLon, tempLat];
      if (!uav) {
        uav = new NodeMap.Uav({
          id: uavId,
          name: 'uav-' + uavId,
          position: position,
          planeIcon: data.icon
        })
        if (this.uavHasSelected(task)) {
          uav.entity.billboard = data.icon_active
        }
        this.$options.uavLayer.addNode(uav)
      } else {
        uav.position = position;
        uav.entity.billboard.rotation = -Cesium.Math.toRadians(Number(uavYaw) || 0);
      }
    },

    getAirVehicleIdBytaskId(id) {
      return `uav-${id}`;
    },

    getAirVehicleImg(task) {
      // 以后可能会根据机型返回图标
      try {
        if (this.uavHasSelected(task)) {
          return this.getUAVSelectIconByTaskId(task.id);
        }
        return this.getUAVIconByTaskId(task.id);
      } catch (error) {
        console.error(error, task.id);
      }
    },

    uavHasSelected(task) {
      if (!this.selectProject) {
        return false;
      }
      for (var i = 0, l = this.projectList.length; i < l; i++) {
        var item = this.projectList[i];
        if (item.id === this.selectProject) {
          var tasks = item.projectTaskList;
          if (!tasks || !Array.isArray(tasks)) {
            continue;
          }
          for (var j = 0; j < tasks.length; j++) {
            if (task.id === tasks[j].id) {
              return true;
            }
          }
        }
      }
      return false;
    },

    stopLoopRequest() {
      clearInterval(this.$options.RealTimeUavTimer)
      this.$options.RealTimeUavTimer = null
    }
  },
};
</script>
