import BaseOperation from "./BaseOperation";
import NodeMap from "../../node/index"
import { screen2cartesian3 } from "@/util/map";

export default class DrawCircleOperation extends BaseOperation {
    static NAME = "DRAW_CIRCLE"
    constructor(props) {
        let defaultParam = Object.assign({
            name: DrawCircleOperation.NAME,
            eventMap: [
                {
                    type: "LEFT_CLICK",
                    callback: (map, event, layer) => {
                        this.click(map, event, layer);
                    }
                },
                {
                    type: "MOUSE_MOVE",
                    callback: (map, event, layer) => {
                        this.mousemove(map, event, layer);
                    }
                }
            ]
        }, props);
        super(defaultParam);
        this._color = props.color || '#0096FF';
        this._primitive = null;
        this._drawType = defaultParam.drawType;
        this._drawing = false;
        this._drawCentering = true;
        this._point = null;
        this._radius = 5;
    }
    set primitive(value) {
        this._primitive = value;
    }
    get primitive() {
        return this._primitive;
    }
    click(map, event, layer) {
        if (!this._drawCentering || this.primitive) {
            this.finishClick(map, event, layer);
            return;
        };
        let nowCartesian = screen2cartesian3(map, event.position);
        if (!nowCartesian) return;
        this._drawing = true;
        this._addCircleCenterPointCall(nowCartesian);
    }
    mousemove(map, event, layer) {
        let nowCartesian = screen2cartesian3(map, event.endPosition);
        if (nowCartesian && this.primitive) {
            let endRadius = Cesium.Cartesian3.distance(this._point, nowCartesian);
            let finalRadius = this._radius + (endRadius - this._radius);
            let radius = finalRadius > 5 ? finalRadius : 5; // 控制最小半径为5
            radius = radius.toFixed(1) * 1;
            this.primitive.draw(this._point, radius);
            this._radius = radius;
        }
    }
    finishClick(map, event, layer) {
        this._finish();
        map._container.style.cursor = "default";
        this._drawing = false;
        this._drawCentering = true;
    }
    _addCircleCenterPointCall(pointCartesian) {
        if (!this.primitive) {
            this.primitive = new NodeMap.TemporaryMarkCircle({
                id: Cesium.createGuid(),
                name: '',
                position: pointCartesian,
                radius: this._radius * 1
            });
            this.layer.addNode(this.primitive);
            this._point = pointCartesian;
            this._drawCentering = false;
        }
    }
    _finish() {
        if (this._afterFinish) {
            this._afterFinish(this._point, this._radius);
        }
    }
    destroy() {
        if (this.primitive) {
            this.layer.removeNodeById(this.primitive.id);
        }
        this._drawing = false;
        this._drawCentering = true;
        this.primitive = null;
        this._point = null;
        this._radius = 5;
    }
}