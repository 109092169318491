<template>
    <!-- 机场右侧信息弹窗 -->
    <div class="airport-information-main" v-if="visible">
        <div class="main">
            <div class="title">
                <span>{{$t('airportManager.airportInformation')}}</span>
                <i class="el-icon-close" @click="closeInformation"></i>
            </div>
            <div class="information">
                <div class="information-list">
                    <div class="list-name">
                        <img class="point-img" v-if="airportData.content.type === 'Point'" src="/img/map/airport.png" alt="">
                        <img class="polygon-img" v-else-if="airportData.content.type === 'Polygon'" src="/img/map/airport-polygon.svg" alt="">
                        <div class="list-name_input">
                            <el-input size="mini" placeholder="" v-model="airportData.airportName" 
                                @blur="saveValue" />
                        </div>
                        <i class="el-icon-delete" @click="deleteAirport" />
                    </div>
                </div>
                <div class="information-list" v-if="false">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('airportManager.basicInformation')}}</span>
                    </div>
                    <div class="base-ins">
                        <div class="base-ins_item">
                            <div class="ins_item">
                                <div class="ins_item-label">{{$t('airportManager.temperature')}}</div>
                                <div class="ins_item-val">22℃</div>
                            </div>
                            <div class="ins_item">
                                <div class="ins_item-label">{{$t('airportManager.humidity')}}</div>
                                <div class="ins_item-val">20%</div>
                            </div>
                        </div>
                        <div class="base-ins_item">
                            <div class="ins_item">
                                <div class="ins_item-label">{{$t('airportManager.windSpeed')}}</div>
                                <div class="ins_item-val">5m/s</div>
                            </div>
                            <div class="ins_item">
                                <div class="ins_item-label">{{$t('airportManager.windDirection')}}</div>
                                <div class="ins_item-val">东北</div>
                            </div>
                        </div>
                        <div class="base-ins_item">
                            <div class="ins_item">
                                <div class="ins_item-label">{{$t('airportManager.rainfall')}}</div>
                                <div class="ins_item-val">0mm</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="information-list" v-if="airportData.content.type === 'Point'">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('airportManager.positionInformation')}}</span>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('airportManager.longitude')}}</div>
                        <div class="msg">
                            <el-input-number size="mini" :controls="false" v-model="airportData.content.coordinates[0]" 
                                @blur="saveValue('number', airportData.content.coordinates[0])" /><span>°</span>
                        </div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('airportManager.latitude')}}</div>
                        <div class="msg">
                            <el-input-number size="mini" :controls="false" v-model="airportData.content.coordinates[1]" 
                                @blur="saveValue('number', airportData.content.coordinates[1])" /><span>°</span>
                        </div>
                    </div>
                </div>
                <div class="information-list" v-if="airportData.content.type === 'Polygon'">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('airportManager.measurementInformation')}}</span>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('airportManager.horizontalArea')}}</div>
                        <div class="msg">{{airportData.area ? (airportData.area / 1000000).toFixed(2) : 0}}km^2</div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('airportManager.horizontalPerimeter')}}</div>
                        <div class="msg">{{airportData.round ? (airportData.round).toFixed(2) : 0}}m</div>
                    </div>
                </div>
                <div class="information-list">
                    <div class="list-title">
                        <div class="list-title-text">
                            <p></p>
                            <span>{{$t('airportManager.airportEquipment')}}</span>
                        </div>
                        <div class="list-title-add" @click="handleOpenEquipment">+ {{$t('airportManager.addDevice')}}</div>
                    </div>
                    <div class="equipment-list">
                        <el-table :data="airportData.equipmentVOS || []" style="width: 100%" height="100%">
                            <el-table-column prop="equipmentName" align="center" show-overflow-tooltip 
                                :label="$t('airportManager.deviceName')" />
                            <el-table-column prop="sn" align="center" show-overflow-tooltip 
                                :label="$t('airportManager.serialNumber')" />
                            <el-table-column align="center" show-overflow-tooltip 
                                :label="$t('airportManager.operation')">
                                <template slot-scope="scope">
                                    <i class="el-icon-delete" @click="deleteEquipment(scope.row)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="information-list information-list-point" v-if="airportData.content.type === 'Polygon'">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('airportManager.endpointInformation')}}</span>
                    </div>
                    <div v-if="!editPoint" class="edit-point-box">
                        <div v-for="(item, index) in airportData.content.coordinates[0]" :key="index + item"
                            class="entity-item" v-show="index + 1 != airportPointLength">
                            <div class="entity-line-index">{{ index + 1 }}</div>
                            <div>[{{ item[0].toFixed(7) }}, {{ item[1].toFixed(7) }}]</div>
                            <i @click="editPointHandle(item, index)" class="el-icon-edit"></i>
                            <i @click="plusPoint(index)" class="el-icon-plus"></i>
                            <i @click.stop="deletePoint(item, index)" class="el-icon-delete"></i>
                        </div>
                    </div>
                    <div v-else class="edit-point-box">
                        <div class="entity-line-index">{{formInPonits.index + 1}}</div>
                        <el-form size="mini" :model="formInPonits" class="demo-form-inline">
                            <el-form-item :label="$t('longitude')">
                                <el-input-number size="mini" :controls="false" v-model="formInPonits.lon" />
                            </el-form-item>
                            <el-form-item :label="$t('latitude')">
                                <el-input-number size="mini" :controls="false" v-model="formInPonits.lat" />
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="cancelEditHandle">{{$t('airportManager.cancelButton')}}</el-button>
                                <el-button :loading="saveLoading" type="primary" 
                                    @click="saveEditHandle">{{$t('airportManager.saveButton')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <!-- 添加设备弹窗 -->
        <el-dialog class="equipmentDialog" title="选择设备" :visible.sync="equipmentDialog" width="850px"
            :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" :before-close="handleCloseEquipment">
            <div class="content">
                <div class="search_main">
                    <el-input v-model="searchVal" :placeholder="$t('airportManager.equipmentSearch')" 
                        size="mini" clearable @keyup.enter.native="handleSearch">
                        <i slot="suffix" class="search-icon el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div class="tabel_main">
                    <el-table :data="equipmentData" style="width: 100%" height="100%" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" align="center" width="55" />
                        <el-table-column prop="equipmentName" align="center" show-overflow-tooltip 
                            :label="$t('airportManager.deviceName')" />
                        <el-table-column prop="serialNumber" align="center" show-overflow-tooltip 
                            :label="$t('airportManager.serialNumber')" />
                        <el-table-column prop="equipmentType" align="center" show-overflow-tooltip 
                            :label="$t('airportManager.devicetype')" />
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCloseEquipment">取 消</el-button>
                <el-button size="small" type="primary" :loading="saveLoading" @click="submitEquipment">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {getEquipmentList} from '@/api/airportManager/index';

export default {
    name: 'airport-information',
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            }
        },
        // 机场信息
        informationData: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    data() {
        return {
            airportData: {}, // 机场信息
            equipmentSourceData: [], // 设备源数据
            equipmentData: [], // 过滤后的设备数据
            multipleSelection: [], // 选择的设备数据
            equipmentDialog: false, // 设备弹窗
            searchVal: '', // 搜索设备值
            editPoint: false, // 编辑端点弹窗
            saveLoading: false, // 保存loading
            // 正在进行编辑的端点信息（端点集合下标，经纬度）
            formInPonits: {
                index: 0,
                lon: '',
                lat: ''
            }
        }
    },
    computed: {
        airportPointLength() {
            return this.airportData.content.coordinates[0].length;
        }
    },
    watch: {
        'informationData': {
            deep: true,
            handler: function(val) {
                this.airportData = val ? JSON.parse(JSON.stringify(val)) : {};
            }
        }
    },
    created() {
        this.airportData = this.informationData ? JSON.parse(JSON.stringify(this.informationData)) : {};
    },
    methods: {
        // 关闭属性面板
        closeInformation() {
            this.$emit('closeAirportPanel');
        },
        // 属性面板所有的数据变更都走saveValue函数进行转发调用接口保存
        saveValue(type, value, isPolygon, isDeletePoint, changeData) {
            if (type === 'number' && !isPolygon) {
                if (!value) {
                    return this.$message.warning('数值不能为空!');
                }
                if (value && Number(value) < 1) {
                    return this.$message.warning('数值不能小于1!');
                }
            }
            if (type === 'equipment') {
                // 单独处理机场设备的更新
                this.$emit('changeAirportEquipment', value, (responseType) => {
                    if (responseType === 'error') {
                        this.saveLoading = false;
                        return;
                    }
                    this.handleCloseEquipment();
                });
            } else {
                let changePoint = type === 'number' ? true : false;
                let airportData = isDeletePoint ? changeData || this.airportData : this.airportData;
                this.$emit('changeAirportValue', airportData, (responseType) => {
                    if (responseType === 'error') {
                        this.saveLoading = false;
                        return;
                    }
                    this.cancelEditHandle();
                }, changePoint, isDeletePoint);
            }
        },
        // 删除机场
        deleteAirport() {
            this.$emit('deleteAirportData', this.airportData);
        },
        // 删除机场设备
        deleteEquipment(data) {
            let equipmentVOSItem = this.airportData.equipmentVOS.find(t => t.id === data.id);
            let equipmentIdsItem = this.airportData.equipmentIds.find(t => t === data.id);
            let airportData = JSON.parse(JSON.stringify(this.airportData));
            if (equipmentVOSItem) {
                let index = this.airportData.equipmentVOS.indexOf(equipmentVOSItem);
                airportData.equipmentVOS.splice(index, 1);
            }
            if (equipmentIdsItem) {
                let index = this.airportData.equipmentIds.indexOf(equipmentIdsItem);
                airportData.equipmentIds.splice(index, 1);
            }
            this.saveValue('equipment', airportData);
        },
        // 关闭机场设备选择弹窗
        handleCloseEquipment() {
            this.equipmentDialog = false;
            this.searchVal = '';
            this.equipmentSourceData = [];
            this.equipmentData = [];
            this.multipleSelection = [];
            this.saveLoading = false;
        },
        // 打开机场设备选择弹窗
        handleOpenEquipment() {
            let params = {
                selectType: 'uav',
                current: 1,
                size: 9999
            };
            getEquipmentList(params).then(res => {
                if (res.data.code == 200) {
                    this.equipmentSourceData = res.data.data ? (res.data.data.records || []) : [];
                    this.equipmentData = this.equipmentSourceData;
                    this.saveLoading = false;
                    this.equipmentDialog = true;
                }
            });
        },
        // 搜素设备
        handleSearch() {
            let data = this.equipmentSourceData.filter(t => t.equipmentName.includes(this.searchVal));
            this.equipmentData = data;
        },
        // 设备选择
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 保存选择的设备
        submitEquipment() {
            if (!this.multipleSelection && !this.multipleSelection.length) {
                return this.$message.warning('请选择要添加的设备!');
            }
            let addEquipmentId = [];
            let addEquipment = [];
            this.multipleSelection.map(t => {
                if (!this.airportData.equipmentVOS) {
                    addEquipment.push({
                        equipmentName: t.equipmentName,
                        id: t.id,
                        sn: t.serialNumber
                    });
                    addEquipmentId.push(t.id);
                } else {
                    let equipmentVOSItem = this.airportData.equipmentVOS.find(i => i.id === t.id);
                    if (!equipmentVOSItem) {
                        addEquipment.push({
                            equipmentName: t.equipmentName,
                            id: t.id,
                            sn: t.serialNumber
                        });
                        addEquipmentId.push(t.id);
                    }
                }
            });
            let airportData = JSON.parse(JSON.stringify(this.airportData));
            if (airportData.equipmentVOS) {
                airportData.equipmentVOS = [...airportData.equipmentVOS, ...addEquipment];
            } else {
                airportData.equipmentVOS = addEquipment;
            }
            if (airportData.equipmentIds) {
                airportData.equipmentIds = [...airportData.equipmentIds, ...addEquipmentId];
            } else {
                airportData.equipmentIds = addEquipmentId;
            }
            this.saveLoading = true;
            this.saveValue('equipment', airportData);
        },
        // 打开编辑端点
        editPointHandle(item, index) {
            this.saveLoading = false;
            this.formInPonits = {
                index,
                lon: item[0],
                lat: item[1]
            };
            this.editPoint = true;
        },
        // 增加端点
        plusPoint(index) {
            this.$emit('addPoint', {...this.airportData, insertPointIndex: index});
        },
        // 删除端点
        deletePoint(item, index) {
            if (this.airportData.content.coordinates[0].length <= 4) {
                this.$message.warning(this.$t("airportManager.cannotdeleted"));
                return;
            }
            let point = this.airportData.content.coordinates[0][index];
            if (point[0] === item[0] && point[1] === item[1]) {
                let airportData = JSON.parse(JSON.stringify(this.airportData));
                airportData.content.coordinates[0].splice(index, 1);
                this.saveValue('number', '', true, true, airportData);
            }
        },
        // 关闭修改端点弹窗
        cancelEditHandle() {
            this.editPoint = false;
            this.saveLoading = false;
            this.formInPonits = {
                index: 0,
                lon: '',
                lat: ''
            };
        },
        // 端点信息修改保存
        saveEditHandle() {
            if (!this.formInPonits.lon || !this.formInPonits.lat) {
                return this.$message.warning('数值不能为空!');
            }
            if (Number(this.formInPonits.lon) < 1 || Number(this.formInPonits.lat) < 1) {
                return this.$message.warning('数值不能小于1!');
            }
            let lon = this.formInPonits.lon ? Number(this.formInPonits.lon) : 1;
            let lat = this.formInPonits.lat ? Number(this.formInPonits.lat) : 1;
            this.airportData.content.coordinates[0][this.formInPonits.index] = [lon, lat];
            if (this.formInPonits.index === 0) {
                let length = this.airportData.content.coordinates[0].length;
                this.airportData.content.coordinates[0][length - 1] = [lon, lat];
            }
            this.saveLoading = true;
            this.saveValue('number', '', true);
        }
    }
}
</script>

<style lang="scss" scoped>
.airport-information-main {
    width: 324px;
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    background: #1E222A;
    .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            border-bottom: 1px solid #030303;
            color: #FFFFFF;
            padding: 9px 0;
            position: relative;
            span {
                font-weight: bold;
                font-size: 14px;
            }
            .el-icon-close {
                font-size: 20px;
                position: absolute;
                right: 10px;
                top: 11px;
                cursor: pointer;
            }
        }
        .information {
            height: 0;
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow-y: auto;
            .information-list {
                display: flex;
                padding: 15px 10px;
                flex-direction: column;
                border-top: 1px solid #030303;
                &:first-child {
                    border: none;
                }
                &.information-list-point {
                    height: 0;
                    flex: 1;
                }
                .list-name {
                    display: flex;
                    align-items: center;
                    .point-img {
                        width: 16px;
                        height: 18px;
                    }
                    .polygon-img {
                        width: 19px;
                        height: 19px;
                    }
                    .list-name_input {
                        flex: 1;
                        margin: 0 8px;
                        ::v-deep .el-input {
                            width: 100%;
                            .el-input__inner {
                                background-color: #030303;
                                border: none;
                                color: #FFFFFF;
                                font-size: 14px;
                            }
                        }
                    }
                    .el-icon-delete {
                        font-size: 17px;
                        color: #FFFFFF;
                        margin-left: 7px;
                        cursor: pointer;
                    }
                }
                .list-title {
                    display: flex;
                    align-items: center;
                    .list-title-text {
                        display: flex;
                        align-items: center;
                        flex: 1;
                    }
                    p {
                        width: 4px;
                        height: 14px;
                        background: #265EDD;
                    }
                    span {
                        font-weight: bold;
                        font-size: 14px;
                        color: #FFFFFF;
                        margin-left: 6px;
                    }
                    .list-title-add {
                        font-size: 14px;
                        color: #409EFF;
                        font-weight: 400;
                        font-style: normal;
                        cursor: pointer;
                    }
                }
                .base-ins {
                    .base-ins_item {
                        display: flex;
                        align-items: center;
                        .ins_item {
                            flex: 1;
                            padding-top: 13px;
                            .ins_item-label {
                                font-weight: 400;
                                font-size: 14px;
                                font-style: normal;
                               
                                color: #999999;
                            }
                            .ins_item-val {
                                font-weight: 400;
                                font-size: 14px;
                                font-style: normal;
                                color: #FFFFFF;
                                margin-top: 5px;
                            }
                        }
                    }
                }
                .list-msg {
                    display: flex;
                    align-items: center;
                    padding-top: 13px;
                    .label {
                        width: 110px;
                        font-size: 14px;
                        color: #FFFFFF;
                        margin-right: 5px;
                    }
                    .msg {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        font-size: 14px;
                        color: #FFFFFF;
                        ::v-deep .el-input, .el-input-number {
                            width: 100%;
                            .el-input__inner {
                                background-color: #030303;
                                border: none;
                                color: #FFFFFF;
                                font-size: 14px;
                                text-align: left;
                            }
                        }
                        span {
                            margin-left: 5px;
                        }
                    }
                }
                .equipment-list {
                    max-height: 20vh;
                    padding-top: 13px;
                    text-align: right;
                    overflow: auto;
                    ::v-deep .el-table {
                        &::before {
                            background-color: transparent;
                        }
                        background-color: transparent;
                        .el-table__header-wrapper {
                            tr, th {
                                background-color: #40434A;
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                color: #FFFFFF;
                                padding: 0;
                                height: 35px;
                                border: none;
                            }
                            th.is-leaf {
                                border: none;
                            }
                        }
                        .el-table__body-wrapper {
                            tr, td {
                                background-color: transparent;
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                color: #FFFFFF;
                                padding: 0;
                                height: 35px;
                                border-bottom: 1px solid #40434A;
                                .el-icon-delete {
                                    cursor: pointer;
                                }
                            }
                            td.is-leaf {
                                border-bottom: 1px solid #40434A;
                            }
                        }
                    }
                }
                .edit-point-box {
                    height: 0;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    color: #ffffff;
                    margin-top: 10px;
                    overflow: auto;
                    .entity-item {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;
                        & > div {
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 14px;
                            margin-right: 6px;
                        }
                        & > i {
                            font-size: 12px;
                            margin-right: 4px;
                            cursor: pointer;
                        }
                        & > i:hover {
                            background-color: #409eff;
                        }
                    }
                    .entity-line-index {
                        width: 24px;
                        background: #454545;
                        border-radius: 2px;
                        opacity: 0.55;
                        text-align: center;
                        font-size: 14px;
                    }
                    ::v-deep .el-input, .el-input-number {
                        width: 100%;
                        .el-input__inner {
                            background-color: #030303;
                            border: none;
                            color: #FFFFFF;
                            font-size: 14px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .equipmentDialog {
        ::v-deep .el-dialog {
            background-color: rgba(30, 34, 42, 1);
            .el-dialog__header {
                .el-dialog__title {
                    font-size: 16px;
                    color: #FFFFFF;
                }
                .el-dialog__headerbtn .el-dialog__close {
                    color: #ffffff;
                }
            }
            .el-dialog__body {
                padding: 15px 0;
                .content {
                    display: flex;
                    flex-direction: column;
                    .search_main {
                        padding: 0 20px;
                        margin-bottom: 10px;
                        .el-input {
                            width: 220px;
                            .el-input__inner {
                                font-size: 14px;
                                color: #ffffff;
                                background-color: transparent;
                                &::placeholder {
                                    color:  rgba(153, 153, 153, 0.79);
                                }
                            }
                        }
                    }
                    .tabel_main {
                        height: 300px;
                        overflow: auto;
                        .el-table {
                            &::before {
                                background-color: transparent;
                            }
                            background-color: transparent;
                            .el-table__header-wrapper {
                                tr, th {
                                    background-color: #40434A;
                                    font-weight: 700;
                                    font-style: normal;
                                    font-size: 12px;
                                    color: #FFFFFF;
                                    padding: 0;
                                    height: 40px;
                                    border: none;
                                }
                                th.is-leaf {
                                    border: none;
                                }
                            }
                            .el-table__body-wrapper {
                                tr, td {
                                    background-color: transparent;
                                    font-weight: 700;
                                    font-style: normal;
                                    font-size: 12px;
                                    color: #FFFFFF;
                                    padding: 0;
                                    height: 40px;
                                    border-bottom: 1px solid #40434A;
                                    .el-icon-delete {
                                        cursor: pointer;
                                    }
                                }
                                td.is-leaf {
                                    border-bottom: 1px solid #40434A;
                                }
                            }
                        }
                    }
                    .page_main {
                        text-align: right;
                        padding: 0 20px;
                        margin-top: 10px;
                        .el-pagination {
                            .el-pagination__total {
                                color: #ffffff;
                            }
                            .el-input__inner {
                                color: #ffffff;
                                background-color: transparent;
                            }
                            .el-pagination__jump {
                                color: #ffffff;
                            }
                            button:disabled {
                                background-color: transparent;
                                color: #556064;
                            }
                            .btn-next {
                                background-color: transparent;
                                color: #FFFFFF;
                            }
                            .btn-prev {
                                background-color: transparent;
                                color: #FFFFFF;
                            }
                            .el-pager, .el-pager li {
                                background-color: transparent;
                                color: #FFFFFF;
                                &.active {
                                    color: #409EFF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>