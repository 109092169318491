<template>
  <div
    @click="cancelDelete"
    v-if="visible"
    class="mark-marker-panel">
    <!-- 加载蒙版 -->
    <div
      element-loading-background="rgba(0, 0, 0, 0.5)"
      v-show="loading"
      class="mark-loading"
      v-loading="loading"
    ></div>
    <div class="panel-title">
      <b></b>
      <span class="title-text">{{ $t("mark.info") }}</span>
      <i @click="handleClose" class="el-icon-close pointer-icon"></i>
    </div>
    <div class="panel-info">
      <div class="entity-info">
        <img width="16px" :src="`/img/map/${drawType}-active.png`" alt="" />
        <el-input
          :disabled="!canEdit"
          style="width: 160px; margin-left: 5px"
          size="small"
          v-model="input"
          @blur="markUpdate"
        />
        <i
          @click="flyTo"
          style="margin-left: 5px"
          class="el-icon-aim pointer-icon"
        >
        </i>
        <i
          :class="deleteClass"
          @click.stop="deletedMark"
          style="margin-left: 5px"
          class="el-icon-delete pointer-icon"
        >
        </i>
      </div>
      <div v-if="type === NodeMap.Node.POINT" class="entity-item">
        <div class="item-title">{{ $t("icon") }}</div>
        <img style="vertical-align: middle;" v-if="!isFire" width="16px" :src="`/img/map/${drawType}-active.png`" alt="" />
        <img style="vertical-align: middle;" v-else width="18px" :src="`/img/map/firePoint.png`" alt="" />
        <i 
          style="margin-left: 4px; vertical-align: middle; cursor: pointer;" 
          @click="editPointSemantics"
          class="el-icon-edit"></i>
      </div>
      <div class="entity-colors">
        {{$t('color')}}
        <span
          v-for="item in colorList"
          :key="item"
          @click="changeColor(item)"
          :class="color===item? 'bgc-img': ''"
          :style="`background-color: ${item};`">
        </span>
      </div>
      <div v-show="showHandlePath" class="entity-item">
        <div class="item-title">{{ $t("folder") }}</div>
        <div class="item-content-box">
          <el-select
            :disabled="!canEdit"
            size="small"
            style="width: 150px"
            @change="pathChange"
            v-model="path"
            placeholder="请选择"
          >
            <el-option
              v-for="item in pathList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="panel-info">
      <el-collapse accordion :value="1">
        <el-collapse-item :name="1">
          <template slot="title">
            <div class="collapse-panel-Separator">
              {{ $t("inspection.baseInfo") }}
            </div>
          </template>
          <!-- 类型 -->
          <!-- <div class="entity-item">
            <div style="width: 80px" class="item-title">{{ $t("type") }}</div>
            <div class="item-content-box">
              {{ $t(`mark.${drawType}`) }}
            </div>
          </div> -->
          <!-- 长度 -->
          <div class="entity-item" v-if="type === NodeMap.Node.LINE">
            <div style="width: 80px" class="item-title">
              {{ $t("mark.horizontalDistance") }}
            </div>
            <div class="item-content-box">
              {{ distance }}
            </div>
          </div>
          <div class="entity-item" v-if="type === NodeMap.Node.POLYGON">
            <div style="width: 80px" class="item-title">
              {{ $t("mark.horizontalPerimeter") }}
            </div>
            <div class="item-content-box">
              {{ distance }}
            </div>
          </div>
          <div class="entity-item" v-if="type === NodeMap.Node.POLYGON">
            <div style="width: 80px" class="item-title">
              {{ $t("mark.horizontalArea") }}
            </div>
            <div class="item-content-box">
              {{ area }}
            </div>
          </div>
          <!-- 创建人 -->
          <div class="entity-item">
            <div style="width: 80px" class="item-title">
              {{ $t("inspection.createBy") }}
            </div>
            <div class="item-content-box">
              {{ createBy }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-if="type === NodeMap.Node.LINE || type === NodeMap.Node.POLYGON" class="panel-last edit-box">
      <div class="panel-Separator">
        {{ $t("inspection.pointInfo") }}
      </div>
      <div v-if="!editPoint" class="edit-point-box">
        <div
          v-for="(item, index) in linePositions"
          :key="item.id"
          class="entity-item entity-line-item"
        >
          <div class="entity-line-index">{{ index + 1 }}</div>
          <div>[{{ item.lon.toFixed(7) }}, {{ item.lat.toFixed(7) }}]</div>
          <i
            @click="editPointHandle(item, index)"
            class="el-icon-edit"
            :class="!canEdit ? 'cannotEdit' : ''"
          ></i>
          <i
            @click="plusPoint(item, index)"
            class="el-icon-plus"
            :class="!canEdit ? 'cannotEdit' : ''"
          ></i>
          <i
            @click.stop="deletePoint(item, index)"
            :class="
              !canEdit
                ? 'cannotEdit'
                : deletePointId === item.id
                ? 'bgc-red'
                : ''
            "
            class="el-icon-delete"
          ></i>
        </div>
      </div>
      <div v-else class="edit-point-box">
        <div class="entity-line-index">{{ pointInfo.index + 1 }}</div>
        <el-form size="mini" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('longitude')">
            <el-input-number
              :disabled="!canEdit"
              :controls="false"
              @blur="markUpdate"
              controls-position="right"
              style="width: 130px; margin-left: 5px"
              v-model="pointInfo.lon"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item :label="$t('latitude')">
            <el-input-number
              :disabled="!canEdit"
              :controls="false"
              @blur="markUpdate"
              controls-position="right"
              style="width: 130px; margin-left: 5px"
              v-model="pointInfo.lat"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancelEditHandle">取消</el-button>
            <el-button
              :loading="saveLinePointLoading"
              type="primary"
              @click="saveEditHandle"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-else class="panel-last edit-box">
      <div class="panel-Separator">
        {{ $t("inspection.survey") }}
      </div>
      <div>
        <div class="entity-item">
          <div class="item-title">{{ $t("longitude") }}</div>
          <div class="item-content-box">
            <el-input-number
              :disabled="!canEdit"
              size="small"
              style="width: 130px; margin-left: 5px"
              v-model="lng"
              :controls="false"
              @blur="markUpdate"
              controls-position="right"
            >
            </el-input-number>
            °
          </div>
        </div>
        <div class="entity-item">
          <div class="item-title">{{ $t("latitude") }}</div>
          <div class="item-content-box">
            <el-input-number
              :disabled="!canEdit"
              size="small"
              style="width: 130px; margin-left: 5px"
              v-model="lat"
              :controls="false"
              @blur="markUpdate"
              controls-position="right"
            >
            </el-input-number>
            °
          </div>
        </div>
      </div>
    </div>
    <!-- 标注点切换火场 -->
    <el-dialog
      width="450px"
      :title="$t('mark.icon')"
      :visible.sync="dialogFireVisible"
      :before-close="handleCloseFire"
      class="common-map-dialog private-mark-markpoint-panel-dialog"
      :close-on-click-modal="false"
      append-to-body>
      <div>
        <div>{{ $t('mark.pointDesc') }}</div>
        <div class="pointDesc-box">
          <span
            @click="changePointSemantics(false)"
            class="pointDesc-item"
            :class="isFire? '': 'pointDesc-item-active'">
            <img style="vertical-align: middle;" width="24px" :src="`/img/map/${drawType}-active.png`" alt="" />
            <span>{{ $t('mark.coordinatePoint') }}</span>
          </span>
          <span
            @click="changePointSemantics(true)"
            class="pointDesc-item"
            :class="isFire? 'pointDesc-item-active': ''">
            <img style="vertical-align: middle;" width="28px" :src="`/img/map/firePoint.png`" alt="" />
            <span>{{ $t('mark.firePoint') }}</span>
          </span>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="handleCloseFire">{{ $t("cancelText") }}</el-button>
        <el-button @click="handleEditFire" type="primary">{{ $t("submitText") }}</el-button>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import NodeMap from "@/components/cesium/js/node/index";

export default {
  name: "MapRightPanel",
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    entity: {
      type: Object,
      default() {
        return {};
      },
    },
    pathList: {
      type: Array,
      default() {
        return [];
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return true;
      },
    },
    colorList: {
      type: Array,
      default() {
        return []
      }
    },
    showHandlePath: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  watch: {
    entity: {
      handler(value) {
        if (value && value.id) {
          // 这种写法以后有时间改进
          this.drawType = value.drawType;
          this.input = value.text;
          this.id = value.id;
          this.color = value.color;
          this.lng = value.lng;
          this.lat = value.lat;
          this.height = value.height;
          this.type = value.type;
          this.distance = value.distance;
          this.area = value.area
          this.createBy = value.createBy;
          this.clickOne = false;
          this.path = value.path;
          this.linePositions = value.linePositions;
          this.editPoint = false;
          this.isFire = value.isFire;
        }
      },
      deep: true,
    },
    visible() {
      this.deletePointId = "";
    },
  },
  data() {
    return {
      NodeMap,
      loading: false,
      clickOne: false,
      // 几何类型
      type: "",
      // 业务类型
      drawType: "",
      // 记录当前entity的id
      id: "",
      input: "",
      color: "",
      path: "",
      lng: null,
      lat: null,
      height: null,
      horizontal: null,
      distance: "0m",
      createBy: "",
      isFire: false,
      linePositions: [],
      editPoint: false,
      pointInfo: {
        index: 0,
        lon: 0,
        lat: 0,
      },
      saveLinePointLoading: false,
      deletePointId: "",
      dialogFireVisible: false
    };
  },
  computed: {
    deleteClass() {
      var className = "";
      if (this.clickOne) {
        className = "bgc-red";
      }
      if (!this.canEdit) {
        className = className + " cannotEdit";
      }
      return className;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
      this.clickOne = false;
      this.$emit("update:visible", false);
      this.reset();
    },
    
    reset() {
      this.input = "";
      this.id = "";
      this.color = "";
      this.lng = null;
      this.lat = null;
      this.height = null;
      this.type = "";
      this.distance = "";
      this.createBy = "";
      this.clickOne = false;
      this.path = "";
      this.linePositions = [];
    },

    markUpdate() {
      this.$emit("updateEntity", {
        id: this.id,
        text: this.input,
        lng: this.lng,
        lat: this.lat,
        path: this.path,
        height: this.height,
        type: this.type,
        color: this.color,
        positions: this.linePositions,
        isFire: this.isFire
      });
    },

    flyTo() {
      if (this.type === NodeMap.Node.POINT) {
        this.$EventBus.$emit(
          "map-flyTo",
          parseFloat(this.lng),
          parseFloat(this.lat)
        );
      } else {
        this.$EventBus.$emit("fly-to-by-id", this.id);
      }
    },

    deletedMark(id) {
      if (!this.canEdit) {
        return;
      }
      if (this.clickOne) {
        this.$EventBus.$emit("delete-mark-by-id", this.id);
        this.clickOne = false;
      } else {
        this.clickOne = true;
      }
    },

    editPointHandle(node, index) {
      if (!this.canEdit) {
        return;
      }
      this.pointInfo = {
        index: index,
        lon: node.lon,
        lat: node.lat,
      };
      this.editPoint = true;
    },

    saveEditHandle() {
      let position = this.linePositions[this.pointInfo.index];
      position.lon = this.pointInfo.lon;
      position.lat = this.pointInfo.lat;
      this.$emit(
        "updateEntity",
        {
          id: this.id,
          text: this.input,
          lng: this.lng,
          lat: this.lat,
          height: this.height,
          type: this.type,
          positions: this.linePositions,
          color: this.color
        },
        () => {
          this.saveLinePointLoading = false;
          this.editPoint = false;
        }
      );
    },

    plusPoint(item, index) {
      if (!this.canEdit) {
        return;
      }
      this.$emit("addPoint", {
        id: this.id,
        text: this.input,
        lng: this.lng,
        lat: this.lat,
        height: this.height,
        type: this.type,
        positions: this.linePositions,
        item,
        index,
      });
    },

    deletePoint(item, index) {
      if (!this.canEdit) {
        return;
      }
      if (this.linePositions.length == 2) {
        this.$message.warning(this.$t("inspection.cannotdeleted"));
        return;
      }
      if (this.deletePointId !== item.id) {
        this.deletePointId = item.id;
      } else {
        this.$emit("removePoint", {
          id: this.id,
          text: this.input,
          lng: this.lng,
          lat: this.lat,
          height: this.height,
          type: this.type,
          positions: this.linePositions,
          item,
          index,
        });
        this.deletePointId = "";
      }
    },

    cancelEditHandle() {
      this.editPoint = false;
    },

    cancelDelete() {
      this.clickOne = false;
      this.deletePointId = "";
    },

    pathChange(value) {
      this.$emit("updateEntity", {
        id: this.id,
        text: this.input,
        lng: this.lng,
        lat: this.lat,
        height: this.height,
        path: this.path,
        color: this.color,
        type: this.type,
        positions: this.linePositions,
      });
    },

    changeColor(color) {
      this.color = color
      this.markUpdate()
    },

    editPointSemantics() {
      this.dialogFireVisible = true
    },

    handleCloseFire() {
      this.dialogFireVisible = false
    },

    handleEditFire() {
      this.handleCloseFire()
      this.markUpdate()
    },

    changePointSemantics(value) {
      this.isFire = value
    }
  }
};
</script>
<style lang="scss">
.mark-marker-panel {
  height: 100%;
  box-sizing: border-box;
  color: #fff;
  background-color: #1e222a;
  width: 278px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  .panel-title {
    padding: 0 16px;
    height: 45px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #030303 solid;
  }
  .panel-info {
    flex-shrink: 0;
    padding: 16px 16px 16px 16px;
    border-bottom: 1px #030303 solid;
    .entity-colors{
      margin-top: 8px;
      span{
        vertical-align: middle;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        cursor: pointer;
        margin-left: 8px;
        display: inline-block;
      }
      .bgc-img{
        background-image: url(/img/map/gou.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90% 90%;
      }
    }
    .entity-item {
      margin-top: 10px;
      .item-title {
        display: inline-block;
        width: 50px;
        vertical-align: middle;
        font-size: 14px;
      }
      .item-content-box {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      .item-content-input {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .entity-line-item {
      & > div {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-right: 4px;
      }

      & > i {
        font-size: 12px;
        margin-right: 2px;
        cursor: pointer;
      }
      & > i:hover {
        background-color: #409eff;
      }
    }
    .entity-line-index {
      width: 24px;
      background: #454545;
      border-radius: 2px;
      opacity: 0.55;
      text-align: center;
      font-size: 14px;
    }
    .el-collapse {
      border: none;
      .el-collapse-item__header {
        background-color: transparent;
        border: none;
        color: #ffff;
        height: 21px;
        .collapse-panel-Separator {
          height: 100%;
          border-left: 4px solid rgba(64, 158, 255, 1);
          padding-left: 16px;
          line-height: 21px;
          font-size: 14px;
        }
      }
      .el-collapse-item__wrap {
        background-color: transparent;
        border-bottom: none;
        .el-collapse-item__content {
          color: #ffffff;
          font-size: 14px;
          padding-bottom: 0px;
        }
      }
    }
  }
  .panel-last {
    padding: 16px 16px 16px 16px;
    flex-grow: 1;
    font-size: 14px;
    .entity-item {
      margin-top: 18px;
      .item-title {
        display: inline-block;
        width: 50px;
        vertical-align: middle;
      }
      .item-content-box {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      .item-content-input {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .entity-line-item {
      & > div {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-right: 4px;
      }

      & > i {
        font-size: 12px;
        margin-right: 2px;
        cursor: pointer;
      }
      & > i:hover {
        background-color: #409eff;
      }
    }
    .entity-line-index {
      width: 24px;
      background: #454545;
      border-radius: 2px;
      opacity: 0.55;
      text-align: center;
      font-size: 14px;
    }
  }
  .pointer-icon {
    cursor: pointer;
  }
  .el-input__inner {
    background-color: #000000 !important;
    border: 1px solid #535353 !important;
    color: #ffffff !important;
  }
  input::-webkit-input-placeholder {
    -webkit-text-fill-color: #535353 !important;
  }
  .bgc-red {
    background: red;
  }
  .bgc-red:hover {
    background: red !important;
  }
  .panel-Separator {
    padding-left: 16px;
    margin-bottom: 8px;
    border-left: 4px solid rgba(64, 158, 255, 1);
  }
  .edit-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .edit-point-box {
      margin-top: 2px;
      flex-grow: 1;
      overflow: auto;
    }
  }
  .cannotEdit {
    cursor: not-allowed !important;
  }
}
.private-mark-markpoint-panel-dialog {
  .item-content-box {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  .setting-left-label {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    text-align: right;
  }
  .el-dialog__body {
    position: relative;
    .pointDesc-box {
      margin-top: 16px;
      .pointDesc-item {
        display: inline-block;
        vertical-align: middle;
        padding: 4px 8px;
        cursor: pointer;
      }
      .pointDesc-item:hover {
        background-color: #0099FF99;
      }
      .pointDesc-item:not(:first-child) {
        margin-left: 16px;
      }
    }
    .pointDesc-item-active {
      background-color: #0099FF99;
    }
  }
  .mark-loading {
    position: absolute !important;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
  }
}
</style>
