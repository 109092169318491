<script>
import DrawPointOperation from "../js/interactive/operation/DrawPointOperation";
import { cartesian2wgs } from "@/util/map";
import NodeMap from "../js/node/index";
import { getProjectCenterPointStyle } from "../js/style/index";
export default {
  name: "project",
  data() {
    return {};
  },
  methods: {
    // 设置home点
    setHomePointHandle() {
      this.drawFinish = false;
      this.tips.tip = "点击绘制";
      this.clearProjectLayer();
      this.$options.map3d.setOperation(
        new DrawPointOperation({
          layer: this.projectLayer,
          id: "homePoint-" + Cesium.createGuid(),
          drawType: NodeMap.HomePoint.DRAWTYPE,
          afterFinish: (point, drawType) => {
            if (!point) return;
            let coord;
            if (!point.position.getValue) {
              coord = point.position;
            } else {
              coord = point.position.getValue(Date.now());
            }
            const d = cartesian2wgs(this.$options.map3d, coord, false);
            this.homePointTemp = d;
            this.$store.dispatch("setHomePoint", d);
            this.drawFinish = true;
          },
        })
      );
    },
    // 设置项目坐标
    setProjectCenterPoint(project) {
      this.clearProjectLayer();
      this.tips.tip = "点击绘制";
      this.$options.map3d._container.style.cursor = "crosshair";
      this.drawFinish = false;
      this.$options.map3d.setOperation(
        new DrawPointOperation({
          map: this.$options.map3d,
          layer: this.projectLayer,
          drawType: NodeMap.ProjectCenterPoint.DRAWTYPE,
          afterFinish: (feature) => {
            // 重设项目中心点
            this.$store.dispatch("setHandlerType", null);
            const position = feature.position.getValue(Date.now());
            if (!position) return;
            const pos = cartesian2wgs(this.$options.map3d, position);
            this.$options.map3d._container.style.cursor = "default";
            this.$store.dispatch("setProjectPoint", pos);
            this.projectLayer.addNode(new NodeMap.ProjectCenterPoint({
                id: project.id,
                position
            }));
            this.drawFinish = true;
          },
        })
      );
    },
    // 设置
    changeSelectProject(projectId) {
      let entities = this.projectLayer.entities.values;
      entities.forEach((x) => {
        if (x.id === projectId) {
          x.billboard.color = Cesium.Color.AQUA;
        } else {
          x.billboard.color = Cesium.Color.WHITE;
        }
      });
    },
    //
    clearProjectLayer() {
      this.projectLayer.clear();
    },
    //
    addProjectPoints(pointList) {
      if (!pointList || pointList.length <= 0) {
        return;
      }
      this.clearProjectLayer();
      for (let i = 0; i < pointList.length; i += 1) {
        const centerpoint = pointList[i].centerPoint;
        if (!centerpoint) {
          continue;
        }
        const point = centerpoint.split(",");
        const lon = point[0] * 1
        const lat = point[1] * 1
        if (!isNaN(lon) && !isNaN(lat)) {
          let cartesian = Cesium.Cartesian3.fromDegrees(
            lon,
            lat
          );
          this.projectLayer.addNode(
            new NodeMap.ProjectCenterPoint({
              id: pointList[i].id,
              position: cartesian
            })
          );
        }
      }
    }
  },
};
</script>
