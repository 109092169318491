<script>
import {
  getWaringNode,
} from "../js/style/index";
import WarningDetail from '../components/warning-detail'
import BaseLayer from "../js/BaseLayer";
import { mapGetters } from "vuex";
import NodeMap from "../js/node/index";
import AIwarning from "@/assets/img/map/AIwarning.png";
import AIwarningPointActive from "@/assets/img/map/AIwarning-active.png";

export default {
    name: 'warningVue',
    components: { WarningDetail },
    data() {
        return {
            // 判断是否是AI报警使用
            isAI: false,
            // 报警详情数据
            showWarningDetail: false,
            // 报警图层
            warningLayer: null,
        }
    },
    computed: {
        ...mapGetters([
            "selectWarning"
        ])
    },
    watch: {
        selectWarning: {
            handler: function (val) {
                if (val &&  val.id) {
                this.changeSelectWarning(val);
                }
            },
        // immediate: true,
        deep: true
        },
    },
    methods: {
        // 初始化判断是否为AI报警弹窗使用
        initIsAI(type) {
            this.isAI = type;
        },
        // 初始化图层
        initWarningLayer(map3d) {
            this.warningLayer = new BaseLayer({
                name: "$报警展示图层$",
                id: "$报警展示图层$"
            });
            if (map3d) {
                map3d.layerManager.add(this.warningLayer);
            } else {
                this.$options.map3d.layerManager.add(this.warningLayer);
            }
        },
        // 增加报警点
        addWarningPoints() {
            for (var i = 0, l = this.warningList.length; i < l; i++) {
                let item = this.warningList[i]
                let cartesian = Cesium.Cartesian3.fromDegrees(
                item.lng,
                item.lat
                );
                const en = new Cesium.Entity({
                id: item.id,
                position: cartesian,
                ...getWaringNode(),
                });
                en.drawType = "warning";
                this.warningLayer.add(en);
            }
        },
        // 更新报警点
        updateWarning() {
            this.clearWarningLayer()
            for (var i = 0, l = this.warningList.length; i < l; i++) {
                let item = this.warningList[i]
                let position = Cesium.Cartesian3.fromDegrees(
                    item.lng,
                    item.lat
                );
                let WarningPointConfig = {
                    id: item.id,
                    show: item.show,
                    position
                };
                if (this.isAI) {
                    WarningPointConfig.icon = AIwarning;
                    WarningPointConfig.selectIcon = AIwarningPointActive;
                }
                const node = new NodeMap.WarningPoint(WarningPointConfig)
                this.warningLayer.addNode(node)
            }
        },
        // 选中报警点
        changeSelectWarning(node) {
            var id = node.id
            let entities = this.warningLayer.entities.values;
            if (node && node.id) {
                this.showWarningDetail = true
            }
            entities.forEach((x) => {
                if (x.id === id) {
                    if (this.isAI) {
                        x._billboard._image._value = "/img/map/warning/AIwarning-active.png";
                    } else {
                        x._billboard._image._value = "/img/map/warning/warning-active.png";
                    }
                } else {
                    if (this.isAI) {
                        x._billboard._image._value = "/img/map/warning/AIwarning.png";
                    } else {
                        x._billboard._image._value = "/img/map/warning/warning.png";
                    }
                }
            });
        },
        // 关闭报警详情面板
        closeWarningDetail(){
            this.showWarningDetail = false
            this.$store.dispatch('setSelectWarning', {})
        },
        // 关闭所有弹窗
        closeAllDialog() {
            this.showWarningDetail = false
        },
        clearWarningLayer() {
            this.warningLayer.clear();
        },
        WarningBeforeDestroy() {
            this.$store.dispatch("setSelectWarning", {});
        },
        // 更新列表
        updataList() {
            this.$EventBus.$emit('updata-list');
        }
    }
}
</script>