<script>
import BaseLayer from "../js/BaseLayer";
import NodeMap from "../js/node/index";


export default {
    name: 'Route',
    data(){
        return {
          showRoute: true
        }
    },
    methods: {
        initRoute(map) {
          this.$options.routeLayer = new BaseLayer()
          if (map) {
            map.layerManager.add(this.$options.routeLayer)
            this.$options.map3d = map
          } else if (this.$options.map3d) {
            this.$options.map3d.layerManager.add(this.$options.routeLayer)
          } else {
            throw '未检到map3d对象'
          }
          this.initEvent()
        },
        // 绑定事件
        initEvent() {
          this.$EventBus.$off('route-manager-click')
          this.$EventBus.$on('route-manager-click', this.chooseRoute)
        },
        // 当有航线被选中时触发
        chooseRoute(data){
          // 清楚当前图层中所有航线
          this.clearRouteNodes()
          // 加载选中航线
          this.addRoute(data)
          setTimeout(()=>{
            const node = this.$options.routeLayer.getNodeById(data.id)
            if (node instanceof NodeMap.Point) {
              this.$options.map3d.zoomTo( node._entity, new Cesium.HeadingPitchRange(0, -90, 2000));
            } else {
              this.$options.map3d.zoomTo(node._entity, new Cesium.HeadingPitchRange(0, -90, 0));
            }
          }, 50)
        },
        addRoute(node) {
          const geojson = node.geom
          let coordinates = geojson.coordinates
          var positions = []
          for (let k = 0; k < coordinates.length; k++) {
            const coordinate = coordinates[k];
            var position = Cesium.Cartesian3.fromDegrees(
              ...coordinate
            )
            positions.push(position)
          }
          let route = new NodeMap.Route({
            id: node.id,
            name: node.name,
            positions,
            canEidt: node.canEidt === false? false : true
          })
          this.$options.routeLayer.addNode(route)
        },
        // 清楚航线图层中的内容
        clearRouteNodes() {
          this.$options.routeLayer.removeAllNode()
        },
        // 销毁时调用
        routeBeforeDestroy() {
          this.$EventBus.$off('route-manager-click')
        }

    }

}
</script>