<script>
import {
  getProhibitData
} from "@/api/project/map";
import * as turf from "@turf/turf";
import BaseLayer from "../js/BaseLayer";
export default {
    name: 'forbidden',
    data(){
        return {
            // 禁飞区
            forbiddenLayer: null,
            //
            showForBidden: false,
            forbidden: [],
            flylineGeo: null,
            noFlyZone: false,
        }
    },
    methods: {
        initForbiddenLayer(map3d) {
            this.forbiddenLayer = new BaseLayer();
            if (map3d) {
                map3d.layerManager.add(this.forbiddenLayer);
            } else {
                this.$options.map3d.layerManager.add(this.forbiddenLayer);
            }
        },
        clearForbiddenLayer() {
            this.forbiddenLayer.clear();
        },

        getBanFlyZone() {
            //todo  还原
            const extent = this.getCurrentExtent();
            if (!extent.xmin || !extent.ymin || !extent.xmax || !extent.ymax) {
                return;
            }
            const rectangle = [
                [extent.xmin, extent.ymin],
                [extent.xmax, extent.ymin],
                [extent.xmax, extent.ymax],
                [extent.xmin, extent.ymax],
            ];

            const request = getProhibitData(rectangle);
            request.then((res) => {
                this.clearForbiddenLayer()
                if (res.data.code == 200) {
                const result = res.data.data;
                if (result && result.length > 0) {
                    for (let i = 0, l = result.length; i < l; i += 1) {
                    const geometry = result[i].geometry;
                    this.forbidden.push(new turf.polygon(geometry.coordinates));
                    const analysis = this.geometry2cartesain(geometry);
                    if (!analysis) {
                        continue;
                    }
                    const plan = new Cesium.Entity({
                        polygon: {
                        hierarchy: new Cesium.PolygonHierarchy(analysis.coord),
                        material: Cesium.Color.RED.withAlpha(0.3),
                        outline: true,
                        outlineColor: Cesium.Color.RED,
                        outlineWidth: 3,
                        height: 0,
                        },
                    });
                    this.forbiddenLayer.add(plan);
                    }
                }
                }
            });
        },

        triggleForBidden() {
            this.showForBidden = !this.showForBidden;
            this.forbiddenLayer.show = this.showForBidden;
            if (this.showForBidden) {
                this.getBanFlyZone();
                return;
            }
            this.noFlyZone = false;
        },

        checkForBidden() {
            if (this.flylineGeo && this.forbidden.length > 0 && this.showForBidden) {
                for (let i = 0; i < this.forbidden.length; i += 1) {
                const forBidden = this.forbidden[i];
                const inForBidden = turf.booleanIntersects(
                    forBidden,
                    this.flylineGeo
                );
                if (inForBidden) {
                    this.noFlyZone = true;
                    return;
                }
                }
            }
            this.noFlyZone = false;
        },

        geometry2cartesain(geometry) {
            let max = [0, 0];
            let min = [1000, 1000];
            if (geometry.type === "Polygon") {
                let coord = [];
                geometry.coordinates.map((hole) => {
                    hole.map((point) => {
                        const lng = point[0];
                        const lat = point[1];
                        if (max[0] < lng) {
                        max[0] = lng;
                        }
                        if (max[1] < lat) {
                        max[1] = lat;
                        }
                        if (min[0] > lng) {
                        min[0] = lng;
                        }
                        if (min[1] > lat) {
                        min[1] = lat;
                        }
                        coord.push(Cesium.Cartesian3.fromDegrees(lng, lat));
                    });
                });
                return {
                    bound: [...min, ...max],
                    coord,
                };
            }
            return null;
        },
    }
}
</script>