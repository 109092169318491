<script>
import DrawPointOperation from "../js/interactive/operation/DrawPointOperation";
import DrawPolyLineOperation from "../js/interactive/operation/DrawPolyLineOperation";
import DrawPolygonOperation from "../js/interactive/operation/DrawPolygonOperation";
import BaseLayer from "../js/BaseLayer";
import { labelEntity2geojson } from "@/util/map";
import NodeMap from "../js/node/index";
import airportPanel  from "../components/airport-panel";
import {getAirportDetail, addEditAirport, editAirportEquipment} from "@/api/airportManager/index";

export default {
    components: {airportPanel},
    data() {
        return {
            showAirportPanel: false,
            airportEntity: {}
        }
    },
    methods: {
        // 绘制机场点
        drawAirportpoint() {
            this.setDrawAirportPointOperation(NodeMap.AirportPoint);
        },
        // 绘制机场面
        drawAirportPolygon() {
            this.setDrawAirportPolygonOperation(NodeMap.AirportPolygon);
        },
        // 点交互机制
        setDrawAirportPointOperation(Node) {
            if (!this.drawFinish) {
                this.drawFinish = true;
                this.setEditModel();
                return;
            }
            this.activeDraw = Node.DRAWTYPE;
            this.drawFinish = false;
            this.tips.tip = "点击绘制";
            this.$options.map3d._container.style.cursor = "crosshair";
            const editLayer = this.airportLayers[0];
            this.$options.map3d.setOperation(
                new DrawPointOperation({
                    map: this.$options.map3d,
                    drawType: Node.DRAWTYPE,
                    layer: editLayer.layer,
                    afterFinish: (entity, type, done) => {
                        this.$options.map3d._container.style.cursor = "default";
                        this.getAirportToSave(entity, NodeMap.Node.POINT).then((data) => {
                            done({...data, name: data.airportName}, Node);
                            this.closeAirportPanel();
                            this.setAirportEntity(data);
                            this.emitListUpdate(data);
                            this.selectAirportById(data.id);
                            editLayer.pointCount++;
                            this.openAirportPanel();
                        });
                        this.drawFinish = true;
                        this.setEditModel();
                    }
                })
            );
        },
        // 面交互机制
        setDrawAirportPolygonOperation(Node) {
            if (!this.drawFinish) {
                this.drawFinish = true;
                this.setEditModel();
                return;
            }
            this.activeDraw = Node.DRAWTYPE;
            this.drawFinish = false;
            this.tips.tip = "点击绘制,双击结束";
            this.$options.map3d._container.style.cursor = "crosshair";
            const editLayer = this.airportLayers[0];
            this.$options.map3d.setOperation(
                new DrawPolygonOperation({
                    map: this.$options.map3d,
                    drawType: Node.DRAWTYPE,
                    layer: editLayer.layer,
                    afterFinish: (positions) => {
                        this.$options.map3d._container.style.cursor = "default";
                        this.getAirportToSave(
                            // 临时创建一个虚拟node使用
                            new Node({
                                id: Cesium.createGuid(),
                                positions: positions
                            }).entity,
                            NodeMap.Node.POLYGON
                        ).then((data) => {
                            // 重新创建实体
                            const node = new Node({
                                id: data.id,
                                positions: positions,
                                name: data.airportName,
                            });
                            editLayer.layer.addNode(node);
                            let points = node.getInflectionPoint();
                            let linePositions = points.map((item) => {
                                let position = item.entity.position._value;
                                let cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
                                return {
                                    lon: Cesium.Math.toDegrees(cartographic.longitude),
                                    lat: Cesium.Math.toDegrees(cartographic.latitude),
                                    id: item.id,
                                };
                            });
                            this.closeAirportPanel();
                            this.setAirportEntity({...data, linePositions: linePositions});
                            this.emitListUpdate(data);
                            this.selectAirportById(data.id);
                            editLayer.polygonCount++;
                            this.openAirportPanel();
                        });
                        this.drawFinish = true;
                        this.setEditModel();
                    }
                })
            );
        },

        // 初始化绘制机场
        initAirport(data) {
            this.airportLayers[0] = {
                layer: new BaseLayer({name: '$机场图层$', id: '$机场图层$'}),
                pointCount: 0,
                polygonCount: 0
            };
            this.$options.map3d.layerManager.add(this.airportLayers[0].layer);
            data && data.map(t => {
                let layer;
                if (t.content.type === 'Point') {
                    layer = new NodeMap.AirportPoint({
                        id: t.id,
                        name: t.airportName,
                        position: Cesium.Cartesian3.fromDegrees(...t.content.coordinates),
                    });
                    this.airportLayers[0].pointCount++;
                } else if (t.content.type === 'Polygon') {
                    let positions = [];
                    const coordinates = t.content.coordinates;
                    for (let k = 0; k < coordinates[0].length - 1; k++) {
                        const coordinate = coordinates[0][k];
                        const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                        positions.push(position);
                    }
                    layer = new NodeMap.AirportPolygon({
                        id: t.id,
                        name: t.airportName,
                        positions
                    });
                    this.airportLayers[0].polygonCount++;
                }
                layer && this.airportLayers[0].layer.addNode(layer);
            });
            this.setEditModel();
        },
        // 定位机场
        locationAirport(data) {
            if (!data) return;
            let range = 4000;
            const node = this.airportLayers[0].layer.getNodeById(data.id);
            if (node && node.entity) {
                range = node.drawType === 'airportPoint' ? 4000 : 0;
                // this.$options.map3d.flyTo(node.entity, {
                //     offset: new Cesium.HeadingPitchRange(0, -90, range), // 设置heading,pitch和range
                //     duration: 1.5
                // });
                this.$options.map3d.zoomTo(
                    node.entity,
                    new Cesium.HeadingPitchRange(0, -90, range)
                )
            }
        },
        // 更新左侧列表
        emitListUpdate(data) {
            this.$EventBus.$emit('airport-left-update', data);
        },
        // 删除机场
        deleteAirport(data) {
            this.$EventBus.$emit('airport-delete', data);
        },
        deleteAirportSuccess(data) {
            if (!data) return;
            let node = this.airportLayers[0].layer.getNodeById(data.id);
            if (node) this.airportLayers[0].layer.removeNodeById(data.id);
            if (data.content.type === 'Point') {
                this.airportLayers[0].pointCount--;
            } else if (data.content.type === 'Polygon') {
                this.airportLayers[0].polygonCount--;
            }
            this.closeAirportPanel();
        },
        // 增加机场面拐点
        addAirportEntityPoint(data) {
            let editLayer = null;
            let insertNode = null;
            if (!this.drawFinish) {
                this.drawFinish = true;
                this.setEditModel(true);
                return;
            }
            insertNode = this.airportLayers[0].layer.getNodeById(data.id);
            if (insertNode) editLayer = this.airportLayers[0].layer;
            if (!editLayer) {
                throw "请查看该图元图层是否存在";
            }
            this.activeDraw = insertNode.drawType;
            this.drawFinish = false;
            this.tips.tip = "点击绘制";
            this.$options.map3d._container.style.cursor = "crosshair";
            this.$options.map3d.setOperation(
                new DrawPolyLineOperation({
                    map: this.$options.map3d,
                    drawType: insertNode.drawType,
                    layer: editLayer,
                    insert: true,
                    insertIndex: data.insertPointIndex,
                    insertNode,
                    afterFinish: (node) => {
                        this.drawFinish = true;
                        this.$options.map3d._container.style.cursor = "default";
                        this.setEditModel({selectFeature: insertNode});
                        let geojson = this.getEntityGeojson(node.entity, 'Polygon');
                        let airportEntityData = JSON.parse(JSON.stringify(data));
                        airportEntityData.content = geojson;
                        this.updateAirport(airportEntityData);
                    }
                })
            );
            this.$options.map3d.interactive._current.selectInsertNode();
        },
        // 更新机场
        updateAirport(data, done, changePoint, isDeletePoint) {
            let airportEntityData = JSON.parse(JSON.stringify(data));
            if (!data.id) return;
            let node, entity, position, positions = [], geojson;
            node = this.airportLayers[0].layer.getNodeById(data.id);
            if (!node) {
                throw "未在地图中找到应对entity, 无法更新";
            }
            entity = node.entity;
            const drawType = node.drawType;
            switch (drawType) {
                case NodeMap.AirportPoint.DRAWTYPE:
                    node.name = data.airportName;
                    if (changePoint) {
                        position = Cesium.Cartesian3.fromDegrees(...data.content.coordinates, 0);
                        node.position = position;
                    }
                    geojson = this.getEntityGeojson(entity, 'Point');
                    break;
                case NodeMap.AirportPolygon.DRAWTYPE:
                    if (isDeletePoint) {
                        // 删除点的话，去除之前旧的节点，创建新的实体节点
                        this.airportLayers[0].layer.removeNodeById(data.id);
                        const coordinates = data.content.coordinates;
                        for (let k = 0; k < coordinates[0].length - 1; k++) {
                            const coordinate = coordinates[0][k];
                            const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                            positions.push(position);
                        }
                        const newLayer = new NodeMap.AirportPolygon({
                            id: data.id,
                            name: data.airportName,
                            positions
                        });
                        entity = newLayer.entity;
                        this.airportLayers[0].layer.addNode(newLayer);
                    } else {
                        entity.label.text._value = data.airportName;
                        if (changePoint) {
                            const coordinates = data.content.coordinates;
                            for (let k = 0; k < coordinates[0].length - 1; k++) {
                                const coordinate = coordinates[0][k];
                                const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                                positions.push(position);
                            }
                            node.positions = positions;
                        }
                    }
                    geojson = this.getEntityGeojson(entity, 'Polygon');
                    break;
            }
            airportEntityData.content = geojson;
            delete airportEntityData.equipmentIds;
            delete airportEntityData.equipmentVOS;
            this.setEditModel();
            this.selectAirportById(data.id);
            addEditAirport(airportEntityData).then(res => {
                if (res.data.code == 200) {
                    this.setAirportEntity(res.data.data || {});
                    this.emitListUpdate(airportEntityData);
                    done && done('success');
                } else {
                    done && done('error');
                }
            }).catch(() => {
                done && done('error');
            });
        },
        // 通过地图的operation类改变和更新
        updateAirportByOperation(node) {
            if (!node) return;
            let geojson;
            switch (node.type) {
                case NodeMap.Node.POINT:
                    geojson = this.getEntityGeojson(node.entity, 'Point');
                    break;
                case NodeMap.Node.POLYGON:
                    geojson = this.getEntityGeojson(node.entity, 'Polygon');
                break;
            }
            let data = JSON.parse(JSON.stringify(this.airportEntity));
            this.setEditModel();
            this.selectAirportById(node.id);
            data.content = geojson;
            delete data.equipmentIds;
            delete data.equipmentVOS;
            if (this.airportEntity.id === node.id) {
                addEditAirport(data).then(res => {
                    if (res.data.code == 200) {
                        this.setAirportEntity(res.data.data || {});
                        this.emitListUpdate(this.airportEntity);
                    }
                });
            }
        },
        // 单独更新机场设备
        updateAirportEquipment(data, done) {
            let equipmentData = {
                id: data.id,
                equipmentIds: data.equipmentIds || [],
                equipmentVOS: data.equipmentVOS || []
            };
            editAirportEquipment(equipmentData).then(res => {
                if (res.data.code == 200) {
                    this.setAirportEntity(res.data.data || {});
                    done && done('success');
                } else {
                    done && done('error');
                }
            }).catch(() => {
                done && done('error');
            });
        },
        // 点选中机场对象
        selectedAirportNode(selectFeature) {
            const node = selectFeature.getRootNode();
            this.$EventBus.$emit('choose-airport-data', node);
            const type = node.type
            let points, linePositions;
            switch (type) {
                case NodeMap.Node.POLYGON:
                    points = node.getInflectionPoint();
                    linePositions = points.map((item) => {
                        let position = item.entity.position._value;
                        let cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
                        return {
                            lon: Cesium.Math.toDegrees(cartographic.longitude),
                            lat: Cesium.Math.toDegrees(cartographic.latitude),
                            id: item.id,
                        };
                    });
                    break
            }
            getAirportDetail({id: node.id}).then(res => {
                if (res.data.code === 200) {
                    let data;
                    switch (type) {
                        case NodeMap.Node.POLYGON:
                            data = {...(res.data.data || {}), linePositions: linePositions};
                            break;
                        default:
                            data = res.data.data || {};
                            break;
                    }
                    this.setAirportEntity(data);
                    this.openAirportPanel();
                } else {
                    this.showAirportPanel = false;
                }
            }).catch(() => {
                this.showAirportPanel = false;
            });
        },
        // 选择机场对象
        selectAirportById(id) {
            if (this.$options.map3d.interactive._current.selectFeatureByEntity) {
                let entity;
                for (let i = 0; i < this.airportLayers.length; i++) {
                    const layer = this.airportLayers[i].layer;
                    entity = layer.getById(id);
                    if (entity) {
                        this.$options.map3d.interactive._current.selectFeatureByEntity(entity);
                        break;
                    }
                }
            }
        },
        // 关闭右侧属性面板
        closeAirportPanel() {
            let operation = this.$options.map3d.interactive._current
            if (operation && operation.selectFeature && operation.selectFeature.category === NodeMap.Node.AIRPORT) {
                this.$options.map3d.interactive._current.cancelSelectFeature();
            }
            this.showAirportPanel = false;
            this.airportEntity = {};
            this.$EventBus.$emit('clear-choose-airport-data');
        },
        // 开启右侧属性面板
        openAirportPanel() {
            this.showAirportPanel = true;
        },
        // 设置右侧面板属性
        setAirportEntity(value) {
            this.airportEntity = Object.assign({}, value);
        },
        // 清空机场层
        clearAirportLayers() {
            this.airportLayers[0].layer.clear();
        },
        // entity转geojson保存
        getAirportToSave(entity, type) {
            return new Promise((resolve, reject) => {
                let geojson, params;
                if (type === NodeMap.Node.POINT) {
                    geojson = this.getEntityGeojson(entity, 'Point');
                    params = {
                        airportType: 0,
                        content: geojson
                    }
                } else if (type === NodeMap.Node.POLYGON) {
                    geojson = this.getEntityGeojson(entity, 'Polygon');
                    params = {
                        airportType: 1,
                        content: geojson
                    }
                }
                addEditAirport(params).then((res) => {
                    const code = res.data.code;
                    if (code === 200) {
                        resolve(res.data.data);
                        this.$message.success("操作成功");
                    }
                }).catch(() => {
                    reject();
                });
            });
        },
        getEntityGeojson(entity, type) {
            let geojson = labelEntity2geojson(this.$options.map3d, entity, type);
            if (geojson.geometry.type === 'Polygon') {
                let length = geojson.geometry.coordinates[0].length;
                let lastPoint = geojson.geometry.coordinates[0][length -1];
                let lastsPoint = geojson.geometry.coordinates[0][length -2];
                if (lastPoint[0] == lastsPoint[0] && lastPoint[1] == lastsPoint[1]) {
                    geojson.geometry.coordinates[0].splice(length - 1, 1);
                }
            }
            return geojson;
        }
    }
}
</script>