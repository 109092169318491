<script>
import DrawPointOperation from "../js/interactive/operation/DrawPointOperation";
import DrawPolyLineOperation from "../js/interactive/operation/DrawPolyLineOperation";
import DrawPolygonOperation from "../js/interactive/operation/DrawPolygonOperation";
import { labelNode2geojson } from "@/util/map";
import markPanel  from "../components/mark-panel";
import NodeMap from "../js/node/index";
import {
  // 标注点
  saveMarkPoint,
  getMarkPoint,
  deleteMarkPoint,
  // 标注线
  saveMarkPolyline,
  getMarkPolyline,
  deleteMarkPolyline,
  // 标注面
  saveMarkPolygon,
  getMarkPolygon,
  deleteMarkPolygon
} from "@/api/project/map";

export default {
  components: { markPanel },
  data() {
    return {
      showMarkPanel: false,
      markEntity: {},
    };
  },
  methods: {
    // 绘制点
    drawMarkPoint() {
      this.setDrawMarkPointOperation(NodeMap.MarkPoint);
    },

    // 设置Point交互机制
    setDrawMarkPointOperation(Node) {
      if (!this.drawFinish) {
        this.drawFinish = true;
        this.setEditModel();
        return;
      }
      this.activeDraw = Node.DRAWTYPE;
      this.drawFinish = false;
      this.tips.tip = "点击绘制";
      this.$options.map3d._container.style.cursor = "crosshair";
      const editLayer = this.markLayers[this.currentLabelLayer];
      this.$options.map3d.setOperation(
        new DrawPointOperation({
          map: this.$options.map3d,
          drawType: Node.DRAWTYPE,
          layer: editLayer.layer,
          afterFinish: (entity, type, done) => {
            this.$options.map3d._container.style.cursor = "default";
            const cartographic = Cesium.Cartographic.fromCartesian(
              entity.position._value
            );
            const lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            const lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            const height = 0; // 高度
            this.getMarkToSave(new NodeMap.MarkPoint({
                id: Cesium.createGuid(),
                position: entity.position._value,
                color: this.markPointColor
            }),NodeMap.Node.POINT)
              .then((data) => {
                done({
                  id: data.id,
                  name: data.name,
                  dirId: data.dirId,
                  position: entity.position._value,
                  color: this.markPointColor,
                  createBy: data.createBy
                }, Node);
                this.setMarkEntity({
                  id: data.id,
                  type: NodeMap.Node.POINT,
                  drawType: Node.DRAWTYPE,
                  color: this.markPointColor,
                  text: data.name,
                  lng,
                  lat,
                  height,
                  path: data.dirId,
                  createBy: data.createBy,
                });
                if (this.modifyType === 2) {
                  this.emitTreeUpdate();
                }
                editLayer.pointCount++;
                this.openMarkPanel();
                this.selectMarkById(data.id);
              })
              .finally(() => {});
            this.drawFinish = true;
            this.setEditModel()
          }
        })
      );
    },

    // 绘制markline管线
    drawMarkline() {
      this.setDrawMarkLineOperation(NodeMap.MarkLine);
    },

    // 设置画line交互机制
    setDrawMarkLineOperation(Node) {
      if (!this.drawFinish) {
        this.drawFinish = true;
        this.setEditModel();
        return;
      }
      this.activeDraw = Node.DRAWTYPE;
      this.drawFinish = false;
      this.tips.tip = "点击绘制,双击结束";
      this.$options.map3d._container.style.cursor = "crosshair";
      const editLayer = this.markLayers[this.currentLabelLayer];
      this.$options.map3d.setOperation(
        new DrawPolyLineOperation({
          map: this.$options.map3d,
          layer: editLayer.layer,
          drawType: Node.DRAWTYPE,
          afterFinish: (positions) => {
            this.getMarkToSave(new NodeMap.MarkLine({
                id: Cesium.createGuid(),
                positions,
                color: this.markPolylineColor
            }))
              .then((data) => {
                let node = new Node({
                  id: data.id,
                  name: data.name,
                  positions,
                  dirId: data.dirId,
                  createBy: data.createBy,
                  color: this.markPolylineColor
                });
                editLayer.layer.addNode(node);
                let points = node.getInflectionPoint()
                let linePositions = points.map((item) => {
                  let position = item.entity.position._value;
                  let cartographic =
                    Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
                  return {
                    lon: Cesium.Math.toDegrees(cartographic.longitude),
                    lat: Cesium.Math.toDegrees(cartographic.latitude),
                    id: item.id,
                  };
                });
                let distance = '0m'
                let dis = node.entity.horiDistance.toFixed(2);
                let suffix = "m";
                if (dis > 1000) {
                  dis = (dis / 1000).toFixed(2);
                  suffix = "km";
                }
                distance = dis + suffix;
                this.setMarkEntity({
                  id: data.id,
                  type: NodeMap.Node.LINE,
                  drawType: Node.DRAWTYPE,
                  color: node.color,
                  text: data.name,
                  path: data.dirId,
                  distance,
                  createBy: data.createBy,
                  linePositions,
                });
                if (this.modifyType === 2) {
                  this.emitTreeUpdate();
                }
                this.openMarkPanel();           
                this.markLayers[0].lineCount += 1;
                this.selectMarkById(data.id);
              })
              .finally(() => {});
            this.drawFinish = true;
            this.setEditModel()
          },
        })
      );
    },

    // 绘制markPolygon管线
    drawMarkPolygon() {
      this.setDrawMarkPolygonOperation(NodeMap.MarkPolygon);
    },

    // 设置画Polygon交互机制
    setDrawMarkPolygonOperation(Node) {
      if (!this.drawFinish) {
        this.drawFinish = true;
        this.setEditModel();
        return;
      }
      this.activeDraw = Node.DRAWTYPE;
      this.drawFinish = false;
      this.tips.tip = "点击绘制,双击结束";
      this.$options.map3d._container.style.cursor = "crosshair";
      const editLayer = this.markLayers[0].layer;
      this.$options.map3d.setOperation(
        new DrawPolygonOperation({
          map: this.$options.map3d,
          layer: editLayer,
          drawType: Node.DRAWTYPE,
          afterFinish: (positions) => {
            this.getMarkToSave(new NodeMap.MarkPolygon({
                id: Cesium.createGuid(),
                positions,
                color: this.markPolygonColor
            })).then((data) => {
              let area = "0m²"
              let square = data.area
              if (square && square > 1000 * 1000) {
                area = (square / 1000 / 1000).toFixed(2) + 'km²'
              } else if (square && square <= 1000 * 1000) {
                area = square.toFixed(2)  + 'm²'
              }
              let node = new Node({
                id: data.id,
                name: data.name,
                positions,
                dirId: data.dirId,
                createBy: data.createBy,
                color: this.markPolygonColor
              });
              editLayer.addNode(node);
              let points = node.getInflectionPoint()
              let linePositions = points.map((item) => {
                let position = item.entity.position._value;
                let cartographic =
                  Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
                return {
                  lon: Cesium.Math.toDegrees(cartographic.longitude),
                  lat: Cesium.Math.toDegrees(cartographic.latitude),
                  id: item.id,
                };
              });
              let distance = '0m'
              let dis = node.entity.horiDistance.toFixed(2);
              let suffix = "m";
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = "km";
              }
              distance = dis + suffix;
              this.setMarkEntity({
                id: data.id,
                type: NodeMap.Node.LINE,
                drawType: Node.DRAWTYPE,
                color: node.color,
                text: data.name,
                path: data.dirId,
                distance,
                area,
                createBy: data.createBy,
                linePositions,
              });
              if (this.modifyType === 2) {
                  this.emitTreeUpdate();
                }
              this.openMarkPanel();
              this.markLayers[0].polygonCount += 1;
              this.selectMarkById(data.id);
            }).finally(() => {});
            this.drawFinish = true;
            this.setEditModel()
          },
        })
      );
    },

    // 标注线增加拐点
    addMarkLinePoint(v) {
      let editLayer = null;
      let insertNode = null;
      if (!this.drawFinish) {
        this.drawFinish = true;
        this.setEditModel(true);
        return;
      }
      for (let i = 0; i < this.markLayers.length; i++) {
        var item = this.markLayers[i];
        insertNode = item.layer.getNodeById(v.id);
        if (insertNode) {
          editLayer = item.layer;
          break;
        }
      }
      if (!editLayer) {
        throw "请查看该图元图层是否存在";
      }
      this.activeDraw = insertNode.drawType;
      this.drawFinish = false;
      this.tips.tip = "点击绘制";
      this.$options.map3d._container.style.cursor = "crosshair";
      this.$options.map3d.setOperation(
        new DrawPolyLineOperation({
          map: this.$options.map3d,
          layer: editLayer,
          drawType: insertNode.drawType,
          insert: true,
          insertIndex: v.index,
          insertNode,
          insertId: v.item.id,
          afterFinish: (node) => {
            const entity = node.entity
            const points = node.getInflectionPoint();
            const linePositions = points.map((item) => {
              const cartographic =Cesium.Ellipsoid.WGS84.cartesianToCartographic(item.position);
              return {
                lon: Cesium.Math.toDegrees(cartographic.longitude),
                lat: Cesium.Math.toDegrees(cartographic.latitude),
                id: item.id,
              };
            });
            this.drawFinish = true;
            let distance = "0m";
            if (entity && entity.horiDistance) {
              let dis = entity.horiDistance.toFixed(2);
              let suffix = "m";
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = "km";
              }
              distance = dis + suffix;
            }
            this.setEditModel({
              selectFeature: insertNode
            });
            switch (node.drawType) {
              case NodeMap.MarkLine.DRAWTYPE:
                saveMarkPolyline({
                  id: node.id,
                  content: labelNode2geojson(this.$options.map3d, insertNode)
                }).then(res=>{
                  if (res.data.code = 200) {
                    this.setMarkEntity({
                      id: insertNode.id,
                      type: node.type,
                      drawType: node.drawType,
                      color: node.color,
                      text: insertNode.name,
                      distance: insertNode.entity.horiDistance,
                      linePositions,
                      distance,
                      path: res.data.data.dirId,
                      createBy: insertNode.createBy,
                    });
                  }
                })
                break;
              case NodeMap.MarkPolygon.DRAWTYPE:
                saveMarkPolygon({
                  id: node.id,
                  content: labelNode2geojson(this.$options.map3d, insertNode)
                }).then(res=>{
                  if (res.data.code = 200) {
                    const data = res.data.data
                    let area = "0m²"
                    let square = data.area
                    if (square && square > 1000 * 1000) {
                      area = (square / 1000 / 1000).toFixed(2) + 'km²'
                    } else if (square && square <= 1000 * 1000) {
                      area = square.toFixed(2)  + 'm²'
                    }
                    this.setMarkEntity({
                      id: insertNode.id,
                      type: node.type,
                      drawType: node.drawType,
                      color: node.color,
                      text: insertNode.name,
                      distance: insertNode.entity.horiDistance,
                      linePositions,
                      distance,
                      path: res.data.data.dirId,
                      createBy: insertNode.createBy,
                    });
                  }
                })
                break;
              default:
                break;
            }
          }
        })
      );
      this.$options.map3d.interactive._current.selectInsertNode()
    },

    // 删除拐点
    removeMarkLinePoint(v) {
      let editLayer;
      // 获取编辑的图层
      for (let i = 0; i < this.markLayers.length; i++) {
        const layer = this.markLayers[i].layer;
        var node = layer.getNodeById(v.id);
        if (node) {
          editLayer = node.entity.editLayer;
          break;
        }
      }
      if (!editLayer) {
        return;
      }
      node.deletePointNode(v);
      const entity = node.entity
      let points = node.getInflectionPoint();
      let linePositions = points.map((item) => {
        let position = item.entity.position._value;
        let cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
        return {
          lon: Cesium.Math.toDegrees(cartographic.longitude),
          lat: Cesium.Math.toDegrees(cartographic.latitude),
          id: item.id,
        };
      });
      let distance = "0m";
      if (entity && entity.horiDistance) {
        let dis = entity.horiDistance.toFixed(2);
        let suffix = "m";
        if (dis > 1000) {
          dis = (dis / 1000).toFixed(2);
          suffix = "km";
        }
        distance = dis + suffix;
      }
      switch (node.type) {
        case NodeMap.Node.LINE:
          saveMarkPolyline({
            id: node.id,
            content: labelNode2geojson(this.$options.map3d, node)
          }).then(res=>{
            if (res.data.code = 200) {
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.LINE,
                drawType: NodeMap.MarkLine.DRAWTYPE,
                color: node.color,
                text: node.name,
                distance: node.entity.horiDistance,
                linePositions,
                distance,
                path: res.data.data.dirId,
                createBy: node.createBy,
              });
            }
          })
          break;
        case NodeMap.Node.POLYGON:
        saveMarkPolygon({
            id: node.id,
            content: labelNode2geojson(this.$options.map3d, node)
          }).then(res=>{
            if (res.data.code = 200) {
              const data = res.data.data
              let area = "0m²"
              let square = data.area
              if (square && square > 1000 * 1000) {
                area = (square / 1000 / 1000).toFixed(2) + 'km²'
              } else if (square && square <= 1000 * 1000) {
                area = square.toFixed(2)  + 'm²'
              }
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.LINE,
                drawType: NodeMap.MarkLine.DRAWTYPE,
                color: node.color,
                text: node.name,
                distance: node.entity.horiDistance,
                linePositions,
                distance,
                area,
                path: res.data.data.dirId,
                createBy: node.createBy,
              });
            }
          })
          break;
        default:
          break;
      }
      
    },

    // 点选中标注对象
    selectedMarkNode(selectFeature) {
      const node = selectFeature.getRootNode()
      const type = node.type
      let 
        cartographic, 
        lng, 
        lat, 
        height,
        dis,
        suffix,
        distance='0m', 
        linePositions,
        inflectionPoint,
        horiDistance = 0;
      switch (type) {
        case NodeMap.Node.POINT:
          cartographic = Cesium.Cartographic.fromCartesian(
            node.position
          );
          lng = Number(
            Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
          ); // 经度
          lat = Number(
            Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
          ); // 纬度
          height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
          getMarkPoint({
            id: node.id
          }).then(res=>{
            if (res.data.code === 200) {
              const data = res.data.data
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.POINT,
                drawType: NodeMap.MarkPoint.DRAWTYPE,
                color: node.color,
                text: data.name,
                lng,
                lat,
                isFire: node.isFire,
                path: data.dirId,
                height: height.toFixed(1),
                createBy: node.createBy,
              });
            }
          })
          break;
        case NodeMap.Node.LINE:
          inflectionPoint = node.getInflectionPoint();
          linePositions = inflectionPoint.map((item) => {
            let position = item.entity.position._value;
            let cartographic =
              Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
            return {
              lon: Cesium.Math.toDegrees(cartographic.longitude),
              lat: Cesium.Math.toDegrees(cartographic.latitude),
              id: item.id,
            };
          });
          horiDistance = node.entity.horiDistance
          // 转化单位
          dis = horiDistance.toFixed(2);
          suffix = "m";
          if (dis > 1000) {
            dis = (dis / 1000).toFixed(2);
            suffix = "km";
          }
          distance = dis + suffix;
          getMarkPolyline({
            id: node.id
          }).then(res=>{
            if (res.data.code === 200) {
              const data = res.data.data
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.LINE,
                color: node.color,
                drawType: NodeMap.MarkLine.DRAWTYPE,
                text: data.name,
                path: data.dirId,
                distance,
                linePositions,
                createBy: node.createBy
              });
            }
          })
          break;
        case NodeMap.Node.POLYGON:
          inflectionPoint = node.getInflectionPoint();
          linePositions = inflectionPoint.map((item) => {
            let position = item.entity.position._value;
            let cartographic =
              Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
            return {
              lon: Cesium.Math.toDegrees(cartographic.longitude),
              lat: Cesium.Math.toDegrees(cartographic.latitude),
              id: item.id,
            };
          });
          horiDistance = node.entity.horiDistance;
          // 转化单位
          dis = horiDistance.toFixed(2);
          suffix = "m";
          if (dis > 1000) {
            dis = (dis / 1000).toFixed(2);
            suffix = "km";
          }
          distance = dis + suffix;
          getMarkPolygon({
            id: node.id
          }).then(res=>{
            if (res.data.code === 200) {
              const data = res.data.data
              let area = "0m²"
              let square = data.area
              if (square && square > 1000 * 1000) {
                area = (square / 1000 / 1000).toFixed(2) + 'km²'
              } else if (square && square <= 1000 * 1000) {
                area = square.toFixed(2)  + 'm²'
              }
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.POLYGON,
                color: node.color,
                drawType: NodeMap.MarkPolygon.DRAWTYPE,
                text: data.name,
                path: data.dirId,
                distance,
                area,
                linePositions,
                createBy: node.createBy
              });
            }
          })
          break;
        default:
          break;
      }
      this.openMarkPanel();
    },

    // 关闭右侧属性面板
    closeMarkPanel() {
      let operation = this.$options.map3d.interactive._current
      if (operation && operation.selectFeature && operation.selectFeature.category ===  NodeMap.Node.MARK) {
        this.$options.map3d.interactive._current.cancelSelectFeature();
      }
      this.showMarkPanel = false;
    },

    // 开启右侧属性面板
    openMarkPanel() {
      this.showMarkPanel = true;
    },

    // 设置右侧面板属性
    setMarkEntity(value) {
      this.markEntity = Object.assign({}, value);
    },

    // 通过地图的operation类改变和更新
    updateMarkByOperation(node) {
      let geojson = labelNode2geojson(this.$options.map3d, node)
      const params = {
        id: node.id,
        content: geojson,
        dirId: this.markEntity.path
      };
      switch (node.type) {
        case NodeMap.Node.POINT:
          const cartographic = Cesium.Cartographic.fromCartesian(
            node.entity.position._value
          );
          const lng = Number(
            Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
          ); // 经度
          const lat = Number(
            Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
          ); // 纬度
          const height = 0; // 高度
          saveMarkPoint(params).then((res) => {
            const data = res.data.data?  res.data.data: {}
            this.setMarkEntity({
              id: node.id,
              type: NodeMap.Node.POINT,
              drawType: node.drawType,
              color: this.markPointColor,
              text: node.name,
              isFire: node.isFire,
              lng,
              lat,
              height,
              path: data.dirId,
              createBy: node.createBy,
            });
          });
          break;
        case NodeMap.Node.LINE:
          var points = node.getInflectionPoint()
          var entity = node.entity
          var linePositions = points.map((item) => {
            let position = item.entity.position._value;
            let cartographic =
              Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
            return {
              lon: Cesium.Math.toDegrees(cartographic.longitude),
              lat: Cesium.Math.toDegrees(cartographic.latitude),
              id: item.id,
            };
          });
          var distance = "0m";
          if (entity && entity.horiDistance) {
            let dis = entity.horiDistance.toFixed(2);
            let suffix = "m";
            if (dis > 1000) {
              dis = (dis / 1000).toFixed(2);
              suffix = "km";
            }
            distance = dis + suffix;
          }
          saveMarkPolyline(params).then((res) => {
            if (res.data.code === 200) {
              const data = res.data.data?  res.data.data: {}
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.LINE,
                drawType: NodeMap.MarkLine.DRAWTYPE,
                text: node.name,
                color: node.color,
                distance,
                path: data.dirId,
                createBy: node.createBy,
                linePositions,
              });
            }
          });
          break;
        case NodeMap.Node.POLYGON:
          var points = node.getInflectionPoint()
          var entity = node.entity
          var linePositions = points.map((item) => {
            let position = item.entity.position._value;
            let cartographic =
              Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
            return {
              lon: Cesium.Math.toDegrees(cartographic.longitude),
              lat: Cesium.Math.toDegrees(cartographic.latitude),
              id: item.id,
            };
          });
          var distance = "0m";
          if (entity && entity.horiDistance) {
            let dis = entity.horiDistance.toFixed(2);
            let suffix = "m";
            if (dis > 1000) {
              dis = (dis / 1000).toFixed(2);
              suffix = "km";
            }
            distance = dis + suffix;
          }
          saveMarkPolygon(params).then((res) => {
            if (res.data.code === 200) {
              const data = res.data.data?  res.data.data: {}
              let area = "0m²"
              let square = data.area
              if (square && square > 1000 * 1000) {
                area = (square / 1000 / 1000).toFixed(2) + 'km²'
              } else if (square && square <= 1000 * 1000) {
                area = square.toFixed(2)  + 'm²'
              }
              this.setMarkEntity({
                id: node.id,
                type: NodeMap.Node.POLYGON,
                drawType: NodeMap.MarkLine.DRAWTYPE,
                text: node.name,
                color: node.color,
                distance,
                area,
                path: data.dirId,
                createBy: node.createBy,
                linePositions,
              });
            }
          });
          break;
        default:
          break;
      }
    },

    // 更新地图中的实体
    updateMark(entityInfo, done) {
      return new Promise((resolve, reject) => {
        let geojson, params, node, entity, position, positions = [];
        const id = entityInfo.id;
        for (let i = 0; i < this.markLayers.length; i++) {
          const layer = this.markLayers[i].layer;
          node = layer.getNodeById(id);
          if (node) break;
        }
        if (!node) {
          throw "未在地图中找到应对entity, 无法更新";
        }
        entity = node.entity
        const drawType = node.drawType;
        const dirId = entityInfo.path;
        switch (drawType) {
          case NodeMap.MarkPoint.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            node.name = entityInfo.text;
            node.position = position;
            node.color = entityInfo.color? entityInfo.color: node.color
            node.isFire = entityInfo.isFire
            // 向后台发送请求
            geojson = labelNode2geojson(this.$options.map3d, node);
            params = {
              id: entityInfo.id,
              content: geojson,
              dirId,
              name: entityInfo.text
            };
            saveMarkPoint(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.MarkLine.DRAWTYPE:
            positions = entityInfo.positions.map(item=>{
              return Cesium.Cartesian3.fromDegrees(item.lon, item.lat ,0)
            })
            // 更改前端对应图层
            entity.label.text._value = entityInfo.text;
            node.positions = positions;
            node.color = entityInfo.color
            // 发送请求
            geojson = labelNode2geojson(this.$options.map3d, node);
            params = {
              id: entityInfo.id,
              content: geojson,
              dirId,
              name: entityInfo.text
            };
            saveMarkPolyline(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.MarkPolygon.DRAWTYPE:
            // 更改前端对应图层
            positions = entityInfo.positions.map(item=>{
              return Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
              )
            })
            entity.label.text._value = entityInfo.text;
            node.positions = positions;
            node.color = entityInfo.color
            geojson = labelNode2geojson(this.$options.map3d, node);
            params = {
              id: entityInfo.id,
              content: geojson,
              dirId,
              name: entityInfo.text
            };
            // todo 发送请求
            saveMarkPolygon(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
        }
      });
    },

    // 改变颜色
    changeColor(type, color) {
      switch (type) {
        case NodeMap.Node.POINT:
          this.markPointColor = color
          break;
        case NodeMap.Node.LINE:
        this.markPolylineColor = color
          break;
        case NodeMap.Node.POLYGON:
          this.markPolygonColor = color
          break;
        default:
          break;
      }
    },

    // 删除mark
    deleteMark(id) {
      let node, layer;
      for (let i = 0; i < this.markLayers.length; i++) {
        layer = this.markLayers[i].layer;
        node = layer.getNodeById(id);
        if (node) {
          break
        }
      }
      if (!node) {
        return
      }
      // todo 向后台发送请求，改变全局缓存，
      switch (node.drawType) {
        case NodeMap.MarkPoint.DRAWTYPE:
        deleteMarkPoint({
            ids: id,
          })
            .then(() => {
              layer.removeNodeById(id);
              this.closeMarkPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.MarkLine.DRAWTYPE:
          deleteMarkPolyline({
            ids: id,
          })
            .then(() => {
              layer.removeNodeById(id);
              this.closeMarkPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.MarkPolygon.DRAWTYPE:
          deleteMarkPolygon({
            ids: id,
          })
            .then(() => {
              layer.removeNodeById(id);
              this.closeMarkPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
      }
      
    },

    // 选择标注对象
    selectMarkById(id) {
      if (this.$options.map3d.interactive._current.selectFeatureByEntity) {
        let entity;
        for (let i = 0; i < this.markLayers.length; i++) {
          const layer = this.markLayers[i].layer;
          entity = layer.getById(id);
          if (entity) {
            this.$options.map3d.interactive._current.selectFeatureByEntity(
              entity
            );
            break;
          }
        }
      }
    },

    // 更新左树
    emitTreeUpdate() {
      this.$EventBus.$emit("left-tree-update");
    },

    // 清理图层，更新标注
    reloadMark() {
      this.clearMarkLayers();
      this.projectId = this.$route.query.projectId;
      this.requestMarkLabel(this.projectId);
      this.updateMarkPathList()
    },

    // 清理图层
    clearMarkLayers() {
      this.markLayers.forEach((x) => {
        this.$options.map3d.layerManager.remove(x.layer);
      });
      this.markLayers = [];
      this.currentLabelLayer = 0;
    },

    // mark是后台返回数据
    createMarkNode(mark) {
      // todo 改成mark类创建
      const geojson = mark.content;
      const type = geojson.geometry.type;
      const coordinates = geojson.geometry.coordinates;
      let node;
      if (type === "Point") {
        node = new NodeMap.MarkPoint({
          id: mark.id,
          name: mark.name,
          createBy: mark.createBy,
          show: !!mark.visibility,
          dirId: mark.dirId,
          color: geojson.properties.color,
          isFire: !!geojson.properties.icon,
          position: Cesium.Cartesian3.fromDegrees(...coordinates),
        });
      }
      if (type === "LineString") {
        var positions = [];
        for (let k = 0; k < coordinates.length; k++) {
          const coordinate = coordinates[k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        node = new NodeMap.MarkLine({
          id: mark.id,
          positions,
          dirId: mark.dirId,
          createBy: mark.createBy,
          color: geojson.properties.color,
          show: !!mark.visibility,
          name: mark.name
        });
      }
      if (type === "Polygon") {
        var positions = [];
        for (let k = 0; k < coordinates[0].length - 1; k++) {
          const coordinate = coordinates[0][k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        node = new NodeMap.MarkPolygon({
          id: mark.id,
          positions,
          dirId: mark.dirId,
          createBy: mark.createBy,
          color: geojson.properties.color,
          show: !!mark.visibility,
          name: mark.name,
        });
      }
      return node;
    },

    // entity转geojson保存
    getMarkToSave(node) {
      return new Promise((resolve, reject) => {
        const type = node.type
        const projectId = this.$route.query.projectId;
        let markDirId
        if (this.handlerType && this.handlerType.params) {
          markDirId = this.handlerType.params.groupId;
        }
        if (this.modifyType !== 2) {
          markDirId = undefined
        }
        const geojson = labelNode2geojson(this.$options.map3d, node);
        const params = {
          content: geojson,
          projectId,
          dirId: markDirId,
        }
        if (type === NodeMap.Node.LINE) {
          saveMarkPolyline(params).then((res) => {
            const code = res.data.code;
            if (code === 200) {
              resolve(res.data.data);
              this.$message.success("操作成功");
            }
          }).catch(() => {
            reject();
          });
        } else if (type === NodeMap.Node.POINT) {
          saveMarkPoint(params).then((res) => {
            const code = res.data.code;
            if (code === 200) {
              resolve(res.data.data);
              this.$message.success("操作成功");
            }
          }).catch(() => {
            reject();
          });
        } else if (type === NodeMap.Node.POLYGON) {
          saveMarkPolygon(params).then((res) => {
            const code = res.data.code;
            if (code === 200) {
              resolve(res.data.data);
              this.$message.success("操作成功");
            }
          }).catch(() => {
            reject();
          });
        }
      });
    },

    //显示隐藏标注
    visibilityMarks(marks, flag, requestToback) {
      let layer;
      // 找到图层
      for (let i = 0; i < this.markLayers.length; i++) {
        const _layer = this.markLayers[i].layer;
        let node = _layer.getNodeById(marks[0].id);
        if (node) {
          layer = _layer;
          break;
        }
      }
      if (!layer) {
        return;
      }
      for (let i = 0; i < marks.length; i++) {
        let id = marks[i].id;
        let dirId = marks[i].dirId
        let node = layer.getNodeById(id);
        if (node) {
          node.show = flag;
          if (id === this.markEntity.id) {
            this.showMarkPanel = false;
          }
          if (!requestToback) {
            continue
          }
          let drawType = node.drawType;
          switch (drawType) {
            case NodeMap.MarkPoint.DRAWTYPE:
              saveMarkPoint({
                id,
                dirId, 
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === marks.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.MarkLine.DRAWTYPE:
              saveMarkPolyline({
                id,
                dirId, 
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === marks.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.MarkPolygon.DRAWTYPE:
              saveMarkPolygon({
                id,
                dirId,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === marks.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
           }
        }
      }
    }
  }
};
</script>
