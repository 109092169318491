<script>
import DrawCircleOperation from "../js/interactive/operation/DrawCircleOperation";
import DrawPolyLineOperation from "../js/interactive/operation/DrawPolyLineOperation";
import DrawPolygonOperation from "../js/interactive/operation/DrawPolygonOperation";
import { labelEntity2geojson, tangentialCircle } from "@/util/map";
import NodeMap from "../js/node/index";
import {NoFlyCircle} from "../js/node/bussiness/noFlyZone/noFlyCircle";
import {NoFlyPolygon} from "../js/node/bussiness/noFlyZone/noFlyPolygon";
import {addEditNoFlyZone} from '@/api/noFlyZone/index';

export default {
    data() {
        return {
            noFlyEntity: {}
        }
    },
    methods: {
        // 手动绘制禁飞区
        drawNoFlyType(data) {
            switch(data.type) {
                case 'polygon':
                    this.drawNoFlyPolygon();
                    break;
                case 'circle':
                    this.drawNoFlyCircle();
                    break;
            }
        },
        // 绘制禁飞区圆
        drawNoFlyCircle() {
            this.setDrawNoFlyCircleOperation(NoFlyCircle);
        },
        // 绘制禁飞区多边形
        drawNoFlyPolygon() {
            this.setDrawNoFlyPolygonOperation(NoFlyPolygon);
        },
        // 圆交互机制
        setDrawNoFlyCircleOperation(Node) {
            if (!this.drawFinish) {
                this.drawFinish = true;
                this.setEditModel();
                return;
            }
            this.activeDraw = Node.DRAWTYPE;
            this.drawFinish = false;
            this.tips.tip = "点击绘制,拖动半径再次点击结束";
            this.$options.map3d._container.style.cursor = "crosshair";
            const editLayer = this.noFlyLayers[0];
            this.$options.map3d.setOperation(
                new DrawCircleOperation({
                    map: this.$options.map3d,
                    drawType: Node.DRAWTYPE,
                    layer: editLayer.layer,
                    afterFinish: (position, radius) => {
                        this.getNoFlyToSave(
                            // 临时创建一个虚拟node使用
                            new Node({
                                id: Cesium.createGuid(),
                                position: position,
                                radius
                            }).entity,
                            NodeMap.Node.POINT,
                        ).then((data) => {
                            // 重新创建实体
                            const node = new Node({
                                id: data.id,
                                position: position,
                                radius,
                                color: '#C600FF',
                                name: data.name
                            });
                            editLayer.layer.addNode(node);
                            this.$EventBus.$emit('clear-noFly-Panel');
                            this.$EventBus.$emit('noFly-set-information', {...data});
                            this.emitNoFlyListUpdate(data);
                            this.selectNoFlyById(data.id);
                            editLayer.circleCount++;
                            this.$EventBus.$emit('noFly-open-information-plane');
                        });
                        this.drawFinish = true;
                        this.setEditModel();
                    }
                })
            );
        },
        // 多边形交互机制
        setDrawNoFlyPolygonOperation(Node) {
            if (!this.drawFinish) {
                this.drawFinish = true;
                this.setEditModel();
                return;
            }
            this.activeDraw = Node.DRAWTYPE;
            this.drawFinish = false;
            this.tips.tip = "点击绘制,双击结束";
            this.$options.map3d._container.style.cursor = "crosshair";
            const editLayer = this.noFlyLayers[0];
            this.$options.map3d.setOperation(
                new DrawPolygonOperation({
                    map: this.$options.map3d,
                    drawType: Node.DRAWTYPE,
                    layer: editLayer.layer,
                    afterFinish: (positions) => {
                        this.$options.map3d._container.style.cursor = "default";
                        this.getNoFlyToSave(
                            // 临时创建一个虚拟node使用
                            new Node({
                                id: Cesium.createGuid(),
                                positions: positions
                            }).entity,
                            NodeMap.Node.POLYGON
                        ).then((data) => {
                            const node = new Node({
                                id: data.id,
                                positions: positions,
                                color: '#C600FF',
                                name: data.name,
                            });
                            editLayer.layer.addNode(node);
                            let points = node.getInflectionPoint();
                            let linePositions = points.map((item) => {
                                let position = item.entity.position._value;
                                let cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
                                return {
                                    lon: Cesium.Math.toDegrees(cartographic.longitude),
                                    lat: Cesium.Math.toDegrees(cartographic.latitude),
                                    id: item.id,
                                };
                            });
                            this.$EventBus.$emit('clear-noFly-Panel');
                            this.$EventBus.$emit('noFly-set-information', {...data, linePositions: linePositions});
                            this.emitNoFlyListUpdate(data);
                            this.selectNoFlyById(data.id);
                            editLayer.polygonCount++;
                            this.$EventBus.$emit('noFly-open-information-plane');
                        });
                        this.drawFinish = true;
                        this.setEditModel();
                    }
                })
            );
        },
        // 增加禁飞区面拐点
        addNoFlyEntityPoint(data) {
            let editLayer = null;
            let insertNode = null;
            if (!this.drawFinish) {
                this.drawFinish = true;
                this.setEditModel(true);
                return;
            }
            insertNode = this.noFlyLayers[0].layer.getNodeById(data.id);
            if (insertNode) editLayer = this.noFlyLayers[0].layer;
            if (!editLayer) {
                throw "请查看该图元图层是否存在";
            }
            this.activeDraw = insertNode.drawType;
            this.drawFinish = false;
            this.tips.tip = "点击绘制";
            this.$options.map3d._container.style.cursor = "crosshair";
            this.$options.map3d.setOperation(
                new DrawPolyLineOperation({
                    map: this.$options.map3d,
                    drawType: insertNode.drawType,
                    layer: editLayer,
                    insert: true,
                    insertIndex: data.insertPointIndex,
                    insertNode,
                    afterFinish: (node) => {
                        this.drawFinish = true;
                        this.$options.map3d._container.style.cursor = "default";
                        this.setEditModel({selectFeature: insertNode});
                        let geojson = this.getEntityGeojson(node.entity, 'Polygon');
                        let noFlyEntityData = JSON.parse(JSON.stringify(data));
                        noFlyEntityData.content = geojson;
                        this.updateNoFly({data: noFlyEntityData});
                    }
                })
            );
            this.$options.map3d.interactive._current.selectInsertNode();
        },

        // 初始化绘制禁飞区
        initNoFly(data) {
            this.noFlyLayers[0].circleCount = 0;
            this.noFlyLayers[0].polygonCount = 0;
            data && data.map(t => {
                let layer;
                if (t.type === 'Point') {
                    if (t.properties && t.properties.radius) {
                        layer = new NoFlyCircle({
                            id: t.id,
                            name: t.name,
                            position: Cesium.Cartesian3.fromDegrees(...t.coordinates),
                            radius: t.properties.radius,
                            color: '#C600FF'
                        });
                        this.noFlyLayers[0].circleCount++;
                    }
                } else if (t.type === 'Polygon') {
                    let positions = [];
                    const coordinates = t.coordinates;
                    for (let k = 0; k < coordinates[0].length - 1; k++) {
                        const coordinate = coordinates[0][k];
                        const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                        positions.push(position);
                    }
                    layer = new NoFlyPolygon({
                        id: t.id,
                        name: t.name,
                        positions,
                        color: '#C600FF'
                    });
                    this.noFlyLayers[0].polygonCount++;
                }
                layer && this.noFlyLayers[0].layer.addNode(layer);
            });
            this.setEditModel();
        },
        // 定位禁飞区
        locationNoFly(data) {
            if (!data) return;
            const node = this.noFlyLayers[0].layer.getNodeById(data.id);
            if (node && node.entity) {
                this.$options.map3d.zoomTo(
                    node.entity,
                    new Cesium.HeadingPitchRange(0, -90, 0)
                )
            }
        },
        // 更新左侧列表
        emitNoFlyListUpdate(data) {
            this.$EventBus.$emit('noFly-left-update', data);
        },
        // 删除禁飞区
        deleteNoFlySuccess(data) {
            if (!data) return;
            let node = this.noFlyLayers[0].layer.getNodeById(data.id);
            if (node) this.noFlyLayers[0].layer.removeNodeById(data.id);
            if (data.content.type === 'Point' && data.content.properties && data.content.properties.radius) {
                this.noFlyLayers[0].circleCount--;
            } else if (data.content.type === 'Polygon') {
                this.noFlyLayers[0].polygonCount--;
            }
        },
        // 更新禁飞区
        updateNoFly(changeData) {
            let {data, done, changePoint, isDeletePoint, changerRadius} = changeData;
            if (!data.id) return;
            let noFlyEntityData = JSON.parse(JSON.stringify(data));
            let node, entity, position, positions = [], linePoints, geojson;
            node = this.noFlyLayers[0].layer.getNodeById(data.id);
            if (!node) {
                throw "未在地图中找到应对entity, 无法更新";
            }
            entity = node.entity;
            const drawType = node.drawType;
            switch (drawType) {
                case NoFlyCircle.DRAWTYPE:
                    node.name = data.name;
                    if (changePoint) {
                        linePoints = tangentialCircle(
                            data.content.coordinates,
                            entity.ellipse.semiMajorAxis.getValue()
                        );
                        position = Cesium.Cartesian3.fromDegrees(...data.content.coordinates, 0);
                        node.position = position;
                        entity.polyline.positions = linePoints;
                        entity.polygon.hierarchy = new Cesium.PolygonHierarchy(linePoints);
                    }
                    if (changerRadius) {
                        linePoints = tangentialCircle(
                            data.content.coordinates,
                            data.content.properties.radius * 1
                        );
                        entity.ellipse.semiMinorAxis = data.content.properties.radius * 1;
                        entity.ellipse.semiMajorAxis = data.content.properties.radius * 1;
                        entity.polyline.positions = linePoints;
                        entity.polygon.hierarchy = new Cesium.PolygonHierarchy(linePoints);
                    }
                    geojson = this.getEntityGeojson(entity, 'Point');
                    geojson.properties = {radius: entity.ellipse.semiMajorAxis.getValue()};
                    break;
                case NoFlyPolygon.DRAWTYPE:
                    if (isDeletePoint) {
                        // 删除点的话，去除之前旧的节点，创建新的实体节点
                        this.noFlyLayers[0].layer.removeNodeById(data.id);
                        const coordinates = data.content.coordinates;
                        for (let k = 0; k < coordinates[0].length - 1; k++) {
                            const coordinate = coordinates[0][k];
                            const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                            positions.push(position);
                        }
                        const newLayer = new NoFlyPolygon({
                            id: data.id,
                            name: data.name,
                            positions,
                            color: '#C600FF'
                        });
                        entity = newLayer.entity;
                        this.noFlyLayers[0].layer.addNode(newLayer);
                    } else {
                        entity.label.text._value = data.name;
                        if (changePoint) {
                            const coordinates = data.content.coordinates;
                            for (let k = 0; k < coordinates[0].length - 1; k++) {
                                const coordinate = coordinates[0][k];
                                const position = Cesium.Cartesian3.fromDegrees(...coordinate);
                                positions.push(position);
                            }
                            node.positions = positions;
                        }
                    }
                    geojson = this.getEntityGeojson(entity, 'Polygon');
                    break;
            }
            noFlyEntityData.content = geojson;
            this.setEditModel();
            this.selectNoFlyById(data.id);
            addEditNoFlyZone(noFlyEntityData).then(res => {
                done && done(res);
                if (res.data.code == 200 && !done) {
                    this.$EventBus.$emit('noFly-set-information', res.data.data || {});
                }
            }).catch(() => {
                done && done(false);
            });
        },
        // 通过地图的operation类改变和更新
        updateNoflyByOperation(node) {
            if (!node) return;
            let geojson;
            switch (node.type) {
                case NodeMap.Node.POINT:
                    geojson = this.getEntityGeojson(node.entity, 'Point');
                    geojson.properties = {radius: node.entity.ellipse.semiMajorAxis.getValue()};
                    break;
                case NodeMap.Node.POLYGON:
                    geojson = this.getEntityGeojson(node.entity, 'Polygon');
                break;
            }
            let data = JSON.parse(JSON.stringify(this.noFlyEntity));
            this.setEditModel();
            this.selectNoFlyById(node.id);
            data.content = geojson;
            if (this.noFlyEntity.id === node.id) {
                addEditNoFlyZone(data).then(res => {
                    if (res.data.code == 200) {
                        this.$EventBus.$emit('noFly-set-information', res.data.data || {});
                        // this.emitNoFlyListUpdate(this.noFlyEntity);
                    }
                });
            }
        },
        // 点选中禁飞区对象
        selectedNoflyNode(selectFeature) {
            const node = selectFeature.getRootNode();
            this.$EventBus.$emit('choose-noFly-data', node);
        },
        // 选择禁飞区对象
        selectNoFlyById(id) {
            if (this.$options.map3d.interactive._current.selectFeatureByEntity) {
                let entity;
                for (let i = 0; i < this.noFlyLayers.length; i++) {
                    const layer = this.noFlyLayers[i].layer;
                    entity = layer.getById(id);
                    if (entity) {
                        this.$options.map3d.interactive._current.selectFeatureByEntity(entity);
                        break;
                    }
                }
            }
        },
        // 设置禁飞区详情数据
        setNoFlyEntityData(data) {
            this.noFlyEntity = Object.assign({}, data);
        },
        // 关闭右侧属性面板
        closeNoFlyPanel() {
            let operation = this.$options.map3d.interactive._current
            if (operation && operation.selectFeature && operation.selectFeature.category === NodeMap.Node.NOFLYZONE) {
                this.$options.map3d.interactive._current.cancelSelectFeature();
            }
            this.noFlyEntity = {};
            this.$EventBus.$emit('clear-choose-noFly-data');
        },
        // 清空禁飞区层
        clearNoFlyLayers() {
            this.noFlyLayers[0].layer.clear();
        },
        // entity转geojson保存
        getNoFlyToSave(entity, type) {
            return new Promise((resolve, reject) => {
                let geojson, params;
                if (type === NodeMap.Node.POINT) {
                    geojson = this.getEntityGeojson(entity, 'Point');
                    geojson.properties = {radius: entity.ellipse.semiMajorAxis.getValue()};
                    params = {
                        type: "Circle",
                        flyZoneType: 5,
                        content: geojson
                    }
                } else if (type === NodeMap.Node.POLYGON) {
                    geojson = this.getEntityGeojson(entity, 'Polygon');
                    params = {
                        type: "Polygon",
                        flyZoneType: 5,
                        content: geojson
                    }
                }
                addEditNoFlyZone(params).then((res) => {
                    const code = res.data.code;
                    if (code === 200) {
                        resolve(res.data.data);
                        this.$message.success("操作成功");
                    }
                }).catch(() => {
                    reject();
                });
            });
        },
        getEntityGeojson(entity, type) {
            let geojson = labelEntity2geojson(this.$options.map3d, entity, type);
            if (geojson.geometry.type === 'Polygon') {
                let length = geojson.geometry.coordinates[0].length;
                let lastPoint = geojson.geometry.coordinates[0][length -1];
                let lastsPoint = geojson.geometry.coordinates[0][length -2];
                if (lastPoint[0] == lastsPoint[0] && lastPoint[1] == lastsPoint[1]) {
                    geojson.geometry.coordinates[0].splice(length - 1, 1);
                }
            }
            return geojson;
        }
    }
}
</script>