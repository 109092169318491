var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-box", style: { height: "100%" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.noFlyZone,
              expression: "noFlyZone",
            },
          ],
          staticClass: "warn-info",
        },
        [_vm._v("#航点处在禁飞区域，请注意。")]
      ),
      _c("div", { attrs: { id: "cesiumContainer" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSearch,
                expression: "showSearch",
              },
            ],
            attrs: { id: "tip" },
          },
          [
            _c("el-input", {
              attrs: {
                placeholder: "请输入查询地点",
                "prefix-icon": "el-icon-search",
                clearable: "",
                size: "small",
                id: "tipinput",
              },
              model: {
                value: _vm.searchInput,
                callback: function ($$v) {
                  _vm.searchInput = $$v
                },
                expression: "searchInput",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.handleInspection
        ? _c("div", { staticClass: "inspection-box" }, [
            _c("div", { staticClass: "inspection-title" }, [
              _vm._v("巡检对象"),
            ]),
            _c(
              "div",
              { staticClass: "inspection-btn", on: { click: _vm.drawOilWell } },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "油井",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.activeDraw === _vm.NodeMap.OilWellPoint.DRAWTYPE
                            ? "/img/map/oilWell-active.png"
                            : "/img/map/oil-well.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawOilPipeline },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "石油管线",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.activeDraw === _vm.NodeMap.OilPipeline.DRAWTYPE
                            ? "/img/map/oilLine-active.png"
                            : "/img/map/oilLine.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawStorageTank },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "储罐",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "24",
                        height: "24",
                        src:
                          _vm.activeDraw === _vm.NodeMap.StorageTank.DRAWTYPE
                            ? "/img/map/" +
                              _vm.NodeMap.StorageTank.DRAWTYPE +
                              "-active.png"
                            : "/img/map/" +
                              _vm.NodeMap.StorageTank.DRAWTYPE +
                              ".png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawMeasurementStation },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "计量站",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "24",
                        height: "24",
                        src:
                          _vm.activeDraw ===
                          _vm.NodeMap.MeasurementStation.DRAWTYPE
                            ? "/img/map/oilMeasurementStation-active.png"
                            : "/img/map/oilMeasurementStation.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawStationStorage },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "油气站库",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "24",
                        height: "24",
                        src:
                          _vm.activeDraw === _vm.NodeMap.StationStorage.DRAWTYPE
                            ? "/img/map/oilStationStorage-active.png"
                            : "/img/map/oilStationStorage.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inspection-btn", on: { click: _vm.drawOilRoad } },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "油田公路",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.activeDraw === _vm.NodeMap.OilRoad.DRAWTYPE
                            ? "/img/map/oilRoad-active.png"
                            : "/img/map/oilRoad.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawPowerLine },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "电力线路",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.activeDraw === _vm.NodeMap.PowerLine.DRAWTYPE
                            ? "/img/map/oilPowerLine-active.png"
                            : "/img/map/oilPowerLine.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawPowerEquipment },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "电力设施",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "24",
                        height: "24",
                        src:
                          _vm.activeDraw === _vm.NodeMap.PowerEquipment.DRAWTYPE
                            ? "/img/map/oilPowerEquipment-active.png"
                            : "/img/map/oilPowerEquipment.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "inspection-btn",
                on: { click: _vm.drawOilGasWell },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "石油气井",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "24",
                        height: "24",
                        src:
                          _vm.activeDraw === _vm.NodeMap.GasWell.DRAWTYPE
                            ? "/img/map/oilGasWell-active.png"
                            : "/img/map/oilGasWell.png",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.handleMark
        ? _c(
            "div",
            {
              staticClass: "mark-label-box",
              class:
                _vm.showInspectionPanel || _vm.showMarkPanel
                  ? "open-oilInspection-panel"
                  : "",
            },
            [
              _c(
                "div",
                {
                  staticClass: "mark-label-btn",
                  style:
                    "background-color: " +
                    _vm.markPointColor +
                    ";border-radius: 4px 4px 0px 0px",
                  on: { click: _vm.drawMarkPoint },
                },
                [
                  _vm.activeDraw == _vm.NodeMap.MarkPoint.DRAWTYPE
                    ? _c("div", { staticClass: "mark-label-triangle" })
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "left" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        _vm._l(_vm.markColorList, function (item) {
                          return _c(
                            "span",
                            {
                              key: item,
                              style:
                                "\n              background-color: " +
                                item +
                                ";\n              width: 12px;\n              height: 12px;\n              cursor: pointer;\n              margin-left: 8px;\n              display: inline-block;",
                              on: {
                                click: function ($event) {
                                  return _vm.changeColor(
                                    _vm.NodeMap.Node.POINT,
                                    item
                                  )
                                },
                              },
                            },
                            [
                              _vm.markPointColor === item
                                ? _c("img", {
                                    attrs: {
                                      width: "12px",
                                      height: "12px",
                                      src: "/img/map/gou.png",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("img", {
                        attrs: {
                          width: "100%",
                          height: "100%",
                          src: "/img/map/hollowing_out_point.png",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "mark-label-btn",
                  style: "background-color: " + _vm.markPolylineColor,
                  on: { click: _vm.drawMarkline },
                },
                [
                  _vm.activeDraw === _vm.NodeMap.MarkLine.DRAWTYPE
                    ? _c("div", { staticClass: "mark-label-triangle" })
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "left" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        _vm._l(_vm.markColorList, function (item) {
                          return _c(
                            "span",
                            {
                              key: item,
                              style:
                                "\n              background-color: " +
                                item +
                                ";\n              cursor: pointer;\n              width: 12px;\n              height: 12px;\n              margin-left: 8px;\n              display: inline-block;",
                              on: {
                                click: function ($event) {
                                  return _vm.changeColor(
                                    _vm.NodeMap.Node.LINE,
                                    item
                                  )
                                },
                              },
                            },
                            [
                              _vm.markPolylineColor === item
                                ? _c("img", {
                                    attrs: {
                                      width: "12px",
                                      height: "12px",
                                      src: "/img/map/gou.png",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("img", {
                        attrs: {
                          width: "100%",
                          height: "100%",
                          src: "/img/map/hollowing_out_polyline.png",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "mark-label-btn",
                  style:
                    "background-color: " +
                    _vm.markPolygonColor +
                    "; border-radius: 0px 0px 4px 4px",
                  on: { click: _vm.drawMarkPolygon },
                },
                [
                  _vm.activeDraw === _vm.NodeMap.MarkPolygon.DRAWTYPE
                    ? _c("div", { staticClass: "mark-label-triangle" })
                    : _vm._e(),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "left" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        _vm._l(_vm.markColorList, function (item) {
                          return _c(
                            "span",
                            {
                              key: item,
                              style:
                                "\n              background-color: " +
                                item +
                                ";\n              width: 12px;\n              cursor: pointer;\n              height: 12px;\n              margin-left: 8px;\n              display: inline-block;",
                              on: {
                                click: function ($event) {
                                  return _vm.changeColor(
                                    _vm.NodeMap.Node.POLYGON,
                                    item
                                  )
                                },
                              },
                            },
                            [
                              _vm.markPolygonColor === item
                                ? _c("img", {
                                    attrs: {
                                      width: "12px",
                                      height: "12px",
                                      src: "/img/map/gou.png",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("img", {
                        attrs: {
                          width: "100%",
                          height: "100%",
                          src: "/img/map/hollowing_out_polygon.png",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.handleAirport
        ? _c("div", { staticClass: "airport-box" }, [
            _c(
              "div",
              {
                staticClass: "draw-options_item",
                attrs: { title: "点机场" },
                on: { click: _vm.drawAirportpoint },
              },
              [
                _vm.activeDraw === _vm.NodeMap.AirportPoint.DRAWTYPE
                  ? _c("div", { staticClass: "active" })
                  : _vm._e(),
                _c("img", {
                  attrs: { src: "/img/map/hollowing_out_point.png", alt: "" },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "draw-options_item",
                attrs: { title: "面机场" },
                on: { click: _vm.drawAirportPolygon },
              },
              [
                _vm.activeDraw === _vm.NodeMap.AirportPolygon.DRAWTYPE
                  ? _c("div", { staticClass: "active" })
                  : _vm._e(),
                _c("img", {
                  attrs: { src: "/img/map/hollowing_out_polygon.png", alt: "" },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.siderContentShow,
              expression: "siderContentShow",
            },
          ],
          staticClass: "sider-content",
        },
        [
          _c("div", { staticClass: "content-header" }, [
            _c("span", { staticClass: "content-title" }, [_vm._v("视频直播")]),
            _c(
              "span",
              { staticClass: "close", on: { click: _vm.closeSiderContent } },
              [_c("img", { attrs: { src: "/img/map/close.png" } })]
            ),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "video-container" },
              [
                _c(_vm.useLive, {
                  ref: "videoLive",
                  tag: "component",
                  attrs: {
                    showclose: false,
                    screen: false,
                    liveVideoVOList: _vm.liveVideoVOList,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "information" }, [
              _c("div", { staticClass: "information-box" }, [
                _c("div", { staticClass: "information-title" }, [
                  _c("span", { staticClass: "title-decoration" }),
                  _c("span", [_vm._v(_vm._s(_vm.currentUavData.airPlaneName))]),
                ]),
                _c("div", { staticClass: "information-content" }, [
                  _vm.$route.matched[0].path === "/emergency"
                    ? _c("div", [
                        _c("span", { staticClass: "information-name" }, [
                          _vm._v("序列号:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.currentUavData.airPlaneSn
                                  ? _vm.currentUavData.airPlaneSn
                                  : "--"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.$route.matched[0].path !== "/emergency"
                    ? _c("div", [
                        _c("span", { staticClass: "information-name" }, [
                          _vm._v("项目名称: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.currentUavData.projectName
                                ? _vm.currentUavData.projectName
                                : "--"
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c("span", { staticClass: "information-name" }, [
                      _vm._v("任务名称: "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.currentUavData.taskName
                            ? _vm.currentUavData.taskName
                            : "--"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "information-name" }, [
                      _vm._v("经度: "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.currentUavData.longitude
                            ? _vm.currentUavData.longitude.toFixed(7)
                            : "--"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "information-name" }, [
                      _vm._v("纬度: "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.currentUavData.latitude
                            ? _vm.currentUavData.latitude.toFixed(7)
                            : "--"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "information-name" }, [
                      _vm._v("场高: "),
                    ]),
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.currentUavData.fieldHeight
                              ? _vm.currentUavData.fieldHeight.toFixed(1)
                              : "0"
                          ) +
                          "m\n              "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "information-name" }, [
                      _vm._v("飞行速度: "),
                    ]),
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.currentUavData.gs
                              ? _vm.currentUavData.gs.toFixed(2)
                              : "0"
                          ) +
                          " m/s\n              "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "information-name" }, [
                      _vm._v("当前电量: "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.currentUavData.pwrLeft
                            ? _vm.currentUavData.pwrLeft + " %"
                            : "0 %"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          class:
            _vm.showInspectionPanel || _vm.showMarkPanel
              ? "open-oilInspection-panel"
              : "",
          attrs: { id: "baseController" },
        },
        [
          _vm.handleNoFlyZone
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    "popper-class": "nofly-tooltip",
                    effect: "dark",
                    placement: "left-start",
                    "visible-arrow": false,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "nofly-tooltip-main",
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tooltip-main_top" },
                        [
                          _c("el-switch", {
                            attrs: {
                              size: "",
                              "active-text": "显示禁飞区",
                              "inactive-text": "",
                            },
                            on: { change: _vm.changenNoflySwitch },
                            model: {
                              value: _vm.noflySwitchData,
                              callback: function ($$v) {
                                _vm.noflySwitchData = $$v
                              },
                              expression: "noflySwitchData",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "tooltip-main_bottom" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.noflyCheckListData,
                                callback: function ($$v) {
                                  _vm.noflyCheckListData = $$v
                                },
                                expression: "noflyCheckListData",
                              },
                            },
                            _vm._l(_vm.noflyTypeValueData, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.label,
                                  attrs: {
                                    border: "",
                                    size: "small",
                                    disabled: item.disabled,
                                    label: item.label,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeNoflyBox(item.label)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/project/" + item.img + ".png",
                                      alt: "",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t(item.name))),
                                  ]),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "no-fly" }, [
                    _c("img", {
                      attrs: { src: "/img/map/noFly.svg", alt: "" },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _c("img", {
            style: "transform: rotate(" + _vm.degrees + "deg)",
            attrs: {
              id: "navigationHelpButtonContainer",
              src: "/img/map/compass.png",
            },
            on: { click: _vm.setCompass },
          }),
          _c("div", { staticClass: "other" }, [
            _c(
              "div",
              {
                staticClass: "btn zoomBtn bottomBorder",
                staticStyle: {
                  "border-top-right-radius": "4px",
                  "border-top-left-radius": "4px",
                },
                on: {
                  click: function ($event) {
                    return _vm.zoom(1)
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/map/放大.png" } })]
            ),
            _c(
              "div",
              {
                staticClass: "btn zoomBtn",
                staticStyle: {
                  "border-bottom-right-radius": "4px",
                  "border-bottom-left-radius": "4px",
                },
                on: {
                  click: function ($event) {
                    return _vm.zoom(-1)
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/map/缩小.png" } })]
            ),
            _c(
              "div",
              {
                attrs: { title: "" + (_vm.isVec ? "卫星图" : "矢量图") },
                on: { click: _vm.switchLayerType },
              },
              [
                _c("img", {
                  staticClass: "layerSwitch",
                  attrs: {
                    src: "/img/map/" + (_vm.isVec ? "3D.png" : "2D.png"),
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("span", { style: { width: "100px", marginRight: "10px" } }, [
          _vm._v("\n      " + _vm._s(_vm.mousePosition[0]) + "\n    "),
        ]),
        _c("span", { style: { width: "80px", marginRight: "10px" } }, [
          _vm._v("\n      " + _vm._s(_vm.mousePosition[1]) + "\n    "),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "scaler",
          class:
            _vm.showInspectionPanel || _vm.showMarkPanel
              ? "open-oilInspection-panel"
              : "",
        },
        [
          _c("div", {
            staticClass: "scaler-text",
            domProps: {
              textContent: _vm._s(
                _vm.scale < 1000 ? _vm.scale + "m" : _vm.scale / 1000 + "km"
              ),
            },
          }),
          _c("div", { staticClass: "scaler-ui" }),
        ]
      ),
      _c("oilInspection-panel", {
        attrs: {
          canEdit: _vm.handleInspection,
          pathList: _vm.inspectionPathList,
          entity: _vm.currentEntity,
          visible: _vm.showInspectionPanel,
        },
        on: {
          close: _vm.closeInspectionPanel,
          addPoint: _vm.addEntityOnLine,
          removePoint: _vm.removeEntityOnLine,
          updateEntity: _vm.updateInspection,
          "update:visible": function ($event) {
            _vm.showInspectionPanel = $event
          },
        },
      }),
      _c("mark-panel", {
        attrs: {
          canEdit: _vm.handleMark,
          pathList: _vm.markPathList,
          entity: _vm.markEntity,
          colorList: _vm.markColorList,
          showHandlePath: _vm.handleMarkPath,
          visible: _vm.showMarkPanel,
        },
        on: {
          close: _vm.closeMarkPanel,
          addPoint: _vm.addMarkLinePoint,
          removePoint: _vm.removeMarkLinePoint,
          updateEntity: _vm.updateMark,
          "update:visible": function ($event) {
            _vm.showMarkPanel = $event
          },
        },
      }),
      _c("airport-panel", {
        attrs: {
          informationData: _vm.airportEntity,
          visible: _vm.showAirportPanel,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAirportPanel = $event
          },
          closeAirportPanel: _vm.closeAirportPanel,
          deleteAirportData: _vm.deleteAirport,
          addPoint: _vm.addAirportEntityPoint,
          changeAirportValue: _vm.updateAirport,
          changeAirportEquipment: _vm.updateAirportEquipment,
        },
      }),
      !_vm.drawFinish
        ? _c(
            "div",
            {
              staticClass: "mouse-tip",
              style: {
                position: "absolute",
                top: _vm.tips.y + "px",
                left: _vm.tips.x + 30 + "px",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.tips.tip) + "\n  ")]
          )
        : _vm._e(),
      _vm.showWarningDetail && _vm.selectWarning.show
        ? _c("WarningDetail", {
            attrs: {
              top: 10,
              right: 60,
              isAI: _vm.isAI,
              selectWarning: _vm.selectWarning,
            },
            on: { close: _vm.closeWarningDetail, updataList: _vm.updataList },
          })
        : _vm._e(),
      _vm.hoverTip.show
        ? _c(
            "div",
            {
              staticClass: "hover-tip",
              style:
                "\n      left: " +
                _vm.hoverTip.left +
                "px;\n      top: " +
                _vm.hoverTip.top +
                "px;\n      transform: translateY(calc(-100% - " +
                (_vm.hoverTip.type === _vm.NodeMap.Node.POINT ? 40 : 18) +
                "px)) translateX(-50%);\n    ",
            },
            [
              _c("div", { staticClass: "hover-tip-triangle" }),
              _vm._l(_vm.hoverTip.list, function (item) {
                return _c(
                  "div",
                  { key: item.label, staticClass: "hover-tip-item" },
                  [
                    _c("span", [_vm._v(_vm._s(item.label) + ": ")]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.handleBaseStation
        ? _c("BaseStationPop", {
            ref: "BaseStationPop",
            attrs: { detailData: _vm.baseStationDetail },
          })
        : _vm._e(),
      _vm.hangarShow
        ? _c("HangarDetails", {
            ref: "hangarDetails",
            attrs: {
              show: _vm.hangarShow,
              baseData: _vm.hangarBaseData,
              detailsData: _vm.hangarDetailsData,
              videoData: _vm.hangarVideoData,
            },
            on: {
              "update:show": function ($event) {
                _vm.hangarShow = $event
              },
              closeDetails: _vm.closeHangarPanel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }