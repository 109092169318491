<template>
  <div class="map-box" :style="{ height: '100%' }">
    <div class="warn-info" v-show="noFlyZone">#航点处在禁飞区域，请注意。</div>
    <!-- cesium容器 -->
    <div
      id="cesiumContainer">
      <div id="tip" v-show="showSearch">
        <el-input
          placeholder="请输入查询地点"
          prefix-icon="el-icon-search"
          clearable
          v-model="searchInput"
          size="small"
          id="tipinput"
        >
        </el-input>
      </div>
    </div>
    <!-- 巡检工具 -->
    <div
      v-if="handleInspection"
      class="inspection-box">
      <div class="inspection-title">巡检对象</div>
      <div class="inspection-btn" @click="drawOilWell">
        <el-tooltip
          class="item"
          effect="dark"
          content="油井"
          placement="bottom"
        >
          <img
            :src="
              activeDraw === NodeMap.OilWellPoint.DRAWTYPE
                ? '/img/map/oilWell-active.png'
                : '/img/map/oil-well.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawOilPipeline">
        <el-tooltip
          class="item"
          effect="dark"
          content="石油管线"
          placement="bottom"
        >
          <img
            :src="
              activeDraw === NodeMap.OilPipeline.DRAWTYPE
                ? '/img/map/oilLine-active.png'
                : '/img/map/oilLine.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawStorageTank">
        <el-tooltip
          class="item"
          effect="dark"
          content="储罐"
          placement="bottom"
        >
          <img
            width="24"
            height="24"
            :src="
              activeDraw === NodeMap.StorageTank.DRAWTYPE
                ? `/img/map/${NodeMap.StorageTank.DRAWTYPE}-active.png`
                : `/img/map/${NodeMap.StorageTank.DRAWTYPE}.png`
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawMeasurementStation">
        <el-tooltip
          class="item"
          effect="dark"
          content="计量站"
          placement="bottom"
        >
          <img
            width="24"
            height="24"
            :src="
              activeDraw === NodeMap.MeasurementStation.DRAWTYPE
                ? '/img/map/oilMeasurementStation-active.png'
                : '/img/map/oilMeasurementStation.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawStationStorage">
        <el-tooltip
          class="item"
          effect="dark"
          content="油气站库"
          placement="bottom"
        >
          <img
            width="24"
            height="24"
            :src="
              activeDraw === NodeMap.StationStorage.DRAWTYPE
                ? '/img/map/oilStationStorage-active.png'
                : '/img/map/oilStationStorage.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawOilRoad">
        <el-tooltip
          class="item"
          effect="dark"
          content="油田公路"
          placement="bottom"
        >
          <img
            :src="
              activeDraw === NodeMap.OilRoad.DRAWTYPE
                ? '/img/map/oilRoad-active.png'
                : '/img/map/oilRoad.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawPowerLine">
        <el-tooltip
          class="item"
          effect="dark"
          content="电力线路"
          placement="bottom"
        >
          <img
            :src="
              activeDraw === NodeMap.PowerLine.DRAWTYPE
                ? '/img/map/oilPowerLine-active.png'
                : '/img/map/oilPowerLine.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawPowerEquipment">
        <el-tooltip
          class="item"
          effect="dark"
          content="电力设施"
          placement="bottom"
        >
          <img
            width="24"
            height="24"
            :src="
              activeDraw === NodeMap.PowerEquipment.DRAWTYPE
                ? '/img/map/oilPowerEquipment-active.png'
                : '/img/map/oilPowerEquipment.png'
            "
          />
        </el-tooltip>
      </div>
      <div class="inspection-btn" @click="drawOilGasWell">
        <el-tooltip
          class="item"
          effect="dark"
          content="石油气井"
          placement="bottom"
        >
          <img
            width="24"
            height="24"
            :src="
              activeDraw === NodeMap.GasWell.DRAWTYPE
                ? '/img/map/oilGasWell-active.png'
                : '/img/map/oilGasWell.png'
            "
          />
        </el-tooltip>
      </div>
    </div>
    <!-- 标注工具 -->
    <div
      v-if="handleMark"
      class="mark-label-box"
      :class="showInspectionPanel || showMarkPanel? 'open-oilInspection-panel' : ''">
      <div
        :style="`background-color: ${markPointColor};border-radius: 4px 4px 0px 0px`"
        class="mark-label-btn"
        @click="drawMarkPoint">
        <div v-if="activeDraw==NodeMap.MarkPoint.DRAWTYPE" class="mark-label-triangle"></div>
        <el-tooltip
          class="item"
          effect="dark"
          placement="left"
        >
          <div slot="content">
            <span
              v-for="item in markColorList"
              :key="item"
              @click="changeColor(NodeMap.Node.POINT, item)"
              :style="`
                background-color: ${item};
                width: 12px;
                height: 12px;
                cursor: pointer;
                margin-left: 8px;
                display: inline-block;`">
                <img v-if="markPointColor===item"  width="12px" height="12px" src="/img/map/gou.png" alt="">
            </span>
          </div>
          <img
            width="100%"
            height="100%"
            src="/img/map/hollowing_out_point.png"
          />
        </el-tooltip>
      </div>
      <div
        :style="`background-color: ${markPolylineColor}`"
        class="mark-label-btn"
        @click="drawMarkline">
        <div v-if="activeDraw===NodeMap.MarkLine.DRAWTYPE" class="mark-label-triangle"></div>
        <el-tooltip
          class="item"
          effect="dark"
          placement="left"
        >
          <div slot="content">
            <span
              v-for="item in markColorList"
              :key="item"
              @click="changeColor(NodeMap.Node.LINE, item)"
              :style="`
                background-color: ${item};
                cursor: pointer;
                width: 12px;
                height: 12px;
                margin-left: 8px;
                display: inline-block;`">
                <img v-if="markPolylineColor===item"  width="12px" height="12px" src="/img/map/gou.png" alt="">
            </span>
          </div> 
          <img width="100%" height="100%" src="/img/map/hollowing_out_polyline.png"/>
        </el-tooltip>
      </div>
      <div
        @click="drawMarkPolygon"
        :style="`background-color: ${markPolygonColor}; border-radius: 0px 0px 4px 4px`"
        class="mark-label-btn">
        <div v-if="activeDraw===NodeMap.MarkPolygon.DRAWTYPE" class="mark-label-triangle"></div>
        <el-tooltip
          class="item"
          effect="dark"
          placement="left"
        >
          <div slot="content">
            <span
              v-for="item in markColorList"
              @click="changeColor(NodeMap.Node.POLYGON, item)"
              :key="item"
              :style="`
                background-color: ${item};
                width: 12px;
                cursor: pointer;
                height: 12px;
                margin-left: 8px;
                display: inline-block;`">
                <img v-if="markPolygonColor===item"  width="12px" height="12px" src="/img/map/gou.png" alt="">
            </span>
          </div>
          <img  width="100%" height="100%" src="/img/map/hollowing_out_polygon.png"/>
        </el-tooltip>
      </div>
    </div>
    <!-- 机场绘制 -->
    <div class="airport-box" v-if="handleAirport">
      <div class="draw-options_item" title="点机场" @click="drawAirportpoint">
        <div class="active" v-if="activeDraw === NodeMap.AirportPoint.DRAWTYPE"></div>
        <img src="/img/map/hollowing_out_point.png" alt="">
      </div>
      <div class="draw-options_item" title="面机场" @click="drawAirportPolygon">
        <div class="active" v-if="activeDraw === NodeMap.AirportPolygon.DRAWTYPE"></div>
        <img src="/img/map/hollowing_out_polygon.png" alt="">
      </div>
    </div>
    <!-- 视频容器 -->
    <div
      class="sider-content"
      v-show="siderContentShow">
      <div class="content-header">
        <span class="content-title">视频直播</span>
        <span class="close" @click="closeSiderContent">
          <img src="/img/map/close.png" />
        </span>
      </div>
      <div class="content">
        <div class="video-container">
          <component
            :is="useLive"
            ref="videoLive"
            :showclose="false"
            :screen="false"
            :liveVideoVOList="liveVideoVOList"
          ></component>
        </div>
        <div class="information">
          <div class="information-box">
            <div class="information-title">
              <span class="title-decoration"></span>
              <span>{{ currentUavData.airPlaneName }}</span>
            </div>
            <div class="information-content">
              <div v-if="$route.matched[0].path === '/emergency'">
                <span class="information-name">序列号:</span>
                <span>
                  {{currentUavData.airPlaneSn ? currentUavData.airPlaneSn : "--"}}
                </span>
              </div>
              <div v-if="$route.matched[0].path !== '/emergency'">
                <span class="information-name">项目名称: </span>
                <span>{{
                  currentUavData.projectName ? currentUavData.projectName : "--"
                }}</span>
              </div>
              <div>
                <span class="information-name">任务名称: </span>
                <span>{{
                  currentUavData.taskName ? currentUavData.taskName : "--"
                }}</span>
              </div>
              <div>
                <span class="information-name">经度: </span>
                <span>{{
                  currentUavData.longitude
                    ? currentUavData.longitude.toFixed(7)
                    : "--"
                }}</span>
              </div>
              <div>
                <span class="information-name">纬度: </span>
                <span>{{
                  currentUavData.latitude
                    ? currentUavData.latitude.toFixed(7)
                    : "--"
                }}</span>
              </div>
              <div>
                <span class="information-name">场高: </span>
                <span>
                  {{ currentUavData.fieldHeight? currentUavData.fieldHeight.toFixed(1): "0"}}m
                </span>
              </div>
              <div>
                <span class="information-name">飞行速度: </span>
                <span>
                  {{ currentUavData.gs ? currentUavData.gs.toFixed(2) : "0" }} m/s
                </span>
              </div>
              <div>
                <span class="information-name">当前电量: </span>
                <span>{{
                  currentUavData.pwrLeft ? currentUavData.pwrLeft + " %" : "0 %"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右下工具栏 -->
    <div
      id="baseController"
      :class="showInspectionPanel || showMarkPanel? 'open-oilInspection-panel' : ''">
      <el-tooltip class="item" popper-class="nofly-tooltip" effect="dark" placement="left-start" 
        :visible-arrow="false" v-if="handleNoFlyZone">
        <div slot="content" class="nofly-tooltip-main">
          <div class="tooltip-main_top">
            <el-switch v-model="noflySwitchData" size active-text="显示禁飞区" 
              inactive-text="" @change="changenNoflySwitch" />
          </div>
          <div class="tooltip-main_bottom">
            <el-checkbox-group v-model="noflyCheckListData">
              <el-checkbox border size="small" v-for="item in noflyTypeValueData" :key="item.label" 
                :disabled="item.disabled" :label="item.label" @change="changeNoflyBox(item.label)">
                <img :src="`/img/project/${item.img}.png`" alt="">
                <span>{{$t(item.name)}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="no-fly">
          <img src="/img/map/noFly.svg" alt="">
        </div>
      </el-tooltip>
      <img
        id="navigationHelpButtonContainer"
        src="/img/map/compass.png"
        :style="'transform: rotate(' + degrees + 'deg)'"
        @click="setCompass"
      />
      <div class="other">
        <!-- 暂时隐藏 -->
        <!-- <div
          class="btn zoomBtn model-changer flexCenter"
          @click="changeViewModel"
        >
          {{ viewModel === 3 ? "2D" : "3D" }}
        </div> -->
        <div
          class="btn zoomBtn bottomBorder"
          style="border-top-right-radius: 4px; border-top-left-radius: 4px"
          @click="zoom(1)"
        >
          <img src="/img/map/放大.png" />
        </div>
        <div
          class="btn zoomBtn"
          style="
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          "
          @click="zoom(-1)"
        >
          <img src="/img/map/缩小.png" />
        </div>

        <div @click="switchLayerType" :title="`${isVec ? '卫星图' : '矢量图'}`">
          <img
            class="layerSwitch"
            :src="`/img/map/${isVec ? '3D.png' : '2D.png'}`"
          />
        </div>
      </div>
    </div>
    <!-- 鼠标坐标位置 -->
    <div 
      class="footer">
      <span :style="{ width: '100px', marginRight: '10px' }">
        {{ mousePosition[0] }}
      </span>
      <span :style="{ width: '80px', marginRight: '10px' }">
        {{ mousePosition[1] }}
      </span>
    </div>
    <!-- 比例尺 -->
    <div
      class="scaler"
      :class="showInspectionPanel || showMarkPanel? 'open-oilInspection-panel' : ''"
    >
      <div
        class="scaler-text"
        v-text="scale < 1000 ? `${scale}m` : `${scale / 1000}km`">
      </div>
      <div class="scaler-ui"></div>
    </div>
    <!-- 巡检对象右侧属性面板 -->
    <oilInspection-panel
      :canEdit="handleInspection"
      :pathList="inspectionPathList"
      :entity="currentEntity"
      @close="closeInspectionPanel"
      @addPoint="addEntityOnLine"
      @removePoint="removeEntityOnLine"
      @updateEntity="updateInspection"
      :visible.sync="showInspectionPanel"
    >
    </oilInspection-panel>
    <!-- 标注对象右侧属性面板 -->
    <mark-panel
      :canEdit="handleMark"
      :pathList="markPathList"
      :entity="markEntity"
      :colorList="markColorList"
      :showHandlePath="handleMarkPath"
      @close="closeMarkPanel"
      @addPoint="addMarkLinePoint"
      @removePoint="removeMarkLinePoint"
      @updateEntity="updateMark"
      :visible.sync="showMarkPanel"
    >
    </mark-panel>
    <!-- 机场对象右侧属性面板 -->
    <airport-panel
      :informationData="airportEntity"
      :visible.sync="showAirportPanel"
      @closeAirportPanel="closeAirportPanel"
      @deleteAirportData="deleteAirport"
      @addPoint="addAirportEntityPoint"
      @changeAirportValue="updateAirport"
      @changeAirportEquipment="updateAirportEquipment"
    ></airport-panel>
    <!-- 正在绘制提示 -->
    <div
      v-if="!drawFinish"
      class="mouse-tip"
      :style="{
        position: 'absolute',
        top: tips.y + 'px',
        left: tips.x + 30 + 'px',
      }"
    >
      {{ tips.tip }}
    </div>
    <!-- 报警弹窗 -->
    <WarningDetail
      :top="10"
      :right="60"
      :isAI="isAI"
      :selectWarning="selectWarning"
      v-if="showWarningDetail && selectWarning.show"
      @close="closeWarningDetail"
      @updataList="updataList"
    />
    <!-- 悬浮窗 -->
    <div
      v-if="hoverTip.show"
      :style="`
        left: ${hoverTip.left}px;
        top: ${hoverTip.top}px;
        transform: translateY(calc(-100% - ${
          hoverTip.type === NodeMap.Node.POINT ? 40 : 18
        }px)) translateX(-50%);
      `"
      class="hover-tip"
    >
      <div class="hover-tip-triangle"></div>
      <div
        class="hover-tip-item"
        v-for="item in hoverTip.list"
        :key="item.label"
      >
        <span>{{ item.label }}: </span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <!-- 基站悬浮窗 -->
    <BaseStationPop v-if="handleBaseStation" ref="BaseStationPop" :detailData="baseStationDetail" />
    <!-- 机库详情弹窗 -->
    <HangarDetails v-if="hangarShow" ref="hangarDetails" :show.sync="hangarShow" :baseData="hangarBaseData" 
      :detailsData="hangarDetailsData" :videoData="hangarVideoData" @closeDetails="closeHangarPanel" />
  </div>
</template>

<script>
import {addEvent, removeEvent} from "@/util/util.js"
import Map3d from "./js/Map3d";
import BaseLayer from "./js/BaseLayer";
import { 
  getLabelTreeList,
  getMapdirList,
  getInspectionObjects,
  getMarkObjects
} from "@/api/project/label";
import NodeMap from "./js/node/index";
import oilInspectionVue from "./mixins/oilInspection.vue";
import markVue from "./mixins/mark.vue";
import noFlyZoneVue from "./mixins/noFlyZone.vue";
import noFlyZoneShowVue from "./mixins/noFlyZoneShow.vue";
import airportVue from "./mixins/airport.vue";
import airportShowVue from "./mixins/airportShow.vue";
import basestationShowVue from "./mixins/basestationShow.vue";
import hangarVue from "./mixins/hangar.vue";
import hangarShowVue from "./mixins/hangarShow.vue";
import warningVue from "./mixins/warning.vue";
import greenLandProvider from "./mixins/greenLandProvider.vue";
import forbidden from "./mixins/forbidden.vue";
import routeVue from "./mixins/route.vue";
import uavVue from "./mixins/uav.vue";
import projectVue from "./mixins/project.vue";
import VdieoLiveEasyplayer from "../video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "../video-live-HWLLS/index.vue";
import {
   getProjectDetail,
   getFGProjectDetail,
   getOGProjectDetail,
   getETProjectDetail,
} from "@/api/map/index";
import { getDetail } from '@/api/phugiacloud/projectTask'
import { mapGetters, mapMutations } from "vuex";
import EditOperation from './js/interactive/operation/EditOperation';
import NormalOperation from './js/interactive/operation/NormalOperation';

export default {
  name: "CesiumIndex",
  /*
   * 目前使用混入有两个目的分离组件（greenLandProvider）
   * 防止index代码冗余复用代码逻辑（oilInspectionVue,warningVue）
   * todo 项目管理以后还更规范需要规范, 每个混入的vue确保它低耦合性
   **/
  mixins: [
    oilInspectionVue,
    markVue,
    noFlyZoneVue,
    // 禁飞区纯展示混入
    noFlyZoneShowVue,
    airportVue,
    // 机场纯展示混入
    airportShowVue,
    // 基站纯展示混入
    basestationShowVue,
    hangarVue,
    // 机库展示图层，需配合hangarVue混入文件结合使用。不能单独使用
    hangarShowVue,
    greenLandProvider,
    warningVue,
    forbidden,
    uavVue,
    projectVue,
    routeVue
  ],
  components: { VdieoLiveEasyplayer, VdieoLiveHWLLS },
  props: {
    // 是否开启3d加载高层
    is3D: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isComponentActive: true, // 当前组件是否处于活跃状态
      // 所有封装节点的map，类型判断，创建对应node等
      NodeMap,
      /**
       *  modifyType随着业务代码会重构，这种方式往后走不便于维护了
       *  0 项目列表 
       *  1 项目详情  
       *  2 标注
       *  3 巡检管理 
       *  4 ？
       *  5 大屏 
       *  6 报警 
       *  7 巡检记录
       *  8 禁飞区
       *  9 临时禁飞区
       *  10 限高禁飞区
       *  11 缓冲区禁飞区
       *  12 自定义禁飞区
       *  13 机场管理
       *  14 机库管理
       *  15 航线管理
       *  16 AI识别报警
       * */
      modifyType: this.getModifyType(),
      // 指南针
      degrees: 0,
      // 2d、3d 模式
      viewModel: Cesium.SceneMode.SCENE3D,
      mousePosition: "",
      scale: null,
      showSearch: false,
      searchInput: null,
      // 航线
      kmlLayer: null,
      // 项目相关
      projectLayer: null,
      // 巡检图层
      inspectionLayers: [],
      // 标注图层
      markLayers: [],
      // 禁飞区绘制图层
      noFlyLayers: [],
      // 机场绘制图层
      airportLayers: [],
      // shp文件图层
      shpLayer: null,
      currentLabelLayer: 0,
      homePointTemp: null,
      // 结束绘制
      drawFinish: true,
      // 视频播放
      siderContentShow: false,
      tips: {
        x: null,
        y: null,
        tip: "双击结束",
      },
      activeDraw: "",
      pointCount: 0,
      lineCount: 0,
      polygonCount: 0,
      selectEntityID: "",
      isVec: false,
      _selectFeature: null,
      mapLabels: [],
      uavData: {},
      cards: [],
      groundStationType: "ASGCSS",
      noFixed: ["uav_heading", "lon", "lat", "target_waypoint_current"],
      // 巡检对象的路径
      inspectionPathList: [],
      markPathList: [],
      // 使用那种播放器:华为云or青犀
      useLive: "",
      // 多视频源list
      liveVideoVOList: [],
      // 当前标注点所选颜色
      markPointColor: '#0096FF',
      // 当前标注线所选颜色
      markPolylineColor: '#0096FF',
      // 当前标注面所选颜色
      markPolygonColor: '#0096FF',
      // 记录当前地图对应的一级菜单，
      firstPath: this.$route.matched[0].path,
    };
  },
  computed: {
    ...mapGetters([
      // 标注可选颜色集合
      "markColorList",
      // 权限中心
      "permission",
      // 飞行中心数据
      "map3d_project",
      // 林草中心
      "map3d_FG",
      // 油气中心
      "map3d_OG",
      // 应急中心
      "map3d_ET",
      // 左侧列表选中的项目item
      "selectProject",
      // 外部组件对该组件操作指令
      "handlerType",
      // 报警列表
      "warningList"
    ]),
    projectList() {
      switch (this.firstPath) {
        case '/mission-center':
          return this.map3d_project.list
        case '/forest-grass':
          return this.map3d_FG.list
        case '/oil-gas':
          return this.map3d_OG.list
        case '/emergency':
          return this.map3d_ET.list
        default:
          return this.map3d_project.list
      }
    },
    projectDetail() {
      switch (this.firstPath) {
        case '/mission-center':
          return this.map3d_project.detail
        case '/forest-grass':
          return this.map3d_FG.detail
        case '/oil-gas':
          return this.map3d_OG.detail
        case '/emergency':
          return this.map3d_ET.detail
        default:
          return this.map3d_project.detail
      }
    },
    handleInspection() {
      return this.modifyType === 3;
    },
    handleMark() {
      /***
       * 判断条件说明:
       * A、必须是需要的菜单
       *    1、飞行中心--飞行项目
       *      1_1、编辑权限
       *    2、应急--应急任务
       *    3、林草巡检--巡检项目
       *      3_1、编辑权限
       * B、this.modifyType !== 0 
       * 
       * **/
      const firstPath = this.$route.matched[0].path
      const secondPath = this.$route.matched[1]? this.$route.matched[1].path: ''

      // 条件A1
      let ConditionA1 = firstPath === '/mission-center' && secondPath === '/mission-center/item-manager-config'
      // 条件A1_1
      let ConditionA1_1 = ConditionA1 && !!this.permission.mark_manager_edit
      // 条件A2
      let ConditionA2 = firstPath === '/emergency' && secondPath === '/emergency/emergency-task'
      // 条件A3
      let ConditionA3 = firstPath === '/forest-grass' && secondPath === '/forest-grass/FG-Inspection'
      // 条件A3_1
      let ConditionA3_1 = ConditionA3 && !!this.permission.FG_mark_edit
      // 条件A
      let ConditionA = ConditionA1_1 || ConditionA2 || ConditionA3_1

      // 条件B
      const ConditionB = this.modifyType !== 0

      return ConditionA && ConditionB
    },
    handleMarkPath() {
      return this.modifyType === 2
    },
    handleNoFlyZone() {
      const secondLevel = this.$route.matched[1];
      if (secondLevel.name !== 'no-fly-zone') {
        return true;
      }
      return false;
    },
    handleAirport() {
      const secondLevel = this.$route.matched[1];
      return secondLevel.name === 'airport-manager';
    },
    handleBaseStation() {
      const secondLevel = this.$route.matched[1];
      return secondLevel.name === 'OG-Inspection' || secondLevel.name === 'project-manager';
    }
  },
  watch: {
    // 通过监听路由改变modifyType， 如果路由关联地图请在定义路由时，在props中添加modifyType
    $route: {
      handler() {
        const matched = this.$route.matched;
        let propsDefault = matched[matched.length - 1].props.default;
        if (!propsDefault) {
          return;
        }
        if (typeof propsDefault === "function") {
          propsDefault = propsDefault(this.$route);
        }
        const modifyType = propsDefault.modifyType;
        if (typeof modifyType === "number" && this.modifyType !== modifyType) {
          this.modifyType = modifyType;
          if (modifyType !== 0) {
            this.switchToModify(this.modifyType);
          }
        }
        this.initIsAI(false);
      },
      deep: true,
    },

    selectModelId() {
      this.liveVideoVOList = []
      var arr = this.selectModelId.split("-");
      var id = arr[arr.length - 1];
      getDetail(id).then(res=>{
        if (res.data.code === 200) {
          const task = res.data.data
          this.useLive = task.liveType === 1 ? "VdieoLiveHWLLS" : "VdieoLiveEasyplayer";
          this.liveVideoVOList = task.liveVideoVOList;
        }
      })
    },
    // 当前选中的项目
    selectProject(newVal, oldVal) {
      this.changeSelectProject(newVal, oldVal);
      this.changUAVstate(newVal, oldVal);
    },

    warningList() {
      this.updateWarning();
    },

    projectList(val) {
      switch (this.modifyType) {
        // 判断当前展示的页面为项目首页
        case 0:
          this.switchToModify(this.modifyType);
          break;
        default:
          break;
      }
    },

    handlerType(newVal) {
      if (newVal && newVal.type === "switchWorkSpace") {
        this.switchWorkSpace(newVal.params);
      }
      if (newVal && newVal.type === "projectPoint") {
        this.setProjectCenterPoint(newVal.params);
      }
      if (newVal && newVal.type === "homePoint") {
        this.setHomePointHandle();
      }
    }
  },
  activated() {
    this.isComponentActive = true;
    this.initBusEvent()
  },
  deactivated() {
    this.isComponentActive = false;
    this.unbindBusEvents()
  },
  mounted() {
    // 初始化地图
    this.init();
    if (this.modifyType !== 0) {
      this.switchToModify(this.modifyType);
    }
  },
  methods: {
    ...mapMutations([
      'SET_NO_FLY_SWITCH',
      'SET_NO_FLY_CHECKLIST',
      'SET_NO_FLY_TYPE_VALUE',
      'SET_MAP3D_PROJECT_DETAIL',
      'SET_MAP3D_FG_DETAIL',
      'SET_MAP3D_OG_DETAIL',
      'SET_MAP3D_ET_DETAIL'
    ]),
    
    // 初始化
    init() {
      const viewer = new Map3d("cesiumContainer", {
        sceneMode: this.viewModel,
        is3D: this.is3D
      });
      // 开监控后billboard 会出现遮挡问题？？ 请注意
      // if(process.env.NODE_ENV === 'development'){
      //   viewer.extend(Cesium.viewerCesiumInspectorMixin)
      // }
      // this.terrain = getTerrainProvider();
      this.getPosition(viewer);
      let self = this;
      // 弹窗位置与球体拖拽事件关联
      viewer.scene.postRender.addEventListener(() => {
        const heading = viewer.scene.camera.heading;
        if (heading) {
          this.degrees = 360 - Cesium.Math.toDegrees(heading);
        } else {
          this.degrees = 0;
        }
        const { distance } = viewer.getScale();
        this.scale = distance;
        if (this.mouseCartesian) {
          const ellipsoid = viewer.scene.globe.ellipsoid;
          const cartographic = ellipsoid.cartesianToCartographic(
            this.mouseCartesian
          );
          const lat = Cesium.Math.toDegrees(cartographic.latitude);
          const lng = Cesium.Math.toDegrees(cartographic.longitude);
          if (!cartographic || !lng || !lat) {
            return;
          }
          const d = viewer.scene.globe.getHeight(cartographic);
          if (!d) {
            return;
          }
          const height = d.toFixed(2);
          const eOrw = lng >= 0 ? "东经" : "西经";
          const sOrn = lat >= 0 ? "北纬" : "南纬";
          this.mousePosition = [
            `${eOrw}${Math.abs(lng).toFixed(4)}°`,
            `${sOrn}${Math.abs(lat).toFixed(4)}°`,
          ];
        }
      });
      new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      this.$options.map3d = viewer;
      // const titles = new Cesium.Cesium3DTileset ({
      //   url: '/testData/output/tileset.json'
      // })
      
      // viewer.scene.primitives.add(titles)
      // titles.readyPromise.then(res=>{
      //   console.log('cheng');
      //   setTimeout(() => {
      //     console.log(11211, res);
      //     viewer.zoomTo(res)
      //   }, 2000);
          
      // }).otherwise(function (error) {
      //   // 处理加载错误
      //   console.error(error);
      // });
      this.initAllLayer();
      this.initBusEvent();
      this.initRunningUAV(viewer)
      AMap.plugin(
        ["AMap.Autocomplete", "AMap.PlaceSearch", "AMap.DistrictSearch"],
        function () {
          let autoOptions = {
            city: "北京", //城市，默认全国
            input: "tipinput", //使用联想输入的input的id
          };
          const autocomplete = new AMap.Autocomplete(autoOptions);
          const placeSearch = new AMap.PlaceSearch({
            city: "北京",
          });
          AMap.event.addListener(autocomplete, "select", function (e) {
            //TODO 针对选中的poi实现自己的功能
            if (e.poi.location) {
              self.zoomToPoint(e.poi.location);
            } else {
              self.districtSearchHandler.search(
                e.poi.name,
                function (status, result) {
                  if (status == "complete") {
                    if (
                      result &&
                      result.districtList[0] &&
                      result.districtList[0].center
                    ) {
                      self.zoomToPoint(result.districtList[0].center);
                    }
                  }
                }
              );
            }
          });
          //行政区划查询
          let opts = {
            subdistrict: 1, //返回下一级行政区
            showbiz: false, //最后一级返回街道信息
            extensions: "all",
          };
          self.districtSearchHandler = new AMap.DistrictSearch(opts); //注意：需要使用插件同步下发功能才能这样直接使用
        }
      );
    },

    // 根据路由返回对应的modifyType
    getModifyType() {
      const matched = this.$route.matched;
      let propsDefault = matched[matched.length - 1].props.default;
      if (!propsDefault) {
        return 0;
      }
      if (typeof propsDefault === "function") {
        propsDefault = propsDefault(this.$route);
      }
      const modifyType = propsDefault.modifyType;
      if (typeof modifyType === "number") {
        return modifyType;
      }
      return 0;
    },

    // 初始化所有图层
    initAllLayer() {
      this.initWarningLayer();
      this.initMarkLayer()
      this.initRoute()
      this.projectLayer = new BaseLayer();
      this.kmlLayer = new BaseLayer();
      this.shpLayer = new BaseLayer();
      this.$options.map3d.layerManager.add(this.projectLayer);
      this.$options.map3d.layerManager.add(this.kmlLayer);
      this.$options.map3d.layerManager.add(this.shpLayer);
      // 初始化禁飞区图层
      let noFlyLayer = new BaseLayer({name: '$禁飞区图层$', id: '$禁飞区图层$'});
      this.noFlyLayers.push({
        layer: noFlyLayer,
        circleCount: 0,
        polygonCount: 0
      });
      this.$options.map3d.layerManager.add(noFlyLayer);
      // 初始化机场图层
      let airportLayer = new BaseLayer();
      this.airportLayers.push({
        layer: airportLayer,
        pointCount: 0,
        polygonCount: 0
      });
      this.$options.map3d.layerManager.add(airportLayer);
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initBasestationShowLayer();
      this.initHangarLayer();
    },

    // 
    initMarkLayer() {
      let markLayer = new BaseLayer();
      this.markLayers.push({
        layer: markLayer,
        pointCount: 0,
        lineCount: 0,
        polygonCount: 0,
      })
      this.$options.map3d.layerManager.add(markLayer);
    },

    // 绑定监听事件
    initBusEvent() {
      // todo 以后总线要管理起来
      /**********请在unbindBusEvent方法中同时写出解绑的事件**********/
      this.$EventBus.$off("init-isAI");
      this.$EventBus.$on("init-isAI", data => {
        this.initIsAI(data);
      });
      this.$EventBus.$off("warning-event");
      this.$EventBus.$on("warning-event", this.doWarningEvent);
      // 定位到entity
      this.$EventBus.$off("fly-to-by-id");
      this.$EventBus.$on("fly-to-by-id", (id) => {
        this.flyToEntity(id);
      });
      // 定位区间
      this.$EventBus.$off("map-flyTo");
      this.$EventBus.$on("map-flyTo", (lng, lat, height) => {
        this.flyTo(lng, lat, height);
      });

      // 选中巡检对象
      this.$EventBus.$off("select-inspection-by-id");
      this.$EventBus.$on("select-inspection-by-id", this.selectInspectionById);
      // 删除巡检对象
      this.$EventBus.$off("delete-inspection-by-id");
      this.$EventBus.$on("delete-inspection-by-id", this.deleteInspection);
      // 批量删除巡检对象
      this.$EventBus.$off("delete-inspection-by-ids");
      this.$EventBus.$on("delete-inspection-by-ids", this.deleteEntities);
      // 显示隐藏巡检对象
      this.$EventBus.$off("visibility-inspection-nodes");
      this.$EventBus.$on("visibility-inspection-nodes", this.visibilityInspection);
      // 更新巡检图元
      this.$EventBus.$off("update-inspection");
      this.$EventBus.$on("update-inspection", this.reloadInspection);

      // 删除标注
      this.$EventBus.$off("delete-mark-by-id");
      this.$EventBus.$on("delete-mark-by-id", this.deleteMark);
      // 更新标注图元
      this.$EventBus.$off("update-mark");
      this.$EventBus.$on("update-mark", this.reloadMark);
      // 选中标注对象
      this.$EventBus.$off("select-mark-by-id");
      this.$EventBus.$on("select-mark-by-id", this.selectMarkById);
      // 显示隐藏标注
      this.$EventBus.$off("visibility-mark-nodes");
      this.$EventBus.$on("visibility-mark-nodes", this.visibilityMarks);

      // 手动绘制禁飞区
      this.$EventBus.$off("draw-noFly");
      this.$EventBus.$on("draw-noFly", data => {
        this.drawNoFlyType(data);
      });
      // 绘制禁飞区数据
      this.$EventBus.$off("draw-noFly-data");
      this.$EventBus.$on("draw-noFly-data", data => {
        this.initNoFly(data);
      });
      // 定位禁飞区
      this.$EventBus.$off("noFly-location");
      this.$EventBus.$on("noFly-location", data => {
        this.locationNoFly(data);
      });
      // 选中禁飞区
      this.$EventBus.$off("select-noFly-by-id");
      this.$EventBus.$on("select-noFly-by-id", this.selectNoFlyById);
      // 关闭禁飞区右侧面板
      this.$EventBus.$off("close-noFly-plan");
      this.$EventBus.$on("close-noFly-plan", this.closeNoFlyPanel);
      // 删除禁飞区成功
      this.$EventBus.$off("delete-noFly-success");
      this.$EventBus.$on("delete-noFly-success", data => {
        this.deleteNoFlySuccess(data);
      });
      // 设置禁飞区数据
      this.$EventBus.$off("set-noFly-data");
      this.$EventBus.$on("set-noFly-data", data => {
        this.setNoFlyEntityData(data);
      });
      // 更新禁飞区
      this.$EventBus.$off("update-noFly");
      this.$EventBus.$on("update-noFly", data => {
        this.updateNoFly(data);
      });
      // 增加禁飞区面的点
      this.$EventBus.$off("add-noFly-PolygonPoint");
      this.$EventBus.$on("add-noFly-PolygonPoint", data => {
        this.addNoFlyEntityPoint(data);
      });

      // 绘制机场
      this.$EventBus.$off("draw-airport-data");
      this.$EventBus.$on("draw-airport-data", data => {
        this.initAirport(data);
      });
      // 定位机场对象
      this.$EventBus.$off("airport-location");
      this.$EventBus.$on("airport-location", data => {
        this.locationAirport(data);
      });
      // 选中机场对象
      this.$EventBus.$off("select-airport-by-id");
      this.$EventBus.$on("select-airport-by-id", this.selectAirportById);
      // 关闭机场属性面板
      this.$EventBus.$off("close-airport-plan");
      this.$EventBus.$on("close-airport-plan", this.closeAirportPanel);
      // 删除机场成功
      this.$EventBus.$off("delete-airport-success");
      this.$EventBus.$on("delete-airport-success", data => {
        this.deleteAirportSuccess(data);
      });

      // 增加当前的workkspace
      this.$EventBus.$off("add-inspection-work-space");
      this.$EventBus.$on("add-inspection-work-space", this.addInspectionWorkSpace);
      // 删除当前的workkspace
      this.$EventBus.$off("delete-inspection-work-space");
      this.$EventBus.$on("delete-inspection-work-space", this.removeInspectionWorkSpace);

      // 绘制机库
      this.$EventBus.$off("draw-hangar-data");
      this.$EventBus.$on("draw-hangar-data", data => {
        this.initHangar(data);
      });
      // 定位机库对象
      this.$EventBus.$off("hangar-location");
      this.$EventBus.$on("hangar-location", data => {
        this.locationHangar(data);
      });
      // 删除机库成功
      this.$EventBus.$off("delete-hangar-success");
      this.$EventBus.$on("delete-hangar-success", data => {
        this.deleteHangarSuccess(data);
      });
      // 机库实体显示隐藏
      this.$EventBus.$off("change-hangar-show");
      this.$EventBus.$on("change-hangar-show", (id, type) => {
        this.changeShowHangarEntity(id, type);
      });
    },

    // 
    unbindBusEvents() {
      this.$EventBus.$off("init-isAI");
      this.$EventBus.$off("warning-event");
      // 定位到entity
      this.$EventBus.$off("fly-to-by-id");
      // 定位区间
      this.$EventBus.$off("map-flyTo");
      // 选中巡检对象
      this.$EventBus.$off("select-inspection-by-id");
      // 删除巡检对象
      this.$EventBus.$off("delete-inspection-by-id");
      // 批量删除巡检对象
      this.$EventBus.$off("delete-inspection-by-ids");
      // 显示隐藏巡检对象
      this.$EventBus.$off("visibility-inspection-nodes");
      // 更新巡检图元
      this.$EventBus.$off("update-inspection");
      // 删除标注
      this.$EventBus.$off("delete-mark-by-id");
      // 更新标注图元
      this.$EventBus.$off("update-mark");
      // 选中标注对象
      this.$EventBus.$off("select-mark-by-id");
      // 显示隐藏标注
      this.$EventBus.$off("visibility-mark-nodes");
      // 手动绘制禁飞区
      this.$EventBus.$off("draw-noFly");
      // 绘制禁飞区数据
      this.$EventBus.$off("draw-noFly-data");
      // 定位禁飞区
      this.$EventBus.$off("noFly-location");
      // 选中禁飞区
      this.$EventBus.$off("select-noFly-by-id");
      // 关闭禁飞区右侧面板
      this.$EventBus.$off("close-noFly-plan");
      // 删除禁飞区成功
      this.$EventBus.$off("delete-noFly-success");
      // 设置禁飞区数据
      this.$EventBus.$off("set-noFly-data");
      // 更新禁飞区
      this.$EventBus.$off("update-noFly");
      // 增加禁飞区面的点
      this.$EventBus.$off("add-noFly-PolygonPoint");
      // 绘制机场
      this.$EventBus.$off("draw-airport-data");
      // 定位机场对象
      this.$EventBus.$off("airport-location");
      // 选中机场对象
      this.$EventBus.$off("select-airport-by-id");
      // 关闭机场属性面板
      this.$EventBus.$off("close-airport-plan");
      // 删除机场成功
      this.$EventBus.$off("delete-airport-success");
      // 增加当前的workkspace
      this.$EventBus.$off("add-inspection-work-space");
      // 删除当前的workkspace
      this.$EventBus.$off("delete-inspection-work-space");
      // 绘制机库
      this.$EventBus.$off("draw-hangar-data");
      // 定位机库对象
      this.$EventBus.$off("hangar-location");
      // 删除机库成功
      this.$EventBus.$off("delete-hangar-success");
      // 机库实体显示隐藏
      this.$EventBus.$off("change-hangar-show");
    },

    // 切换新的工作图层
    switchWorkSpace(params) {
      const { groupId, visible } = params;
      let layerIndex;
      switch (this.modifyType) {
        // 巡检对象
        case 3:
          layerIndex = this.inspectionLayers.findIndex(
            (x) => x.layer.id === groupId
          );
          if (layerIndex >= 0) {
            this.currentLabelLayer = layerIndex;
            this.inspectionLayers[layerIndex].layer.show = true;
            this.$options.map3d.interactive.layer =this.inspectionLayers[layerIndex].layer;
          }
          break;
        // 标注
        case 2:
          layerIndex = this.markLayers.findIndex(
            (x) => x.layer.id === groupId
          );
          if (layerIndex >= 0) {
            this.currentLabelLayer = layerIndex;
            this.markLayers[layerIndex].layer.show = true;
            this.$options.map3d.interactive.layer =this.markLayers[layerIndex].layer;
          }
          break;
        default:
          break;
      }
    },

    // 添加新的工作图层
    addInspectionWorkSpace(params) {
      if (params && params.id) {
        // 创建新的图层
        let layer = new BaseLayer({
          name: params.id,
          id: params.id,
        })
        this.inspectionLayers.push ({
          layer,
          pointCount: 0,
          lineCount: 0,
          polygonCount: 0,
        });
        this.$options.map3d.layerManager.add(layer);
      }
    },

    // 删除新的工作图层
    removeInspectionWorkSpace(params) {
      let layer = this.$options.map3d.layerManager.find(params.id)
      this.$options.map3d.layerManager.remove(layer)
    },

    // 地图缩放
    zoomToPoint(center) {
      this.$options.map3d.camera.flyTo({
        destination: Cesium.Rectangle.fromDegrees(
          center.lng * 1 - 0.5,
          center.lat * 1 + 0.5,
          center.lng * 1 + 0.5,
          center.lat * 1 - 0.5
        ),
      });
    },

    // 罗盘
    setCompass() {
      const center = this.$options.map3d.getCenter();
      const d = 360 - this.degrees;
      this.$options.map3d.scene.camera.rotate(
        center,
        -Cesium.Math.toRadians(d)
      );
    },

    // 切换绘制图层
    switchLayerType() {
      this.isVec = !this.isVec;
      const type = this.isVec ? "vector" : "satellite";
      this.$options.map3d.switchLayer(type);
    },

    zoom(amount) {
      let cartographic;
      if (this.viewModel === Cesium.SceneMode.SCENE3D) {
        const cameraPos = this.$options.map3d.camera.position;
        const ellipsoid = this.$options.map3d.scene.globe.ellipsoid;
        cartographic = ellipsoid.cartesianToCartographic(cameraPos);
      } else {
        cartographic = this.$options.map3d.camera.positionCartographic;
      }
      const height = cartographic.height;
      const centerLon = Cesium.Math.toDegrees(cartographic.longitude);
      const centerLat = Cesium.Math.toDegrees(cartographic.latitude);

      // 镜头动画
      this.$options.map3d.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          centerLon,
          centerLat,
          amount > 0 ? height / 1.8 : height * 1.8
        ),
        duration: 1.0,
      });
    },

    changeViewModel() {
      const newMode = this.viewModel
      let cameraInfo = this.$options.map3d.getCameraInfo();

      const result = this.$options.map3d.camera.pickEllipsoid(
        new Cesium.Cartesian2(
          this.$options.map3d.scene.canvas.clientWidth / 2,
          this.$options.map3d.scene.canvas.clientHeight / 2
        )
      );

      const d = this.$options.map3d.camera.positionCartographic;
      const curPosition =
        Cesium.Ellipsoid.WGS84.cartesianToCartographic(result);

      let bound = new Cesium.BoundingSphere(
        new Cesium.Cartesian3.fromRadians(
          curPosition.longitude,
          curPosition.latitude
        ),
        d.height
      );

      if (newMode === Cesium.SceneMode.SCENE2D) {
        cameraInfo.orientation = {
          heading: 0,
          pitch: Cesium.Math.toRadians(-90.0), //look down
          roll: 0,
        };
        this.$options.map3d.camera.flyToBoundingSphere(bound, {
          offset: cameraInfo.orientation,
          duration: 0.5,
        });
      } else {
        bound.radius = d.height * 0.15;
        cameraInfo.orientation = {
          heading: 0,
          pitch: Cesium.Math.toRadians(-45.0), //look in 3d
          roll: 0,
        };
        this.$options.map3d.camera.flyToBoundingSphere(bound, {
          offset: cameraInfo.orientation,
          duration: 0.5,
        });
      }
    },

    getCurrentExtent() {
      // 范围对象
      var extent = {};

      // 得到当前三维场景
      var scene = this.$options.map3d.scene;

      // 得到当前三维场景的椭球体
      var ellipsoid = scene.globe.ellipsoid;
      var canvas = scene.canvas;

      // canvas左上角
      var car3_lt = this.$options.map3d.camera.pickEllipsoid(
        new Cesium.Cartesian2(0, 0),
        ellipsoid
      );

      // canvas右下角
      var car3_rb = this.$options.map3d.camera.pickEllipsoid(
        new Cesium.Cartesian2(canvas.width, canvas.height),
        ellipsoid
      );

      // 当canvas左上角和右下角全部在椭球体上
      if (car3_lt && car3_rb) {
        var carto_lt = ellipsoid.cartesianToCartographic(car3_lt);
        var carto_rb = ellipsoid.cartesianToCartographic(car3_rb);
        extent.xmin = Cesium.Math.toDegrees(carto_lt.longitude);
        extent.ymax = Cesium.Math.toDegrees(carto_lt.latitude);
        extent.xmax = Cesium.Math.toDegrees(carto_rb.longitude);
        extent.ymin = Cesium.Math.toDegrees(carto_rb.latitude);
      }

      // 当canvas左上角不在但右下角在椭球体上
      else if (!car3_lt && car3_rb) {
        var car3_lt2 = null;
        var yIndex = 0;
        do {
          // 这里每次10像素递加，一是10像素相差不大，二是为了提高程序运行效率
          yIndex <= canvas.height ? (yIndex += 10) : canvas.height;
          car3_lt2 = this.$options.map3d.camera.pickEllipsoid(
            new Cesium.Cartesian2(0, yIndex),
            ellipsoid
          );
        } while (!car3_lt2);
        var carto_lt2 = ellipsoid.cartesianToCartographic(car3_lt2);
        var carto_rb2 = ellipsoid.cartesianToCartographic(car3_rb);
        extent.xmin = Cesium.Math.toDegrees(carto_lt2.longitude);
        extent.ymax = Cesium.Math.toDegrees(carto_lt2.latitude);
        extent.xmax = Cesium.Math.toDegrees(carto_rb2.longitude);
        extent.ymin = Cesium.Math.toDegrees(carto_rb2.latitude);
      }
      // 获取高度
      extent.height = Math.ceil(
        this.$options.map3d.camera.positionCartographic.height
      );
      return extent;
    },

    getPosition(viewer) {
      const canvas = viewer.scene.canvas;
      const ellipsoid = viewer.scene.globe.ellipsoid;
      const handler = new Cesium.ScreenSpaceEventHandler(canvas);

      const self = this;
      handler.setInputAction(function (movement) {
        self.tips = movement.endPosition;
        const cartesian = viewer.camera.pickEllipsoid(
          movement.endPosition,
          ellipsoid
        );
        self.mouseCartesian = cartesian;
        const pickedFeature = viewer.scene.pick(movement.endPosition);
        if (!Cesium.defined(pickedFeature)) {
          self.selectEntityID = "";
          return;
        }
        self.selectEntityID = pickedFeature.id.id;
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    },

    search() {
      this.showSearch = !this.showSearch;
    },

    // 加载巡检对象
    requestInspectLabel(id) {
      // 判断项目类型------当前有飞行项目 mission-center  油气项目 oil-gas  林草项目 forest-grass
      switch (this.firstPath) {
        case '/mission-center':
          if (!id) return;
          getInspectionObjects({
              projectId: id,
            }).then((res) => {
              const code = res.data.code;
              if (code === 200) {
                const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
                this.inspectionPathList = []
                // 给编辑图层集合添加图层
                this.addInspects(data);
              }
            });
          break;
        case '/oil-gas':
          // 如果是项目管理则调用有文件夹的接口
          getLabelTreeList({
            projectId: id,
          }).then((res) => {
            const code = res.data.code;
            if (code === 200) {
              const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
              this.inspectionPathList = data.map((item) => {
                return {
                  label: item.dirName,
                  value: item.id,
                };
              });
              // 给编辑图层集合添加图层
              this.addInspectLabels(data);
            }
          });
          break;
        case '/forest-grass':
          // 林草项目暂不需要巡检项目
          break;
        default:
          break;
      }
      
    },

    // 巡检管理分图层渲染
    addInspectLabels(data) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        this.inspectionLayers[i] = {
          layer: new BaseLayer({
            name: item.id.toString(),
            id: item.id.toString(),
          }),
          pointCount: 0,
          lineCount: 0,
          polygonCount: 0,
        };
        this.$options.map3d.layerManager.add(this.inspectionLayers[i].layer);
        this.createInspectionsIntoLayer(item.mapMarks, this.inspectionLayers[i].layer);
      }
    },

    // 单一图层渲染巡检管理
    addInspects(data) {
      this.inspectionLayers = []
      this.inspectionLayers[0] = {
        layer: new BaseLayer({
          name: '$巡检对象展示图层$',
          id: '$巡检对象展示图层$',
        }),
        pointCount: 0,
        lineCount: 0,
        polygonCount: 0,
      };
      this.$options.map3d.layerManager.add(this.inspectionLayers[0].layer);
      this.createInspectionsIntoLayer(data, this.inspectionLayers[0].layer)
    },

    // 加载标注管理
    requestMarkLabel(id) {
      // 判断权限
      try {
        switch (this.firstPath) {
          case '/mission-center':
            if (!this.permission.mark_manager_view) return
            // 判断项目类型------当前有飞行项目 mission-center
            if (this.modifyType === 2) {
              getLabelTreeList({
                projectId: id,
                dirType: 1
              }).then((res) => {
                const code = res.data.code;
                if (code === 200) {
                  const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];;
                  this.markPathList = data.map((item) => {
                    return {
                      label: item.dirName,
                      value: item.id,
                    };
                  });
                  // 给编辑图层集合添加图层
                  this.addMarkLabels(data);
                } else {
                  this.initMarkLayer()
                }
              }).catch(err=>{
                this.initMarkLayer()
              });
            } else {
              getMarkObjects({
                projectId: id,
              }).then((res) => {
                const code = res.data.code;
                if (code === 200) {
                  const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
                  this.markPathList = []
                  // 给编辑图层集合添加图层
                  this.addMarks(data);
                } else {
                  this.initMarkLayer()
                }
              }).catch(err=>{
                this.initMarkLayer()
              });
            }
            break
          case '/emergency':
            // 判断项目类型------当前有应急 emergency
            if (this.modifyType === 2) {
              getLabelTreeList({
                projectId: id,
                dirType: 1
              }).then((res) => {
                const code = res.data.code;
                if (code === 200) {
                  const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];;
                  this.markPathList = data.map((item) => {
                    return {
                      label: item.dirName,
                      value: item.id,
                    };
                  });
                  // 给编辑图层集合添加图层
                  this.addMarkLabels(data);
                } else {
                  this.initMarkLayer()
                }
              }).catch(err=>{
                this.initMarkLayer()
              });
            } else {
              getMarkObjects({
                projectId: id,
              }).then((res) => {
                const code = res.data.code;
                if (code === 200) {
                  const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
                  this.markPathList = []
                  // 给编辑图层集合添加图层
                  this.addMarks(data);
                }
              });
            }
            break
          case '/forest-grass':
            if (!this.permission.FG_mark_view) return
            // 判断项目类型------当前有林草 forest-grass
            if (this.modifyType === 2) {
              getLabelTreeList({
                projectId: id,
                dirType: 1
              }).then((res) => {
                const code = res.data.code;
                if (code === 200) {
                  const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];;
                  this.markPathList = data.map((item) => {
                    return {
                      label: item.dirName,
                      value: item.id
                    };
                  });
                  // 给编辑图层集合添加图层
                  this.addMarkLabels(data);
                } else {
                  this.initMarkLayer()
                }
              }).catch(err=>{
                this.initMarkLayer()
              });
            } else {
              getMarkObjects({
                projectId: id,
              }).then((res) => {
                const code = res.data.code;
                if (code === 200) {
                  const data = res.data.data && Array.isArray(res.data.data)? res.data.data: [];
                  this.markPathList = []
                  // 给编辑图层集合添加图层
                  this.addMarks(data);
                }
              });
            }
            break
          default:
            this.initMarkLayer()
            break;
        }
      } catch (error) {
        this.initMarkLayer()
      }
      
    },

    // 标注管理分图层渲染
    addMarkLabels(data) {
      for (let i = 0; i < data.length; i++) {
        let pointCount = 0;
        let lineCount = 0;
        let polygonCount = 0;
        let item = data[i];
        this.markLayers[i] = {
          layer: new BaseLayer({
            name: item.id.toString(),
            id: item.id.toString(),
          }),
          pointCount,
          lineCount,
          polygonCount,
        };
        this.$options.map3d.layerManager.add(this.markLayers[i].layer);
        for (let j = 0; j < item.mapMarks.length; j++) {
          const mark = item.mapMarks[j];
          const node = this.createMarkNode(mark);
          this.markLayers[i].layer.addNode(node);
          switch (mark.content.geometry.type) {
            case "Point":
              pointCount++;
              break;
            case "LineString":
              lineCount++;
              break;
            case "Polygon":
              polygonCount++;
              break;
            default:
              break;
          }
          this.markLayers[i].pointCount = pointCount
          this.markLayers[i].lineCount = lineCount
          this.markLayers[i].polygonCount = polygonCount
        }
      }
    },

    // 单一图层渲染标注
    addMarks(data) {
      this.markLayers = []
      let pointCount = 0;
      let lineCount = 0;
      let polygonCount = 0;
      this.markLayers[0] = {
        layer: new BaseLayer({
          name: '$标注展示图层$',
          id: '$标注展示图层$',
        }),
        pointCount,
        lineCount,
        polygonCount,
      };
      this.$options.map3d.layerManager.add(this.markLayers[0].layer);
      for (let j = 0; j < data.length; j++) {
        const mark = data[j];
        const node = this.createMarkNode(mark);
        this.markLayers[0].layer.addNode(node);
        switch (mark.content.geometry.type) {
          case "Point":
            pointCount++;
            break;
          case "LineString":
            lineCount++;
            break;
          case "Polygon":
            polygonCount++;
            break;
          default:
            break;
        }
        this.markLayers[0].pointCount = pointCount
        this.markLayers[0].lineCount = lineCount
        this.markLayers[0].polygonCount = polygonCount
      }
    },

    // 定位
    flyTo(lng, lat, height) {
      if (typeof lng !== "number" && typeof lat !== "number") {
        return;
      }
      this.$options.map3d.camera.flyTo({
        destination: new Cesium.Cartesian3.fromDegrees(
          lng,
          lat,
          height ? height : 2000
        ),
        orientation: {
          heading: 0,
          pitch: Cesium.Math.toRadians(-90.0), //look down
          roll: 0,
        },
      });
    },

    // 定位到实体
    flyToEntity(id) {
      let node 
      for (let i = 0; i < this.markLayers.length; i++) {
        const _layer = this.markLayers[i].layer;
        node = _layer.getNodeById(id);
        if (node) {
          break
        }
      }
      if (!node) {
        for (let i = 0; i < this.inspectionLayers.length; i++) {
        const _layer = this.inspectionLayers[i].layer;
        node = _layer.getNodeById(id);
        if (node) {
            break
          }
        }
      }
      if (!node) return
      if (node instanceof NodeMap.Point) {
        this.$options.map3d.zoomTo(
          node._entity,
          new Cesium.HeadingPitchRange(0, -90, 2000)
        );
      } else {
        this.$options.map3d.zoomTo(
          node._entity,
          new Cesium.HeadingPitchRange(0, -90, 0)
        );
      }
    },

    // 切换Modify
    switchToModify(typeNumber) {
      this.$nextTick(() => {
        if (!this.isComponentActive) return;
        /**
         *  modifyType随着业务代码会重构，这种方式往后走不便于维护了
         *  0 项目列表 
         *  1 项目详情  
         *  2 标注
         *  3 巡检管理 
         *  4 ？
         *  5 大屏 
         *  6 报警 
         *  7 巡检记录
         *  8 禁飞区
         *  9 临时禁飞区
         *  10 限高禁飞区
         *  11 缓冲区禁飞区
         *  12 自定义禁飞区
         *  13 机场管理
         *  14 机库管理
         *  15 航线管理
         *  16 AI识别报警
         * */
        // 解除“ESC”事件
        removeEvent(window, 'keydown', this.keydown)
        // 如果是巡检管理则添加 “ESC” 键事件
        if (typeNumber === 3) {
          addEvent(window, 'keydown', this.keydown)
        }
        // 跳过不许要记录项目详情的页面
        if (typeNumber !== 0 && typeNumber !== 8 && typeNumber !== 9 && typeNumber !== 10 && 
          typeNumber !== 11 && typeNumber !== 12 && typeNumber !== 13 && 
          typeNumber !== 14 && typeNumber !== 15) {
          const id = this.$route.query.projectId
          switch (this.firstPath) {
            case '/mission-center':
              getProjectDetail({id}).then(res=>{
                if(res.data.code === 200){
                  this.SET_MAP3D_PROJECT_DETAIL(res.data.data)
                  this.getHangarData(res.data.data);
                  this.updateMap3d(typeNumber);
                }
              })
              break
            case '/forest-grass':
              getFGProjectDetail({id}).then(res=>{
                if(res.data.code === 200){
                  this.SET_MAP3D_FG_DETAIL(res.data.data)
                  this.getHangarData(res.data.data);
                  this.updateMap3d(typeNumber);
                }
              })
              break
            case '/oil-gas':
              getOGProjectDetail({id}).then(res=>{
                if(res.data.code === 200){
                  this.SET_MAP3D_OG_DETAIL(res.data.data)
                  this.getHangarData(res.data.data);
                  this.updateMap3d(typeNumber);
                }
              })
              break
            case '/emergency':
              getETProjectDetail({id}).then(res=>{
                if(res.data.code === 200){
                  res.data.data.centerPoint = res.data.data.eventLocation
                  this.SET_MAP3D_ET_DETAIL(res.data.data)
                  this.getHangarData(res.data.data);
                  this.updateMap3d(typeNumber);
                }
              })
              break
          }
        } else {
          this.updateMap3d(typeNumber);
        }
      });
    },

    // 更新地图
    updateMap3d(typeNumber) {
      console.log("当前地图modifyType:", this.modifyType);
      this.siderContentShow = false;
      if (!this.$options.map3d) return;
      // 关闭面板
      this.closeInspectionPanel();
      this.closeWarningDetail();
      this.closeAirportPanel();
      // 清理层层
      this.clearProjectLayer();
      this.clearWarningLayer();
      this.clearUAVLayer();
      this.clearMarkLayers();
      this.clearNoFlyShowLayer();
      this.clearNoFlyLayers();
      this.clearAirportShowLayer();
      this.clearAirportLayers();
      const projectId = this.$route.query.projectId;
      if (typeNumber !== 0 && typeNumber !== 8) {
        // 判断是否第一次加载巡检对象
        if (this.inspectionLayers.length === 0) {
          this.clearinspectionLayers();
          this.requestInspectLabel(projectId);
        }
      }
      // 判断是否是第一次加载基站
      if (!this.basestationList.length) {
        this.$options.baseStationHook.clearRenderLayer();
        this.getBasestationData();
      }
      switch (typeNumber) {
        // 项目列表
        case 0:
          this.clearinspectionLayers();
          this.clearHangar();
          this.loadUav();
          this.addProjectPoints(this.projectList);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.setEditModel();
          this.hiddenGreenLand();
          break;
        // 项目详情
        case 1:
          this.requestMarkLabel(projectId);
          this.addProjectPoints([this.projectDetail]);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.initGreenLand();
          this.loadUav();
          this.setEditModel();
          break;
        // 标注管理
        case 2:
          this.requestMarkLabel(projectId);
          // 应急---详情需要显示项目中心点
          if (this.$route.matched[this.$route.matched.length - 1].name === 'ET-detail') {
            this.addProjectPoints([this.projectDetail]);
          }
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.initGreenLand();
          this.loadUav();
          this.setEditModel();
          break;
        // 巡检管理
        case 3:
          this.requestMarkLabel(projectId);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.showGreenLand();
          this.setEditModel();
          break;
        case 4:
          this.setEditModel();
          break;
        // 大屏
        case 5:
          this.loadUav();
          this.requestMarkLabel(projectId);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.showGreenLand();
          this.setEditModel();
          break;
        // 报警
        case 6:
          this.addWarningPoints();
          this.requestMarkLabel(projectId);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.showGreenLand();
          this.setEditModel();
          break;
        // 巡检记录
        case 7:
          this.requestMarkLabel(projectId);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.showGreenLand();
          this.setEditModel();
          break;
        // 禁飞区
        case 8:
          break;
        // 临时禁飞区
        case 9:
          break;
        // 限高禁飞区
        case 10:
          break;
        // 缓冲区禁飞区
        case 11:
          break;
        // 自定义禁飞区
        case 12:
          break;
        // 机场管理
        case 13:
          this.getNoFlyData(); // 加载禁飞区
          break;
        case 14:
          // 机库管理
          this.getNoFlyData(); // 加载禁飞区
        case 15:
          // 航线管理
          this.getNoFlyData(); // 加载禁飞区
          this.setEditModel();
          break;
        // AI识别报警
        case 16:
          this.requestMarkLabel(projectId);
          this.getNoFlyData(); // 加载禁飞区
          this.getAirportData(); // 加载机场
          this.showGreenLand();
          this.setEditModel();
          break;
        default:
          this.setEditModel();
          break;
      }
    },

    doWarningEvent(data) {
      switch (data.type) {
        case "detail":
          this.showWarningDetail = true;
          break;
        case "moveTo":
          this.showWarningDetail = true;
          this.$options.map3d.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              data.data.lng,
              data.data.lat,
              1000 * 4
            ),
            duration: 1.0,
          });
          break;
        default:
          break;
      }
    },

    setHomePoint(newPoint) {
      if (newPoint) {
        if (
          !this.homePointTemp ||
          !this.homePointTemp ||
          newPoint[0] * 1 !== this.homePointTemp[0] ||
          newPoint[1] * 1 !== this.homePointTemp[1] ||
          newPoint[2] * 1 !== this.homePointTemp[2]
        ) {
          this.clearProjectLayer();
          this.homePointTemp = newPoint;
          const position = Cesium.Cartesian3.fromDegrees(
            newPoint[0] * 1,
            newPoint[1] * 1,
            newPoint[2] * 1 ? newPoint[2] * 1 : 0
          );
          this.projectLayer.addNode(new NodeMap.HomePoint({
              id: "homePoint-" + Cesium.createGuid(),
              position,
          }))
        }
        return;
      }
      this.clearProjectLayer();
    },

    // 控制单个要素显示
    fetaureVisible(fea) {
      const layObj = this.inspectionLayers.find(
        (x) => x.layer.name === fea.groupId
      );
      const lay = layObj.layer;
      if (lay) {
        let entities = lay.entities.values;
        entities.forEach((x) => {
          const idx = x.id.indexOf(fea.id);
          if (idx > -1) {
            x.show = fea.visible;
          }
          if (fea.visible && !lay.show) {
            lay.show = true;
          }
        });
      }
    },

    // 
    closeSiderContent() {
      this.siderContentShow = false;
    },

    // 设置交互机制
    setEditModel(params) {
      this.activeDraw = "";
      this.$options.map3d._container.style.cursor = "default";
      let afterFinish = (feature) => {
        if (!feature) {
          this.closeAllRightPannel()
          return
        }
        let category = feature && feature.category;
        switch (category) {
          case NodeMap.Node.WARNING:
            let selected = this.warningList.find((item) => {
              return item.id == feature.id;
            });
            this.$store.dispatch("setSelectWarning", selected);
            this.showWarningDetail = true;
            break;
          case NodeMap.Node.UAV:
            this.clickUav(feature.id);
            break;
          case NodeMap.Node.INSPECTION:
            this.closeMarkPanel()
            this.selectedOilNode(feature);
            break;
          case NodeMap.Node.MARK:
            this.closeInspectionPanel()
            this.selectedMarkNode(feature);
            break;
          case NodeMap.Node.NOFLYZONE:
            this.selectedNoflyNode(feature);
            break;
          case NodeMap.Node.AIRPORT:
            this.selectedAirportNode(feature);
            break;
          case NodeMap.Node.HANGAR:
            this.selectedHangarNode(feature);
            break;
          default:
            break;
        }
      };
      let updating = (feature) => {
        switch (feature.category) {
          case NodeMap.Node.INSPECTION:
            this.updateInspectionByOperation(feature)
            break;
          case NodeMap.Node.MARK:
            this.updateMarkByOperation(feature)
            break;
          case NodeMap.Node.NOFLYZONE:
            this.updateNoflyByOperation(feature)
            break;
          case NodeMap.Node.AIRPORT:
            this.updateAirportByOperation(feature)
            break;
          default:
            break;
        }
      }
      params = Object.assign({
        map: this.$options.map3d,
        vueHandler: this,
        afterFinish,
        updating,
        canEdit: this.hasEditPermission()
      }, params)

      switch (this.modifyType) {
        case 3:
          this.$options.map3d.triggerEditModel(new EditOperation(params));
          break;
        case 12:
          this.$options.map3d.triggerEditModel(new EditOperation(params));
          break;
        case 13:
          this.$options.map3d.triggerEditModel(new EditOperation(params));
          break;
        default:
          this.$options.map3d.triggerEditModel(new NormalOperation(params));
          break;
      }
    },

    // hasEditPermission
    hasEditPermission() {
      /***
       * 判断条件说明:
       * 
       * **/
      const firstPath = this.$route.matched[0].path
      if (firstPath === '/mission-center') {
        return this.permission.mark_manager_edit
      }

      if (firstPath === '/forest-grass') {
        return this.permission.FG_mark_edit
      }

      return true
    },

    keydown() {
      if (!this.drawFinish) {
        this.drawFinish = true
        this.activeDraw = ''
        this.setEditModel()
      }
    },

    closeAllRightPannel() {
      this.closeInspectionPanel();
      this.closeMarkPanel();
      this.closeNoFlyPanel();
      this.closeAirportPanel();
      this.closeHangarPanel();
    },

    updateMarkPathList() {
      const projectId = this.$route.query.projectId;
      getMapdirList({
        dirType: 1,
        projectId,
      }).then(res=>{
        if (res.data.code === 200) {
          this.markPathList = res.data.data.records
        }
      })
    }
  },
  beforeDestroy() {
    removeEvent(window, 'keydown', this.keydown)
    this.UAVBeforeDestroy();
    this.routeBeforeDestroy();
    this.WarningBeforeDestroy();
  }
};
</script>

<style lang="scss" scoped>
.map-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .warn-info {
    position: absolute;
    top: 2%;
    left: 1%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 10px;
    z-index: 10;
    color: #FF0000;
    font-size: 14px;
  }
  .draw {
    position: absolute;
    top: 2%;
    right: 1%;
    z-index: 10;
  }
  .inspection-box {
    display: flex;
    position: absolute;
    top: 2%;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    height: 48px;
    background-color: #1e222a;
    border-radius: 2px;
    .inspection-title {
      height: 100%;
      line-height: 48px;
      width: 94px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      text-align: center;
      border-right: 1px #3e434d solid;
    }
    .inspection-btn {
      cursor: pointer;
      height: 100%;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px #3e434d solid;
    }
    :last-child {
      border-right: none;
    }
  }
  .mark-label-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2%;
    right: 1%;
    z-index: 10;
    border-radius: 2px;
    .mark-label-btn {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px #3E434D  solid;
      .mark-label-triangle{
        box-sizing: border-box;
        position: absolute;
        border-top: 8px #265edd solid;
        border-right: 8px #265edd solid;
        border-left: 8px #0000 solid;
        border-bottom: 8px #0000 solid;
        width: 8px;
        height: 8px;
        top: 0px;
        right: 0px;
      }
      &:hover {
        border: 2px #265edd solid;
      }
    }
    :last-child {
      border-bottom: none;
    }
  }
  .airport-box {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 2%;
    left: 1%;
    border-radius: 2px;
    .draw-options_item {
      width: 48px;
      height: 47px;
      background: #265edd;
      border: 1px solid #3E434D;
      cursor: pointer;
      position: relative;
      &:first-child {
        border-right: none;
      }
      &:hover {
        border: 1px solid #265edd;
      }
      .active {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        border-top: 8px #265edd solid;
        border-right: 8px #265edd solid;
        border-left: 8px #0000 solid;
        border-bottom: 8px #0000 solid;
        width: 8px;
        height: 8px;
        top: 0px;
        right: 0px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  #cesiumContainer {
    width: 100%;
    height: 100%;
    #tip {
      background-color: #fff;
      color: #333;
      position: absolute;
      top: 2%;
      right: 5%;
      z-index: 11;
      border-radius: 5px;
      overflow: hidden;
      line-height: 20px;
    }
  }
  // 1.1.1版本 修改bug
  .sider-content {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    position: absolute;
    right: 5%;
    top: 0px;
    width: "460px";
    padding: 10px 20px;
    z-index: 10000;
    min-width: 150px;
    box-sizing: border-box;
    .content-header {
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      box-sizing: border-box;
      .close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        cursor: pointer;
      }
    }
    .content {
      ::v-deep .el-button--text {
        color: #265edd;
      }
      font-size: 12px;
      width: 100%;
      height: calc(100% - 40px) !important;
      background: transparent;
      .video-container {
        width: 426px;
        height: 234px;
        margin: 10px 0;
        position: relative;
        .video-menu {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 100001;
          padding: 4px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          img {
            width: 16px;
            height: 16px;
          }
          .update-btn {
            .span-text {
              background-color: #409eff;
              border-radius: 2px;
              color: white;
            }
            // color: #409EFF;
          }
        }
      }
      ul {
        width: 100%;
        list-style: none;
        padding: 0;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .video-attrs {
        height: 40%;
        overflow-y: scroll;
        .content-title {
          display: flex;
          font-size: 16px;
          align-items: center;
          font-weight: bold;
        }
        .square {
          width: 10px;
          height: 10px;
          background: #387bed;
          margin-right: 10px;
        }
      }
      .information {
        height: 52%;
        overflow: auto;
        .information-title {
          margin-bottom: 10px;
          margin-top: 8px;
          .title-decoration {
            height: 11px;
            width: 11px;
            display: inline-block;
            background: #387bed;
            margin-right: 4px;
          }
          span {
            font-size: 14px;
          }
        }
        .information-content {
          & > div {
            margin-top: 8px;
          }
          .information-name {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            margin-right: 5px;
          }
          span {
            font-size: 14px;
          }
        }
        .information-content > div {
          display: flex;
          justify-content: space-between;
        }
        .information-content-flex {
          display: flex;
          div {
            flex: 1;
          }
        }
      }
    }
  }
  #baseController {
    position: absolute;
    bottom: 10%;
    right: 1%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-fly {
      padding: 4px 5px 5px 5px;
      background-color: #2d333e;
      margin-bottom: 10px;
      border-radius: 4px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
    #navigationHelpButtonContainer {
      width: 21px;
      height: 42px;
      margin-bottom: 10px;
    }
  }
  .footer {
    position: absolute;
    bottom: 0%;
    padding: 2px;
    left: 0%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
  }
  .scaler {
    position: absolute;
    bottom: 3%;
    padding: 2px;
    right: 1%;
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    .scaler-text {
      text-align: center;
    }
    .scaler-ui {
      width: 100%;
      height: 10px;
      border: 1px solid #fff;
      border-top: none;
    }
  }
  .hover-tip {
    position: absolute;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
    &-triangle {
      position: absolute;
      box-sizing: border-box;
      border: 10px solid;
      border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8)
        transparent;
      left: 50%;
      top: 100%;
      transform: translateX(-50%) translateY(-51%) rotateZ(45deg);
    }
    &-item {
      word-wrap: break-word;
    }
  }
}

.other {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layerSwitch {
  margin-top: 20px;
  width: 32px;
  height: 32px;
  border-radius: 18px;
  border: 2px solid #fff;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2d333e;
  color: #fff;
  cursor: pointer;
  border: 1px solid #2d333e;
}

.forbidden {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-bottom: 10px;
}

.drawBtn {
  width: 40px;
  height: 40px;
}

.zoomBtn {
  width: 30px;
  height: 30px;
}

.btn > img {
  width: 16px;
}

.bottomBorder {
  border-bottom: 1px solid #171c28;
}

.btn:hover {
  filter: brightness(150%);
  border: 1px solid #171c28;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-changer {
  border-radius: 4px;
  margin-bottom: 10px;
}

.groupLastBtn {
  margin-bottom: 10px;
}

.search {
  margin-bottom: 10px;
  border-radius: 4px;
}

.borderRadiusTop {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.borderRadiusBottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.mouse-tip {
  padding: 5px 10px;
  border-radius: 4px;
  background: black;
  color: #fff;
  font-size: 12px;
}

.map-popup-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 8;
  overflow: auto;
  widows: 100%;
  font-size: 14px;
  margin-bottom: 20px;
}

.map-popup-li {
  margin-bottom: 5px;
  display: flex;
}

.li-title {
  flex: 4;
  text-align: right;
}

.li-plan-title {
  justify-content: center;
}

.map-popup-li-split {
  margin: 0 5px;
}

.map-popup-li-value {
  font-weight: bold;
  flex: 3;
  text-align: left;
}

.activeDraw {
  background: #387bed;
}

.open-oilInspection-panel {
  right: 286px !important;
}
</style>

<style lang="scss">
.nofly-tooltip {
  text-align: left;
  font-size: 13px;
  .nofly-tooltip-main {
    .tooltip-main_top {
      margin-bottom: 8px;
      .el-switch.is-checked .el-switch__core::after {
        margin-left: -14px;
      }
      .el-switch__core {
        height: 16px;
        &::after {
          width: 13px;
          height: 13px;
        }
      }
      .el-switch__label * {
        color: #fff;
        font-size: 13px;
      }
    }
    .tooltip-main_bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .el-checkbox.is-bordered {
        margin-left: 0;
        margin-top: 3px;
        display: flex;
        align-items: center;
        &:first-child {
          margin-top: 0;
        }
        &.el-checkbox--small {
          padding: 0;
          height: auto;
          border: none;
          .el-checkbox__label {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #fff;
            img {
              width: 18px;
              height: 18px;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
